/*
Author       : Dreamguys
Template Name: Truelysell - Bootstrap Html Template
Version      : 1.0
*/
/*============================
 [Table of CSS]

1. General
2. Table
3. Bootstrap Classes
4. Select2
5. Nav Tabs
6. Components
7. Avatar
8. Header
9. Sidebar
10. Content
11. Toggle Button
12. Login
13. Notifications
14. Dashboard
15. Profile
16.Blog
17.Blog details
18. Reports
19. Invoice
20. Pricing
21. Preloading
22. Chat
23. View Service
24. Add Service
25. Bank Transfer
26. Responsive

========================================*/
/*-----------------
	1. General
-----------------------*/
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
html {
  height: 100%;
  background-color: #fff;
}

body {
  background-color: #fff;
  color: #74788D;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  height: 100%;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  color: #2A313D;
  font-family: "Roboto", sans-serif;
  margin-top: 0;
}

a {
  outline: none;
  text-decoration: none;
}
a:hover, a:active, a:focus {
  outline: none;
  text-decoration: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}
input:-webkit-autofill:hover, input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

img {
  max-width: 100%;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.table-responsive {
  white-space: nowrap;
}

.table > :not(caption) > * > * {
  padding: 15px;
  box-shadow: unset;
  background-color: unset;
}
.table > :not(:first-child) {
  border-top: 0;
}

label {
  color: #262a2e;
}
.form-control, .form-select {
  color: #262a2e;
  border-color: #d8d8d8;
  border-radius: 0.375rem;
  min-height: 42px;
}
.form-control::placeholder {
  color: #74788D;
}
textarea.form-control {
  height: auto;
}
.form-grouphead {
  margin-bottom: 25px;
}
.form-grouphead h2 {
  font-size: 16px;
  font-weight: 500;
  color: #12151C;
}
.form-grouphead.pay-req .active-switch input:checked + .sliders {
  background-color: #4C40ED;
}
.form-control:focus {
  border-color: #4C40ED;
  box-shadow: none;
  outline: 0 none;
}

.table-content h5 {
  font-size: 16px;
  font-weight: 500;
}
.table-content h6 {
  color: #9597A9;
  font-size: 13px;
  margin: 0;
}

.form-control.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
}
.form-control.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

a {
  color: #009ce7;
}

input, button, a {
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}

input, button {
  outline: none;
}
input:focus, button:focus {
  outline: none;
}

input[type=file] {
  height: auto;
  min-height: calc(1.5em + 0.75rem + 2px);
}
input[type=text], input[type=password] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.navbar-nav > li {
  float: left;
}
.btn {
  text-transform: capitalize;
  outline: none;
  font-size: 1rem;
  padding: 0.375rem 1rem;
  border-radius: 0.375rem;
}
.form-group {
  margin-bottom: 20px;
}
.form-group label {
  color: #28283C;
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 400;
}
.form-group label span {
  color: #74788D;
  font-size: 13px;
  margin-left: 0;
  font-weight: 400;
}
.form-group label span.manitory {
  color: #FC1313;
  margin-left: 5px;
}

.input-group .form-control {
  height: 40px;
}

.nav .open > a {
  background-color: rgba(0, 0, 0, 0.1);
  border-color: rgba(0, 0, 0, 0.1);
}
.nav .open > a:focus, .nav .open > a:hover {
  background-color: rgba(0, 0, 0, 0.1);
  border-color: rgba(0, 0, 0, 0.1);
}

.font-weight-600 {
  font-weight: 600;
}

div.dataTables_wrapper div.dataTables_length select {
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
}

/*-----------------
	2. Table
-----------------------*/
.table {
  color: #333;
  max-width: 100%;
  margin-bottom: 0;
  width: 100%;
}
.table tbody {
  border: 1px solid #F1F1F1;
}

.table-striped > tbody > tr:nth-of-type(2n+1) {
  background-color: #f8f9fa;
}

.table.no-border > tbody > tr > td {
  border-top: 0;
  padding: 10px 8px;
}
.table.no-border > tfoot > tr > td, .table.no-border > tfoot > tr > th {
  border-top: 0;
  padding: 10px 8px;
}
.table.no-border > thead > tr > td, .table.no-border > thead > tr > th {
  border-top: 0;
  padding: 10px 8px;
}
.table > tbody > tr > th {
  border-top: 0;
  padding: 10px 8px;
}

.table-resposnive {
  overflow-x: auto;
}

.table th {
  white-space: nowrap;
  vertical-align: middle;
}
.table td {
  white-space: nowrap;
  vertical-align: middle;
  color: #74788D;
  font-size: 14px;
  font-weight: 400;
}

.table-nowrap td, .table-nowrap th {
  white-space: nowrap;
}

.table thead tr th:first-child {
  border-radius: 10px 0 0 0;
}
.table thead tr th:last-child {
  border-radius: 0 10px 0 0;
}
.table thead th {
  padding: 16px;
  font-size: 16px;
  font-weight: 500;
  color: #28283C;
  background: #EDF3F9;
}
.table.dataTable {
  border-collapse: collapse !important;
  margin: 0 !important;
}

table.table td h2 {
  display: inline-block;
  font-size: inherit;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

.action-language ul {
  display: flex;
  align-items: center;
}
.action-language .btn {
  padding: 5px 10px;
  min-width: 90px;
}
.action-language ul li {
  margin: 0 5px;
}
.action-language .btn.btn-primary:hover {
  color: #fff;
}
.btn-grey {
  background: #f5f5f5;
}

table.table td h2.table-avatar {
  align-items: center;
  display: inline-flex;
  font-size: inherit;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  white-space: nowrap;
}
table.table td h2 a {
  color: #333;
}
table.table td h2 a:hover {
  color: #4C40ED;
}
table.table td h2 span {
  color: #888;
  display: block;
  font-size: 12px;
  margin-top: 3px;
}

.table thead tr th {
  font-weight: 600;
  border: 0;
}
.table tbody tr {
  border-bottom: 1px solid #F1F1F1;
}
.table tbody tr:hover {
  background: #eee;
}
.table.table-center td, .table.table-center th {
  vertical-align: middle;
}

.table-bottom {
  margin-bottom: 40px;
}

.table-hover tbody tr:hover {
  background-color: #f7f7f7;
}
.table-hover tbody tr:hover td {
  color: #474648;
}

.table-striped thead tr {
  border-color: transparent;
}
.table-striped tbody tr {
  border-color: transparent;
}
.table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(255, 255, 255, 0.3);
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(235, 235, 235, 0.4);
}

.table-bordered {
  border: 1px solid rgba(0, 0, 0, 0.05) !important;
}
.table-bordered th, .table-bordered td {
  border-color: rgba(0, 0, 0, 0.05);
}

.card-table .card-body {
  padding: 0;
}
.card-table .card-body .table > thead > tr > th {
  border-top: 0;
}
.card-table .card-body .table tr td:first-child, .card-table .card-body .table tr th:first-child {
  padding-left: 1.5rem;
}
.card-table .card-body .table tr td:last-child, .card-table .card-body .table tr th:last-child {
  padding-right: 1.5rem;
}
.card-table .table td, .card-table .table th {
  border-top: 1px solid #e2e5e8;
  padding: 1rem 0.75rem;
  white-space: nowrap;
}

.actions a {
  width: 85px;
}

.cal-icon {
  position: relative;
  width: 100%;
}
.cal-icon:after {
  color: #979797;
  content: "\f073";
  display: block;
  font-family: "FontAwesome";
  font-size: 15px;
  margin: auto;
  position: absolute;
  right: 15px;
  top: 10px;
}

.invoice-total-inner .add-links:hover {
  color: #4C40ED;
}

.dataTables_info {
  float: right;
  padding-right: 15px;
  padding-top: 18px !important;
  font-size: 12px;
  color: #5E5873;
  font-weight: 600;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin-top: 15px;
}

.dataTables_length {
  margin-top: 15px !important;
  float: left;
  font-size: 12px;
}
.dataTables_length label:before {
  content: "Show";
  margin-right: 5px;
}

.paging_numbers {
  float: right;
}
.login-content {
  padding-left: 50px;
}

/*-----------------
	3. Bootstrap Classes
-----------------------*/

.btn-block {
  width: 100%;
}

.btn-link {
  text-decoration: none;
}
.btn-link:hover {
  text-decoration: underline;
}

.btn.focus, .btn:focus {
  box-shadow: unset;
}

.btn-white {
  background-color: #fff;
  border: 1px solid #ccc;
  color: #333;
}

.btn.btn-rounded {
  border-radius: 50px;
}

.bg-primary, .badge-primary {
  background-color: #4C40ED !important;
}

a.bg-primary:focus, a.bg-primary:hover {
  background-color: #e60073 !important;
}

button.bg-primary:focus, button.bg-primary:hover {
  background-color: #e60073 !important;
}

.bg-success, .badge-success {
  background-color: #27c24c !important;
}

a.bg-success:focus, a.bg-success:hover {
  background-color: #23ad44 !important;
}

button.bg-success:focus, button.bg-success:hover {
  background-color: #23ad44 !important;
}

.bg-info, .badge-info {
  background-color: #009efb !important;
}

a.bg-info:focus, a.bg-info:hover {
  background-color: #028ee1 !important;
}

button.bg-info:focus, button.bg-info:hover {
  background-color: #028ee1 !important;
}

.bg-warning, .badge-warning {
  background-color: #ffbc34 !important;
}

a.bg-warning:focus, a.bg-warning:hover {
  background-color: #e9ab2e !important;
}

button.bg-warning:focus, button.bg-warning:hover {
  background-color: #e9ab2e !important;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.bg-danger, .badge-danger {
  background-color: #f05050 !important;
  color: #333;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.bg-danger:focus, a.bg-danger:hover {
  background-color: #ee3939 !important;
}

button.bg-danger:focus, button.bg-danger:hover {
  background-color: #ee3939 !important;
}

.bg-white {
  background-color: #fff;
}

.bg-purple, .badge-purple {
  background-color: #9368e9 !important;
}

.text-primary, .dropdown-menu > li > a.text-primary {
  color: #4C40ED !important;
}

.text-success, .dropdown-menu > li > a.text-success {
  color: #23ad44 !important;
}

.text-danger, .dropdown-menu > li > a.text-danger {
  color: #f05050 !important;
}

.text-info, .dropdown-menu > li > a.text-info {
  color: #009efb !important;
}

.text-warning, .dropdown-menu > li > a.text-warning {
  color: #ffbc34 !important;
}

.text-purple, .dropdown-menu > li > a.text-purple {
  color: #7460ee !important;
}

.text-muted {
  color: #757575 !important;
}

.btn-primary {
  background-color: #4C40ED;
  border-color: #4C40ED;
}

.fs-14 {
  font-size: 14px !important;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary.active, .btn-primary:active {
  background-color: #2c1fe5;
  border: 1px solid #2c1fe5;
}

.open > .dropdown-toggle.btn-primary {
  background-color: #2c1fe5;
  border: 1px solid #2c1fe5;
}

.btn-primary:hover {
  box-shadow: 0 50px 0 0 #fff inset;
  color: #2c1fe5;
}
.btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover {
  background: #4C40ED;
  border: 1px solid #4C40ED;
}
.btn-primary.focus:active {
  background: #4C40ED;
  border: 1px solid #4C40ED;
}
.btn-primary:active:focus, .btn-primary:active:hover {
  background: #4C40ED;
  border: 1px solid #4C40ED;
}

.open > .dropdown-toggle.btn-primary.focus, .open > .dropdown-toggle.btn-primary:focus, .open > .dropdown-toggle.btn-primary:hover {
  background: #4C40ED;
  border: 1px solid #4C40ED;
}

.btn-primary.active:not(:disabled):not(.disabled), .btn-primary:active:not(:disabled):not(.disabled) {
  background: #4C40ED;
  border-color: #4C40ED;
  color: #fff;
}

.show > .btn-primary.dropdown-toggle {
  background: #4C40ED;
  border-color: #4C40ED;
  color: #fff;
}

.btn-primary.active:focus:not(:disabled):not(.disabled), .btn-primary:active:focus:not(:disabled):not(.disabled) {
  box-shadow: unset;
}

.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-primary.disabled, .btn-primary:disabled {
  background-color: #4C40ED;
  border-color: #4C40ED;
  color: #fff;
}

.btn-secondary.active:focus:not(:disabled):not(.disabled), .btn-secondary:active:focus:not(:disabled):not(.disabled) {
  box-shadow: unset;
}

.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-success {
  background-color: #27c24c;
  border: 1px solid #27c24c;
}
.btn-success:hover, .btn-success:focus, .btn-success.active, .btn-success:active {
  background-color: #23ad44;
  border: 1px solid #23ad44;
  color: #fff;
}

.open > .dropdown-toggle.btn-success {
  background-color: #23ad44;
  border: 1px solid #23ad44;
  color: #fff;
}

.btn-success.active.focus, .btn-success.active:focus, .btn-success.active:hover {
  background-color: #23ad44;
  border: 1px solid #23ad44;
}
.btn-success.focus:active {
  background-color: #23ad44;
  border: 1px solid #23ad44;
}
.btn-success:active:focus, .btn-success:active:hover {
  background-color: #23ad44;
  border: 1px solid #23ad44;
}

.open > .dropdown-toggle.btn-success.focus, .open > .dropdown-toggle.btn-success:focus, .open > .dropdown-toggle.btn-success:hover {
  background-color: #23ad44;
  border: 1px solid #23ad44;
}

.btn-success.active:not(:disabled):not(.disabled), .btn-success:active:not(:disabled):not(.disabled) {
  background-color: #23ad44;
  border-color: #23ad44;
  color: #fff;
}

.show > .btn-success.dropdown-toggle {
  background-color: #23ad44;
  border-color: #23ad44;
  color: #fff;
}

.btn-success.active:focus:not(:disabled):not(.disabled), .btn-success:active:focus:not(:disabled):not(.disabled) {
  box-shadow: unset;
}

.show > .btn-success.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-success.disabled, .btn-success:disabled {
  background-color: #27c24c;
  border-color: #27c24c;
  color: #fff;
}

.btn-info {
  background-color: #009efb;
  border: 1px solid #009efb;
}
.btn-info:hover, .btn-info:focus, .btn-info.active, .btn-info:active {
  background-color: #028ee1;
  border: 1px solid #028ee1;
}

.open > .dropdown-toggle.btn-info {
  background-color: #028ee1;
  border: 1px solid #028ee1;
}

.btn-info.active.focus, .btn-info.active:focus, .btn-info.active:hover {
  background-color: #028ee1;
  border: 1px solid #028ee1;
}
.btn-info.focus:active {
  background-color: #028ee1;
  border: 1px solid #028ee1;
}
.btn-info:active:focus, .btn-info:active:hover {
  background-color: #028ee1;
  border: 1px solid #028ee1;
}

.open > .dropdown-toggle.btn-info.focus, .open > .dropdown-toggle.btn-info:focus, .open > .dropdown-toggle.btn-info:hover {
  background-color: #028ee1;
  border: 1px solid #028ee1;
}

.btn-info.active:not(:disabled):not(.disabled), .btn-info:active:not(:disabled):not(.disabled) {
  background-color: #028ee1;
  border-color: #028ee1;
  color: #fff;
}

.show > .btn-info.dropdown-toggle {
  background-color: #028ee1;
  border-color: #028ee1;
  color: #fff;
}

.btn-info.active:focus:not(:disabled):not(.disabled), .btn-info:active:focus:not(:disabled):not(.disabled) {
  box-shadow: unset;
}

.show > .btn-info.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-info.disabled, .btn-info:disabled {
  background-color: #009efb;
  border-color: #009efb;
  color: #fff;
}

.btn-warning {
  background-color: #ffbc34;
  border: 1px solid #ffbc34;
}
.btn-warning:hover, .btn-warning:focus, .btn-warning.active, .btn-warning:active {
  background-color: #e9ab2e;
  border: 1px solid #e9ab2e;
}

.open > .dropdown-toggle.btn-warning {
  background-color: #e9ab2e;
  border: 1px solid #e9ab2e;
}

.btn-warning.active.focus, .btn-warning.active:focus, .btn-warning.active:hover {
  background-color: #e9ab2e;
  border: 1px solid #e9ab2e;
}
.btn-warning.focus:active {
  background-color: #e9ab2e;
  border: 1px solid #e9ab2e;
}
.btn-warning:active:focus, .btn-warning:active:hover {
  background-color: #e9ab2e;
  border: 1px solid #e9ab2e;
}

.open > .dropdown-toggle.btn-warning.focus, .open > .dropdown-toggle.btn-warning:focus, .open > .dropdown-toggle.btn-warning:hover {
  background-color: #e9ab2e;
  border: 1px solid #e9ab2e;
}

.btn-warning.active:not(:disabled):not(.disabled), .btn-warning:active:not(:disabled):not(.disabled) {
  background-color: #e9ab2e;
  border-color: #e9ab2e;
  color: #fff;
}

.show > .btn-warning.dropdown-toggle {
  background-color: #e9ab2e;
  border-color: #e9ab2e;
  color: #fff;
}

.btn-warning.active:focus:not(:disabled):not(.disabled), .btn-warning:active:focus:not(:disabled):not(.disabled) {
  box-shadow: unset;
}

.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-warning.disabled, .btn-warning:disabled {
  background-color: #ffbc34;
  border-color: #ffbc34;
  color: #fff;
}

.btn-danger {
  background-color: #f05050;
  border: 1px solid #f05050;
}
.btn-danger:hover, .btn-danger:focus, .btn-danger.active, .btn-danger:active {
  background-color: #ee3939;
  border: 1px solid #ee3939;
}

.open > .dropdown-toggle.btn-danger {
  background-color: #ee3939;
  border: 1px solid #ee3939;
}

.btn-danger.active.focus, .btn-danger.active:focus, .btn-danger.active:hover {
  background-color: #ee3939;
  border: 1px solid #ee3939;
}
.btn-danger.focus:active {
  background-color: #ee3939;
  border: 1px solid #ee3939;
}
.btn-danger:active:focus, .btn-danger:active:hover {
  background-color: #ee3939;
  border: 1px solid #ee3939;
}

.open > .dropdown-toggle.btn-danger.focus, .open > .dropdown-toggle.btn-danger:focus, .open > .dropdown-toggle.btn-danger:hover {
  background-color: #ee3939;
  border: 1px solid #ee3939;
}

.btn-danger.active:not(:disabled):not(.disabled), .btn-danger:active:not(:disabled):not(.disabled) {
  background-color: #ee3939;
  border-color: #ee3939;
  color: #fff;
}

.show > .btn-danger.dropdown-toggle {
  background-color: #ee3939;
  border-color: #ee3939;
  color: #fff;
}

.btn-danger.active:focus:not(:disabled):not(.disabled), .btn-danger:active:focus:not(:disabled):not(.disabled) {
  box-shadow: unset;
}

.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-danger.disabled, .btn-danger:disabled {
  background-color: #f62d51;
  border-color: #f62d51;
  color: #fff;
}

.btn-light.active:focus:not(:disabled):not(.disabled), .btn-light:active:focus:not(:disabled):not(.disabled) {
  box-shadow: unset;
}

.show > .btn-light.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-dark.active:focus:not(:disabled):not(.disabled), .btn-dark:active:focus:not(:disabled):not(.disabled) {
  box-shadow: unset;
}

.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-outline-primary {
  color: #4C40ED;
  border-color: #4C40ED;
}
.btn-outline-primary:hover {
  background-color: #4C40ED;
  border-color: #4C40ED;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: none;
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #4C40ED;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active {
  background-color: #4C40ED;
  border-color: #4C40ED;
}

.show > .btn-outline-primary.dropdown-toggle {
  background-color: #4C40ED;
  border-color: #4C40ED;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
}

.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-success {
  color: #27c24c;
  border-color: #27c24c;
}
.btn-outline-success:hover {
  background-color: #27c24c;
  border-color: #27c24c;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: none;
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #27c24c;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active {
  background-color: #27c24c;
  border-color: #27c24c;
}

.show > .btn-outline-success.dropdown-toggle {
  background-color: #27c24c;
  border-color: #27c24c;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
}

.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-info {
  color: #009efb;
  border-color: #009efb;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #009efb;
  border-color: #009efb;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: none;
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  background-color: transparent;
  color: #009efb;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active {
  background-color: #009efb;
  border-color: #009efb;
}

.show > .btn-outline-info.dropdown-toggle {
  background-color: #009efb;
  border-color: #009efb;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
}

.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-warning {
  color: #ffbc34;
  border-color: #ffbc34;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffbc34;
  border-color: #ffbc34;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: none;
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  background-color: transparent;
  color: #ffbc34;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active {
  color: #212529;
  background-color: #ffbc34;
  border-color: #ffbc34;
}

.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffbc34;
  border-color: #ffbc34;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
}

.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-danger {
  color: #f05050;
  border-color: #f05050;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #f05050;
  border-color: #f05050;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: none;
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  background-color: transparent;
  color: #f05050;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active {
  background-color: #f05050;
  border-color: #f05050;
}

.show > .btn-outline-danger.dropdown-toggle {
  background-color: #f05050;
  border-color: #f05050;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
}

.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-light {
  color: #ababab;
  border-color: #e6e6e6;
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #ababab;
}

.pagination > .active > a {
  background-color: #4C40ED;
  border-color: #4C40ED;
  color: #fff;
}
.pagination > .active > a:focus, .pagination > .active > a:hover {
  background-color: #4C40ED;
  border-color: #4C40ED;
  color: #fff;
}
.pagination > .active > span {
  background-color: #4C40ED;
  border-color: #4C40ED;
  color: #fff;
}
.pagination > .active > span:focus, .pagination > .active > span:hover {
  background-color: #4C40ED;
  border-color: #4C40ED;
  color: #fff;
}
.pagination > li > a, .pagination > li > span {
  color: #4C40ED;
}

.page-link:hover {
  color: #4C40ED;
}
.page-link:focus {
  box-shadow: unset;
}

.page-item:first-child .page-link {
  margin-left: 0 !important;
}
.page-item .page-link {
  width: 25px;
  height: 25px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F5F5F5;
  border-radius: 5px;
  margin-left: 10px !important;
  color: #74788D;
}
.page-item.active .page-link {
  background-color: #4C40ED;
  border-color: #4C40ED;
}

.dropdown-menu {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  transform-origin: left top 0;
  box-shadow: inherit;
  background-color: #fff;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: #4C40ED;
}

.navbar-nav .open .dropdown-menu {
  border: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.settings-tab .nav .nav-link {
  margin-bottom: 15px;
  color: #333;
}
.settings-tab .nav .nav-link:hover {
  background: #e8e8e8;
  color: #333;
}
.settings-tab .nav .nav-link.active {
  background: #4C40ED;
  color: #fff;
}
.settings-tab .nav .show > .nav-link {
  background: #4C40ED;
  color: #fff;
}

.dropdown-menu {
  font-size: 14px;
}

.card-body {
  padding: 1.5rem;
}

.card-header {
  border-bottom: 1px solid #e6e6e6;
  padding: 1rem 1.5rem;
}

.card-footer {
  background-color: #fff;
  border-top: 1px solid #e6e6e6;
  padding: 1rem 1.5rem;
}

.card .card-header {
  background-color: #fff;
  border-bottom: 1px solid #eaeaea;
}
.card .card-header .card-title {
  margin-bottom: 0;
  font-size: 18px;
}

.btn-update:hover {
  background: transparent;
  color: #4C40ED;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.custom-radios {
  padding-left: 25px;
}
.custom-radios input[type=radio] + label {
  position: relative;
}
.custom-radios input[type=radio] + label::after {
  position: absolute;
  content: "";
  background: #C9C9C9;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  left: -18px;
  top: 6px;
}

.radiossets {
  display: block;
  position: relative;
  padding-left: 29px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 0 !important;
}
.radiossets input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark-radio {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid #D1D1D6;
}

.radiossets:hover input ~ .checkmark-radio {
  background-color: #eee;
}
.radiossets input:checked ~ .checkmark-radio {
  background-color: #fff;
  border: 1px solid #4C40ED;
}

.checkmark-radio:after {
  content: "";
  position: absolute;
  display: none;
}

.radiossets input:checked ~ .checkmark-radio:after {
  display: block;
}
.radiossets .checkmark-radio:after {
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #4C40ED;
}

ul.custom-radiosbtn {
  display: flex;
}
ul.custom-radiosbtn li {
  margin-right: 20px;
}

.input-tags.form-control {
  display: block;
  border-radius: 5px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  min-height: 50px;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 10px;
}

.bootstrap-tagsinput {
  display: block;
  border-radius: 8px;
  background: #fff;
  border: 1px solid #d8d8d8;
  min-height: 46px;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 10px;
  width: 100%;
  box-shadow: none;
}

.manidory {
  color: #4C40ED;
  margin-left: 5px;
}

.settings-label {
  margin-bottom: 10px;
}

.settings-btn {
  border: 1px solid #D7D7D7;
  border-radius: 5px;
  color: #000000;
  cursor: pointer;
  font-weight: 400;
  padding: 15px 15px;
  position: relative;
  width: 100%;
  height: 50px;
}
.settings-btn .hide-input {
  position: relative;
  z-index: 1;
  cursor: pointer;
  min-height: auto;
  padding-left: 4px;
  padding-top: 0;
  line-height: 10px;
  width: 100%;
  opacity: 0;
}
.settings-btn .upload {
  position: absolute;
  z-index: 11;
  background: #4C40ED;
  border-radius: 5px;
  right: 2px;
  top: 1.5px;
  width: 44px;
  height: 45px;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.btn-reset {
  background: #858585;
  color: #fff;
}

.form-head span {
  color: #FF0000;
  font-size: 10px;
  margin-left: 10px;
}

.form-heads {
  font-size: 18px;
  font-weight: 500;
  color: #4C40ED;
}

.socail-links-set ul {
  padding: 0;
  list-style: none;
  margin: 0;
}

.cards {
  border: 1px solid #EEF1FE;
  box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.0196078431);
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.socail-links-set a.dropdown-toggle.nav-link {
  background: #858585;
  color: #fff;
  border-radius: 5px;
}

.btn-reset:hover {
  background: #4C40ED;
  color: #fff;
}

.btn-success {
  background: #26AF48;
  color: #fff !important;
}

.f-14 {
  font-size: 14px !important;
}

.upload-images {
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #E4E4E4;
  border-radius: 5px;
  display: flex;
  margin-top: 20px;
  width: 196px;
  height: auto;
  justify-content: space-around;
  align-items: center;
  position: relative;
  padding: 5px;
}
.upload-images img {
  max-height: 195px;
  width: 100%;
}

.settings-size {
  font-size: 14px;
  color: #131523;
  margin-top: 10px;
  margin-bottom: 0;
  font-weight: 400;
}

.upload-images .btn-icon {
  color: #ff0000;
  font-size: 16px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  position: absolute;
  padding: 0;
  width: 15px;
  height: 15px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  right: 10px;
  top: 10px;
  justify-content: center;
  -webkit-justify-content: center;
  background: #fff;
  border-radius: 50%;
  font-size: 9px;
  border: 1px solid #ff0000;
}
.upload-images .btn-icon:hover {
  background: #ff0000;
  color: #fff;
}

.color-set {
  width: 25px;
  height: 25px;
  border-radius: 5px;
  display: block;
  margin-left: 25px;
  margin-right: 15px;
}

.clr-default {
  background: #C4C4C4;
}

.clr-orange {
  background: #FC8543;
}

.clr-blue {
  background: #0F6FDE;
}

.clr-red {
  background: #F43F3F;
}

.clr-green {
  background: #17D053;
}
.slidecontainer {
  width: 100%;
}

.admin-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: linear-gradient(to right, #4C40ED 0%, #4C40ED 50%, #c4c4c4 50%, #c4c4c4 100%);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 10px;
}
.slider::-webkit-slider-thumb:hover, .slider::-moz-range-thumb:hover {
  background: #000000;
}

.upload-images.upload-imagesprofile img {
  width: 68px;
  height: 68px;
  background: #c4c4c4;
  padding: 10px;
  border-radius: 5px;
}
.upload-images h6 {
  font-size: 12px;
  font-weight: 600;
  margin-top: 10px;
}
.upload-images h5 {
  font-size: 10px;
  font-weight: 400;
}
.upload-images.upload-imagesprofile {
  width: 80px;
  height: 80px;
  padding: 5px;
  display: block;
}

.loan-set h3 {
  font-size: 12px;
  color: #131523;
  font-weight: 400;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
  background: #4C40ED;
  border: 2px solid #fff;
  box-shadow: 0px 4px 14px rgba(154, 154, 154, 0.25);
}
.slider::-moz-range-thumb {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background: #FFFFFF;
  box-shadow: 0px 4px 14px rgba(154, 154, 154, 0.25);
}

.bootstrap-tagsinput .tag {
  margin-right: 5px;
  margin-bottom: 2px;
  color: #9597A9;
  background: #F2F2F4 !important;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 20px 10px 15px;
  border-radius: 5px;
}

.hidden-web {
  display: block;
}

.custom-radios .custom-control-input:checked ~ .custom-control-label::before {
  border: 1px solid #4C40ED;
}
.custom-radios .custom-control-input:checked ~ .custom-control-label::after {
  background: #4C40ED;
}
.custom-radios input[type=radio] + label::before {
  position: absolute;
  content: "";
  width: 18px;
  height: 18px;
  border: 1px solid #C9C9C9;
  left: -22px;
  top: 2px;
  border-radius: 50%;
  background: #fff !important;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}

.modal-footer.text-left {
  text-align: left;
}
.modal-footer.text-center {
  text-align: center;
}

.btn-light {
  border-color: #e6e6e6;
  color: #a6a6a6;
}

.bootstrap-datetimepicker-widget table td.active {
  background-color: #4C40ED;
  text-shadow: unset;
}
.bootstrap-datetimepicker-widget table td.active:hover {
  background-color: #4C40ED;
  text-shadow: unset;
}
.bootstrap-datetimepicker-widget table td.today:before {
  border-bottom-color: #4C40ED;
}

.bg-info-light {
  background-color: rgba(2, 182, 179, 0.12) !important;
  color: #1db9aa !important;
}

.bg-primary-light {
  background-color: rgba(17, 148, 247, 0.12) !important;
  color: #2196f3 !important;
}

.bg-danger-light {
  background-color: rgba(242, 17, 54, 0.12) !important;
  color: #e63c3c !important;
}

.bg-warning-light {
  background-color: rgba(255, 152, 0, 0.12) !important;
  color: #f39c12 !important;
}

.bg-success-light {
  background-color: rgba(15, 183, 107, 0.12) !important;
  color: #26af48 !important;
}

.bg-purple-light {
  background-color: rgba(197, 128, 255, 0.12) !important;
  color: #c580ff !important;
}

.bg-default-light {
  background-color: rgba(40, 52, 71, 0.12) !important;
  color: #283447 !important;
}

.dropdown-item {
  font-size: 13px;
  color: #74788D;
}

/*-----------------
	4. Select2
-----------------------*/
.select2-container {
  z-index: unset;
}
.select2-container .select2-selection--single {
  border: 1px solid #d8d8d8;
  height: 42px;
  border-radius: 0.375rem;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 38px;
  right: 7px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #ddd transparent transparent;
  border-style: solid;
  border-width: 6px 6px 0;
  height: 0;
  left: 50%;
  margin-left: -10px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #ddd;
  border-width: 0 6px 6px;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-right: 30px;
  padding-left: 0.75rem;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #262a2e;
  line-height: 42px;
}

.form-group .select2-container--default .select2-selection--single .select2-selection__rendered {
  padding-left: 35px;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: #9597A9;
  border-width: 2px 0 0 2px;
  padding: 3px;
  margin-top: -2px;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #4C40ED;
}
.select2-container--default .select2-selection--multiple {
  border: 1px solid #F8F8F8;
  min-height: 42px;
  background-color: #F9F9FB;
  padding: 4px 8px;
  border-radius: 8px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #FFFFFF;
  border: 0;
  font-size: 13px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  margin-right: auto;
  float: right;
  margin-left: 15px;
  width: 15px;
  height: 15px;
  background: #9597A9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  position: relative;
  top: 3px;
  padding: 2px;
}

.select2-dropdown {
  border: 1px solid #E6E9E8;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  margin-top: 6px;
  padding: 3px 5px;
}

/*-----------------
	5. Nav Tabs
-----------------------*/
.nav-tabs {
  border-bottom: 1px solid #e6e6e6;
}

.card-header-tabs {
  border-bottom: 0;
}

.nav-tabs > li > a {
  margin-right: 0;
  color: #888;
  border-radius: 0;
}
.nav-tabs > li > a:hover, .nav-tabs > li > a:focus {
  border-color: transparent;
  color: #333;
}
.nav-tabs.nav-tabs-solid > li > a {
  color: #333;
}
.nav-tabs.nav-tabs-solid > .active > a {
  background-color: #4C40ED;
  border-color: #4C40ED;
  color: #fff;
}
.nav-tabs.nav-tabs-solid > .active > a:hover, .nav-tabs.nav-tabs-solid > .active > a:focus {
  background-color: #4C40ED;
  border-color: #4C40ED;
  color: #fff;
}

.tab-content {
  padding-top: 20px;
}

.nav-tabs .nav-link {
  border-radius: 0;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  background-color: #eee;
  border-color: transparent;
  color: #333;
}
.nav-tabs.nav-justified > li > a {
  border-radius: 0;
  margin-bottom: 0;
}
.nav-tabs.nav-justified > li > a:hover, .nav-tabs.nav-justified > li > a:focus {
  border-bottom-color: #ddd;
}
.nav-tabs.nav-justified.nav-tabs-solid > li > a {
  border-color: transparent;
}
.nav-tabs.nav-tabs-solid > li > a {
  color: #333;
}
.nav-tabs.nav-tabs-solid > li > a.active {
  background-color: #4C40ED;
  border-color: #4C40ED;
  color: #fff;
}
.nav-tabs.nav-tabs-solid > li > a.active:hover, .nav-tabs.nav-tabs-solid > li > a.active:focus {
  background-color: #4C40ED;
  border-color: #4C40ED;
  color: #fff;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
  border-radius: 50px;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a {
  border-radius: 50px;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active {
  border-radius: 50px;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:hover, .nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:focus {
  border-radius: 50px;
}

.nav-tabs-justified > li > a {
  border-radius: 0;
  margin-bottom: 0;
}
.nav-tabs-justified > li > a:hover, .nav-tabs-justified > li > a:focus {
  border-bottom-color: #ddd;
}
.nav-tabs-justified.nav-tabs-solid > li > a {
  border-color: transparent;
}

.nav-tabs.nav-justified.nav-tabs-top {
  border-bottom: 1px solid #ddd;
}
.nav-tabs.nav-justified.nav-tabs-top > li > a {
  border-width: 2px 0 0 0;
}
.nav-tabs.nav-justified.nav-tabs-top > li > a:hover, .nav-tabs.nav-justified.nav-tabs-top > li > a:focus {
  border-width: 2px 0 0 0;
}
.nav-tabs.nav-tabs-top > li {
  margin-bottom: 0;
}
.nav-tabs.nav-tabs-top > li > a {
  border-width: 2px 0 0 0;
}
.nav-tabs.nav-tabs-top > li > a:hover, .nav-tabs.nav-tabs-top > li > a:focus {
  border-width: 2px 0 0 0;
}
.nav-tabs.nav-tabs-top > li.open > a {
  border-top-color: #ddd;
}
.nav-tabs.nav-tabs-top > li > a:hover, .nav-tabs.nav-tabs-top > li > a:focus {
  border-top-color: #ddd;
}
.nav-tabs.nav-tabs-top > li + li > a {
  margin-left: 1px;
}
.nav-tabs.nav-tabs-top > li > a.active {
  border-top-color: #4C40ED;
}
.nav-tabs.nav-tabs-top > li > a.active:hover, .nav-tabs.nav-tabs-top > li > a.active:focus {
  border-top-color: #4C40ED;
}
.nav-tabs.nav-tabs-bottom > li {
  margin-bottom: -1px;
}
.nav-tabs.nav-tabs-bottom > li > a.active {
  border-bottom-width: 2px;
  border-color: transparent;
  border-bottom-color: #4C40ED;
  background-color: transparent;
  transition: none 0s ease 0s;
  -moz-transition: none 0s ease 0s;
  -o-transition: none 0s ease 0s;
  -ms-transition: none 0s ease 0s;
  -webkit-transition: none 0s ease 0s;
}
.nav-tabs.nav-tabs-bottom > li > a.active:hover, .nav-tabs.nav-tabs-bottom > li > a.active:focus {
  border-bottom-width: 2px;
  border-color: transparent;
  border-bottom-color: #4C40ED;
  background-color: transparent;
  transition: none 0s ease 0s;
  -moz-transition: none 0s ease 0s;
  -o-transition: none 0s ease 0s;
  -ms-transition: none 0s ease 0s;
  -webkit-transition: none 0s ease 0s;
}
.nav-tabs.nav-tabs-solid {
  background-color: #fafafa;
  border: 0;
}
.nav-tabs.nav-tabs-solid > li {
  margin-bottom: 0;
}
.nav-tabs.nav-tabs-solid > li > a {
  border-color: transparent;
}
.nav-tabs.nav-tabs-solid > li > a:hover, .nav-tabs.nav-tabs-solid > li > a:focus {
  background-color: #f5f5f5;
}
.nav-tabs.nav-tabs-solid > .open:not(.active) > a {
  background-color: #f5f5f5;
  border-color: transparent;
}

.nav-tabs-justified.nav-tabs-top {
  border-bottom: 1px solid #ddd;
}
.nav-tabs-justified.nav-tabs-top > li > a {
  border-width: 2px 0 0 0;
}
.nav-tabs-justified.nav-tabs-top > li > a:hover, .nav-tabs-justified.nav-tabs-top > li > a:focus {
  border-width: 2px 0 0 0;
}

/*-----------------
	6. Components
-----------------------*/
.progress-xs {
  height: 4px;
}

.progress-sm {
  height: 15px;
}

.progress.progress-sm {
  height: 6px;
}
.progress.progress-md {
  height: 8px;
}
.progress.progress-lg {
  height: 18px;
}

.row.row-sm {
  margin-left: -3px;
  margin-right: -3px;
}
.row.row-sm > div {
  padding-left: 3px;
  padding-right: 3px;
}

.swal2-title {
  font-size: 22px !important;
  font-weight: 500;
  color: #28283C !important;
}

.swal2-content {
  font-size: 14px !important;
  color: #74788D !important;
}

.swal2-styled.swal2-confirm {
  font-size: 14px !important;
}

/*-----------------
	7. Avatar
-----------------------*/
.avatar {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 3rem;
}
.avatar > img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.avatar-title {
  width: 100%;
  height: 100%;
  background-color: #4C40ED;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar-away:before, .avatar-offline:before {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 25%;
  height: 25%;
  border-radius: 50%;
  content: "";
  border: 2px solid #fff;
}

.avatar-online:before {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 25%;
  height: 25%;
  border-radius: 50%;
  content: "";
  border: 2px solid #fff;
  background-color: #27c24c;
}

.avatar-offline:before {
  background-color: #f05050;
}

.avatar-away:before {
  background-color: #ffbc34;
}

.avatar .border {
  border-width: 3px !important;
}
.avatar .rounded {
  border-radius: 6px !important;
}
.avatar .avatar-title {
  font-size: 18px;
}

.avatar-xs {
  width: 1.65rem;
  height: 1.65rem;
}
.avatar-xs .border {
  border-width: 2px !important;
}
.avatar-xs .rounded {
  border-radius: 4px !important;
}
.avatar-xs .avatar-title {
  font-size: 12px;
}
.avatar-xs.avatar-away:before, .avatar-xs.avatar-offline:before, .avatar-xs.avatar-online:before {
  border-width: 1px;
}

.avatar-sm {
  width: 2.5rem;
  height: 2.5rem;
}
.avatar-sm .border {
  border-width: 3px !important;
}
.avatar-sm .rounded {
  border-radius: 4px !important;
}
.avatar-sm .avatar-title {
  font-size: 15px;
}
.avatar-sm.avatar-away:before, .avatar-sm.avatar-offline:before, .avatar-sm.avatar-online:before {
  border-width: 2px;
}

/*-----------------
	8. Header
-----------------------*/
.admin-header {
  background: #3b3b3b;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  height: 76px;
  transition: all 0.45s ease 0s;
}
.admin-header .header-left {
  float: left;
  height: 76px;
  padding: 0 20px;
  position: relative;
  text-align: center;
  width: 270px;
  z-index: 1;
  transition: all 0.2s ease-in-out;
  display: none;
}
.admin-header .header-left .logo {
  display: inline-block;
  line-height: 60px;
}
.admin-header .header-left .logo img {
  max-height: 24px;
  width: auto;
}

.page-headers h6 {
  margin: 0;
  font-size: 22px;
  color: #28283C;
  font-weight: 500;
}

.header-left .logo.logo-small {
  display: none;
}

.header-split {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.admin-header .dropdown-menu > li > a {
  position: relative;
}
.admin-header .dropdown-toggle:after {
  display: none;
}
.admin-header .has-arrow .dropdown-toggle:after {
  border-top: 0;
  border-left: 0;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  content: "";
  height: 8px;
  display: inline-block;
  pointer-events: none;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: 8px;
  vertical-align: 2px;
}

.animate-circle {
  -webkit-animation: heartbit 1s ease-out;
  animation: heartbit 3s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border: 5px solid #4C40ED;
  border-radius: 70px;
  height: 57px;
  position: absolute;
  left: -7px;
  top: -7px;
  width: 57px;
  z-index: -1;
}

@keyframes heartbit {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  25% {
    transform: scale(0.1);
    opacity: 0.1;
  }
  50% {
    transform: scale(0.5);
    opacity: 0.3;
  }
  75% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
.admin-header .has-arrow .dropdown-toggle[aria-expanded=true]:after {
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.admin-user-menu {
  float: right;
  margin: 0;
  position: relative;
  z-index: 99;
  align-items: center;
}

.dropdown-heads {
  margin: 0 5px;
}
.dropdown-heads > a {
  background: #F7F7FA;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 50px;
  justify-content: center;
}

.admin-user-menu.nav > li > a {
  color: #74788D;
  font-size: 14px;
  padding: 0 15px;
}
.admin-user-menu.nav > li > a:hover, .admin-user-menu.nav > li > a:focus {
  background-color: transparent;
}
.admin-user-menu.nav > li.view-btn > a:hover i {
  color: #fff;
}
.admin-user-menu.nav > li.view-btn > a:focus {
  background-color: #923564 !important;
}
.admin-user-menu.nav > li.view-btn > a:focus i {
  color: #fff;
}
.admin-user-menu.nav > li > a:hover i, .admin-user-menu.nav > li > a:focus i {
  color: #333;
}

.user-img {
  margin-right: 3px;
  position: relative;
  width: 44px;
  height: 44px;
  background: #D9D9D9;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-img img {
  width: 40px;
  height: 40px;
  -o-object-fit: cover;
  object-fit: cover;
  border: 3px solid #FFFFFF;
  background: #D9D9D9;
  padding: 3px;
}

.user-link {
  display: flex;
  align-items: center;
}

span.user-content {
  padding-left: 8px;
}
span.user-name {
  display: block;
  color: #28283C;
  font-size: 15px;
  font-weight: 600;
}
span.user-details {
  color: #9E9E9E;
  font-size: 14px;
  font-weight: 400;
}

.admin-user-menu.nav > li > a.mobile_btn {
  border: 0;
  position: relative;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
.admin-user-menu .dropdown-menu {
  min-width: 200px;
  padding: 0;
  margin-top: 12px !important;
}
.admin-user-menu .dropdown-menu .dropdown-item {
  padding: 7px 15px;
  display: flex;
  align-items: center;
  border-top: 1px solid #e3e3e3;
  padding: 10px 15px;
}
.admin-user-menu .dropdown-menu .dropdown-item:hover {
  color: #4C40ED;
}

.admin-header .dropdown-menu > li > a {
  padding: 10px 18px;
}
.admin-header .dropdown-menu > li > a:focus, .admin-header .dropdown-menu > li > a:hover {
  background-color: #4C40ED;
  color: #fff;
}
.admin-header .dropdown-menu > li > a:focus i, .admin-header .dropdown-menu > li > a:hover i {
  color: #fff;
}
.admin-header .dropdown-menu > li > a i {
  color: #4C40ED;
  margin-right: 10px;
  text-align: center;
  width: 18px;
}
.admin-header .admin-user-menu .dropdown-menu > li > a i {
  color: #4C40ED;
  font-size: 16px;
  margin-right: 10px;
  min-width: 18px;
  text-align: center;
}
.admin-header .admin-user-menu .dropdown-menu > li > a:focus i, .admin-header .admin-user-menu .dropdown-menu > li > a:hover i {
  color: #fff;
}

.mobile_btn {
  display: none;
  float: left;
}

.slide-nav .admin-sidebar {
  margin-left: 0;
}

.user-header {
  background-color: #f9f9f9;
  display: flex;
  padding: 10px 15px;
}
.user-header .user-text {
  margin-left: 10px;
}
.user-header .user-text h6 {
  margin-bottom: 2px;
}

.menu-title {
  color: #a3a3a3;
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  padding: 0 25px;
}

.sidebar-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}
.sidebar-overlay.opened {
  display: block;
}

html.menu-opened {
  overflow: hidden;
}
html.menu-opened body {
  overflow: hidden;
}

.admin-user-menu.nav > li.view-btn > a {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 9px 15px;
  border-radius: 6px;
  background-color: #4C40ED;
}
.admin-user-menu.nav > li.view-btn > a:hover {
  background-color: #e60073;
  color: #fff;
}
.admin-user-menu.nav > li.view-btn > a i {
  font-size: 17px;
  position: relative;
}

.view-btn {
  margin-right: 15px;
}

.select-by {
  min-width: 134px;
  margin-right: 25px;
}

.modal.contentmodal .modal-content h3 {
  font-size: 24px;
}

.delete-wrap {
  margin-bottom: 30px;
}
.delete-wrap h2 {
  font-size: 24px;
  margin-bottom: 25px;
}

.modal-content .close-btn i {
  font-size: 20px;
  color: #F0142F;
}
.modal-content.doctor-profile {
  border-radius: 10px;
}

.delete-wrap p {
  font-size: 24px;
  margin-bottom: 20px;
}

.del-icon {
  margin-bottom: 23px;
}
.del-icon i {
  font-size: 60px;
  color: #F0142F;
  margin-bottom: 23px;
}

.submit-section {
  text-align: center;
  margin-top: 25px;
}

.del-icon {
  margin-bottom: 23px;
}

.modal.contentmodal .modal-content .submit-section .btn {
  padding: 7px 12px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  color: #FFFFFF;
  min-width: 150px;
  margin-top: 5px;
}

/*-----------------
	9. Sidebar
-----------------------*/
.admin-sidebar {
  background-color: #080C18;
  bottom: 0;
  left: 0;
  margin-top: 0;
  position: fixed;
  top: 0;
  transition: all 0.2s ease-in-out 0s;
  width: 270px;
  z-index: 1001;
  border-radius: 0 15px 0 0;
}
.admin-sidebar.opened {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.admin-sidebar-inner {
  height: 100%;
  min-height: 100%;
  transition: all 0.2s ease-in-out 0s;
}

.admin-sidebar-menu {
  padding: 10px;
  padding-bottom: 35px;
}
.admin-sidebar-menu ul {
  font-size: 15px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;
}
.admin-sidebar-menu li a {
  color: #fff;
  display: block;
  font-size: 15px;
  height: auto;
  padding: 0 20px;
}
.admin-sidebar-menu li a:hover {
  color: #4C40ED;
}
.admin-sidebar-menu li.active > a {
  background-color: #4C40ED;
  color: #fff;
}
.admin-sidebar-menu > ul > li > a:hover, .admin-sidebar-menu > ul > li.active > a:hover {
  background-color: #4C40ED;
  color: #fff;
}

.menu-title {
  color: #9e9e9e;
  display: flex;
  font-size: 14px;
  opacity: 1;
  padding: 5px 10px 5px 20px;
  white-space: nowrap;
  margin-top: 30px;
  text-transform: uppercase;
}
.menu-title h6 {
  color: #74788D;
  margin: 0;
  font-size: 12px;
}

.admin-siderbar-toggle .switch {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
}

.admin-sidebar-menu > ul > li > a.active {
  position: relative;
  color: #fff;
  background-color: #4C40ED;
}
.admin-sidebar-menu > ul > li > a.active.subdrop {
  color: #fff;
  background-color: #4C40ED;
}
.admin-sidebar-menu > ul > li > a.active:hover {
  color: #fff;
}
.admin-sidebar-menu > ul > li > a.active:after {
  background: #4C40ED;
  width: 6px;
  height: 40px;
  left: -10px;
  z-index: 9999;
  position: absolute;
  content: "";
  border-radius: 0 5px 5px 0;
}

.admin-siderbar-toggle {
  position: relative;
}

.admin-sidebar-header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 27px;
  justify-content: space-between;
}

.admin-siderbar-toggle .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.admin-siderbar-toggle .admin-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #CCCFE0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: 41px;
  height: 21px;
}
.admin-siderbar-toggle .slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.admin-siderbar-toggle input:checked + .admin-slider {
  background-color: #4C40ED;
}
.admin-siderbar-toggle input:focus + .admin-slider {
  box-shadow: 0 0 1px #4C40ED;
}
.admin-siderbar-toggle input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  left: -5px;
}
.admin-siderbar-toggle .admin-slider.round {
  border-radius: 34px;
}
.admin-siderbar-toggle .admin-slider.round:before {
  border-radius: 50%;
}

.menu-title > i {
  float: right;
  line-height: 40px;
}

.admin-sidebar-menu li.menu-title a {
  color: #ff9b44;
  display: inline-block;
  margin-left: auto;
  padding: 0;
}

.form-links a {
  color: #009AF4;
  text-decoration: underline;
  font-size: 14px;
}

.form-groupbtn ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
}
.form-groupbtn .btn {
  min-width: 104px;
  padding: 7px 15px;
  font-size: 16px;
}

.socail-links-set .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  position: absolute;
  right: 10px;
  top: 17px;
}

.form-groupbtn ul li {
  margin-right: 12px;
}

.btn-update {
  border: 1px solid #4C40ED;
  color: #fff;
  background: #4C40ED;
}

.btn-cancel {
  border: 1px solid #F3F3F3;
  color: #3F3F3F;
  background: #F3F3F3;
}
.btn-cancel:hover {
  background: #242424;
  color: #fff;
}

.card-heads {
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: flex;
}

.admin-sidebar-menu li.menu-title a.btn {
  color: #fff;
  display: block;
  float: none;
  font-size: 15px;
  margin-bottom: 15px;
  padding: 10px 15px;
}
.admin-sidebar-menu ul ul a.active {
  color: #4C40ED;
}

.mobile_btn {
  display: none;
  float: left;
}

.admin-sidebar .admin-sidebar-menu > ul > li > a span {
  transition: all 0.2s ease-in-out 0s;
  display: inline-block;
  margin-left: 10px;
  white-space: nowrap;
}
.admin-sidebar .admin-sidebar-menu > ul > li > a span.chat-user {
  margin-left: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.admin-sidebar .admin-sidebar-menu > ul > li > a span.badge {
  margin-left: auto;
}

.admin-sidebar-menu ul ul {
  display: none;
}
.admin-sidebar-menu ul ul a {
  display: block;
  font-size: 15px;
  padding: 7px 10px 7px 50px;
  position: relative;
}
.admin-sidebar-menu ul ul ul a {
  padding-left: 65px;
}
.admin-sidebar-menu ul ul ul ul a {
  padding-left: 85px;
}
.admin-sidebar-menu > ul > li {
  margin-bottom: 5px;
  position: relative;
}
.admin-sidebar-menu > ul > li:last-child {
  margin-bottom: 0;
}
.admin-sidebar-menu .menu-arrow i {
  -webkit-transition: -webkit-transform 0.15s;
  -o-transition: -o-transform 0.15s;
  transition: transform 0.15s;
  position: absolute;
  right: 15px;
  display: inline-block;
  text-rendering: auto;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  top: 12px;
  width: auto;
}
.admin-sidebar-menu li a.subdrop .menu-arrow i {
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.admin-sidebar-menu ul ul a .menu-arrow {
  top: 10px;
}
.admin-sidebar-menu > ul > li > a {
  align-items: center;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  padding: 10px 20px;
  position: relative;
  transition: all 0.2s ease-in-out 0s;
}
.admin-sidebar-menu > ul > li > a.subdrop {
  background: transparent;
  color: #fff;
}

.mini-sidebar .admin-siderbar-toggle {
  opacity: 0;
  width: 1px;
}
.mini-sidebar .logo-small {
  display: block;
  min-width: 30px;
}
.mini-sidebar .admin-sidebar-header {
  padding: 15px 0;
  justify-content: center;
}
.mini-sidebar.expand-menu .admin-sidebar-header {
  padding: 27px;
  justify-content: space-between;
}
.mini-sidebar .logo {
  display: none;
}

.logo-small {
  display: none;
}

.mini-sidebar.expand-menu .logo {
  display: block;
}
.mini-sidebar.expand-menu .logo-small {
  display: none;
}
.mini-sidebar.expand-menu .admin-siderbar-toggle {
  opacity: 1;
  width: auto;
}

.admin-sidebar-menu ul li a i {
  display: inline-block;
  font-size: 18px;
  text-align: left;
  vertical-align: middle;
  width: 18px;
  transition: all 0.2s ease-in-out 0s;
}
.admin-sidebar-menu ul li.menu-title a i {
  font-size: 16px !important;
  margin-right: 0;
  text-align: right;
  width: auto;
}
.admin-sidebar-menu li a > .badge {
  color: #fff;
}

/*-----------------
	10. Content
-----------------------*/
.main-wrapper {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
}

.page-wrapper {
  margin-left: 270px;
  padding-top: 60px;
  position: relative;
  transition: all 0.4s ease;
}
.page-wrapper > .content {
  padding: 30px;
  width: 100%;
}

.page-header {
  margin-bottom: 1.875rem;
}
.page-header .breadcrumb {
  background-color: transparent;
  color: #6c757d;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0;
  padding: 0;
}
.page-header .breadcrumb a {
  color: #333;
}

.page-title {
  color: #333;
  margin-bottom: 5px;
}

.login-pages {
  padding: 40px 70px;
}

.login-logo {
  margin-bottom: 80px;
  text-align: center;
}

.login-input .form-group label {
  color: #000000;
  font-size: 12px;
}

.login-contenthead h5 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 18px;
}

.flag-nav img {
  min-width: 18px;
  height: 18px;
  width: 18px;
}

.login-contenthead {
  margin-bottom: 60px;
}
.login-contenthead h6 {
  font-size: 14px;
  color: #71717A;
  font-weight: 400;
  margin-bottom: 18px;
}
.login-contenthead p {
  margin: 0;
  color: #040404;
  font-weight: 400;
}
.login-contenthead p a {
  color: #0E82FD;
  text-decoration: underline;
}

.login-input .form-control {
  background: #fff;
  border: 1px solid #E4E4E8;
}

.forgetpassword-link {
  color: #0E82FD;
  text-decoration: underline;
}

.login-input .form-control:focus {
  border: 1px solid #4C40ED;
  border-left: 5px solid #4C40ED;
}

.tab-sets {
  border-bottom: 2px solid #E7E7E7;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tab-contents-sets ul {
  display: flex;
  align-items: center;
}
.tab-contents-sets ul li + li {
  padding-left: 35px;
}
.tab-contents-sets ul li a {
  color: #74788D;
  font-weight: 500;
  position: relative;
}
.tab-contents-sets ul li a.active {
  color: #4C40ED;
}
.tab-contents-sets ul li a.active::after {
  position: absolute;
  content: "";
  background: #4C40ED;
  width: 100%;
  height: 2px;
  left: 0;
  top: 29px;
}
.tab-contents-sets ul li a:hover::after {
  position: absolute;
  content: "";
  background: #4C40ED;
  width: 100%;
  height: 2px;
  left: 0;
  top: 29px;
}

.table-div {
  padding-top: 40px;
}

.tab-contents-sets ul li a:hover {
  color: #4c40ed;
}

.tab-contents-count h6 {
  margin: 0;
  font-size: 14px;
  color: #74788D;
  font-weight: normal;
}

.table-imgname {
  align-items: center;
  display: inline-flex;
  font-size: inherit;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  white-space: nowrap;
  padding-right: 30px;
}
.table-imgname img {
  width: 40px;
  height: 40px;
  border-radius: 5px;
}

.badge-pending {
  background: #FFF9E8;
  padding: 6px;
  min-width: 80px;
  margin: 0;
  font-size: 13px;
  color: #FFC107;
  border-radius: 50px;
  display: inline-block;
  text-align: center;
}

.delete-table:hover img {
  filter: brightness(0) invert(1);
}

.badge-inactive {
  background: #F1F8FF;
  padding: 6px;
  min-width: 80px;
  margin: 0;
  font-size: 13px;
  color: #007BFF;
  border-radius: 50px;
  display: inline-block;
  text-align: center;
}

.badge-active {
  background: #F2FFF6;
  padding: 6px;
  min-width: 80px;
  margin: 0;
  font-size: 13px;
  color: #1BA345;
  border-radius: 50px;
  display: inline-block;
  text-align: center;
}
.badge-active.inprogress {
  background: #FFF9E8;
  color: #FFC107;
}
.badge-active.inprogress:hover {
  background: #FFF9E8;
  color: #FFC107;
}
.badge-active.badge-reject {
  background: #FFF3F3;
  color: #FF4949;
}
.badge-active.badge-reject:hover {
  background: #FFF3F3;
  color: #FF4949;
}
.badge-active.badge-pending {
  background: #F1F8FF;
  color: #007BFF;
}
.badge-active.badge-pending:hover {
  background: #F1F8FF;
  color: #007BFF;
}
.badge-active:hover {
  background: #F2FFF6;
  padding: 6px;
  min-width: 80px;
  margin: 0;
  font-size: 13px;
  color: #1BA345;
  display: inline-block;
  text-align: center;
}

.badge-delete {
  background: #FDE2E7;
  padding: 6px;
  min-width: 80px;
  margin: 0;
  font-size: 14px;
  color: #E63C3C;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
}

.badge-delete.action-delete {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
  max-width: 74px;
}

.badge-delete.action-delete:hover {
  color: #E63C3C;
}

.badge-delete.action-delete img {
  margin-right: 5px;
}

/*-----------------
	11. Toggle Button
-----------------------*/
.check {
  display: block;
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  position: absolute;
}

.checktoggle {
  background-color: #e0001a;
  border-radius: 12px;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 24px;
  margin-bottom: 0;
  position: relative;
  width: 48px;
}
.checktoggle:after {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(5px, -50%);
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  transition: left 300ms ease, transform 300ms ease;
}

.check:checked + .checktoggle {
  background-color: #55ce63;
}
.check:checked + .checktoggle:after {
  left: 100%;
  transform: translate(calc(-100% - 5px), -50%);
}

.onoffswitch {
  margin-left: auto;
  position: relative;
  width: 73px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 20px;
  margin-bottom: 0;
}

.onoffswitch-inner {
  display: block;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
  width: 200%;
}
.onoffswitch-inner:before, .onoffswitch-inner:after {
  box-sizing: border-box;
  color: #fff;
  display: block;
  float: left;
  font-size: 16px;
  height: 30px;
  line-height: 32px;
  padding: 0;
  width: 50%;
}
.onoffswitch-inner:before {
  background-color: #55ce63;
  color: #fff;
  content: "ON";
  padding-left: 14px;
}
.onoffswitch-inner:after {
  content: "OFF";
  padding-right: 14px;
  background-color: #ccc;
  color: #fff;
  text-align: right;
}

.onoffswitch-switch {
  background: #fff;
  border-radius: 20px;
  bottom: 0;
  display: block;
  height: 20px;
  margin: 5px;
  position: absolute;
  right: 43px;
  top: 0;
  transition: all 0.3s ease-in 0s;
  width: 20px;
}

.password-strength {
  display: flex;
  margin-top: 15px;
}
.password-strength span {
  width: 100%;
  height: 5px;
  background: #E3E3E3;
  margin-right: 10px;
  border-radius: 5px;
}
.password-strength span:last-child {
  margin-right: 0;
}

.weak-pass .password-strength span {
  background: #FF0000;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}

.custom-modal .modal-body {
  padding: 20px 20px 0px 20px;
}

/*-----------------
	12. Login
-----------------------*/
.login-wrapper {
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

.loginbox {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.02);
  max-width: 500px;
  padding: 40px;
  width: 100%;
}

.account-logo img {
  max-height: 80px;
  width: auto;
}

/*-----------------
	13. Notifications
-----------------------*/
.notifications {
  padding: 0;
}
.notifications .notification-time {
  font-size: 12px;
  line-height: 1.35;
  color: #bdbdbd;
}
.notifications .media {
  margin-top: 0;
  border-bottom: 1px solid #f5f5f5;
}
.notifications .media:last-child {
  border-bottom: none;
}
.notifications .media a {
  display: block;
  padding: 10px 15px;
  border-radius: 2px;
}
.notifications .media a:hover {
  background-color: #fafafa;
}
.notifications .media > .avatar {
  margin-right: 10px;
}
.notifications .media-list .media-left {
  padding-right: 8px;
}

.topnav-dropdown-header {
  border-bottom: 1px solid #eee;
  text-align: center;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  padding-left: 15px;
  padding-right: 15px;
}

.topnav-dropdown-footer {
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  border-top: 1px solid #eee;
}
.topnav-dropdown-footer a {
  display: block;
  text-align: center;
  color: #333;
}

.admin-user-menu.nav > li > a .badge {
  background-color: #f43f3f;
  display: block;
  font-size: 10px;
  font-weight: bold;
  height: 10px;
  width: 10px;
  position: absolute;
  right: 7px;
  top: 7px;
  border: 2px solid #fff;
  line-height: unset;
  padding: 0;
}
.admin-user-menu.nav > li > a > i {
  font-size: 18px;
}

.noti-details {
  color: #989c9e;
  margin-bottom: 0;
}

.noti-title {
  color: #333;
}

.notifications .noti-content {
  height: 290px;
  width: 350px;
  overflow-y: auto;
  position: relative;
}

.notification-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.notifications ul.notification-list > li {
  margin-top: 0;
  border-bottom: 1px solid #f5f5f5;
}
.notifications ul.notification-list > li:last-child {
  border-bottom: none;
}
.notifications ul.notification-list > li a {
  display: block;
  padding: 20px 15px;
  border-radius: 2px;
}
.notifications ul.notification-list > li a:hover {
  background-color: #fafafa;
}
.notifications ul.notification-list > li .list-item {
  border: 0;
  padding: 0;
  position: relative;
}

.topnav-dropdown-header .notification-title {
  color: #333;
  display: block;
  float: left;
  font-size: 14px;
}
.topnav-dropdown-header .clear-noti {
  color: #f83f37;
  float: right;
  font-size: 12px;
  text-transform: uppercase;
}

.noti-time {
  margin: 0;
}

/*-----------------
	14. Dashboard
-----------------------*/
.dash-widget-icon {
  align-items: center;
  border-radius: 10px;
  color: #fff;
  display: inline-flex;
  font-size: 40px;
  height: 80px;
  justify-content: center;
  text-align: center;
  width: 80px;
  background-color: #4C40ED;
}

.dash-count {
  font-size: 18px;
  margin-left: auto;
}

.dash-widget-info {
  margin-left: auto;
  text-align: right;
}
.dash-widget-info h3 {
  margin-bottom: 10px;
  font-size: 1.5rem;
}
.dash-widget-info h6 {
  margin-bottom: 0;
}

.dash-widget-header {
  align-items: center;
  display: flex;
}

.submit-section {
  text-align: center;
  margin-top: 30px;
}

.back-btn:hover {
  color: #000000;
}

.submit-btn {
  background-color: #000000;
  color: #fff;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  min-width: 200px;
  padding: 10px 20px;
}
.submit-btn:hover {
  background-color: #85084c;
}

/*-----------------
	15. Profile
-----------------------*/
.profile-menu {
  background-color: #fff;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  padding: 0.9375rem 1.5rem;
}
.profile-menu .nav-tabs.nav-tabs-solid {
  background-color: transparent;
}

.cal-icon {
  position: relative;
  width: 100%;
}
.cal-icon:after {
  color: #979797;
  content: "\f073";
  display: block;
  font-family: "Font Awesome 5 Free";
  font-weight: 500;
  font-size: 15px;
  margin: auto;
  position: absolute;
  right: 15px;
  top: 10px;
}

.dropdown-heads > a:hover {
  background: #4C40ED !important;
  color: #fff !important;
}
.dropdown-heads > a:hover i {
  color: #fff !important;
}

.main-wrapper > .admin-header {
  background: #fff;
  border-bottom: 1px solid #E5E9F2;
  left: 270px;
  position: fixed;
  right: 0;
  top: 0;
  border-radius: 0;
  padding: 15px;
}

.profilemenu {
  background: white;
  border-radius: 10px;
  min-width: 210px;
}

.admin-user-menu .dropdown-menu {
  transition: all 0.5s;
  transform: translate(0px, 100px);
}
.admin-user-menu .dropdown-menu.menu-drop-user {
  margin-top: 6px !important;
}

.user-detials a {
  display: flex;
  color: #222;
  align-items: center;
  text-align: left;
  padding: 15px 10px;
  border-bottom: 1px solid rgba(213, 213, 213, 0.8352941176);
}
.user-detials a span.profile-image img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  display: block;
  border-radius: 50px;
}
.user-detials a span.profile-content {
  flex-direction: column;
  align-items: start;
  padding-left: 15px;
}
.user-detials a span.profile-content h5 {
  font-size: 16px;
  margin: 0;
}
.user-detials a span.profile-content h6 {
  margin: 0;
}

.subscription-detials {
  padding: 15px 10px;
  border-bottom: 1px solid #d5d5d5;
}

.subscription-menu ul {
  padding: 10px;
  margin: 0;
  list-style: none;
  border-bottom: 1px solid #d5d5d5;
}
.subscription-menu ul li {
  margin: 5px 0;
}
.subscription-menu ul li a {
  color: #333;
}
.subscription-menu ul li a:hover {
  color: #4C40ED;
}

.subscription-logout a {
  padding: 10px;
  color: #333;
  display: block;
}

.profilemenu a:hover {
  color: #272751;
}

.property-listset {
  padding: 20px;
}

.user-view {
  border: 1px solid #d5d5d5;
  margin-bottom: 25px;
}

.user-viewhead {
  padding: 15px;
  border-bottom: 1px solid #d5d5d5;
  display: flex;
}

.product-help {
  background: #fff;
  position: fixed;
  left: -500px;
  top: 0;
  height: 100vh;
  width: 400px;
  z-index: 10000;
  transition: all 0.5s;
}

.admin-user-menu.nav > li > a.viewsite {
  font-size: 13px;
  font-weight: 400;
  color: #74788D;
  display: flex;
  align-items: center;
}
.admin-user-menu.nav > li > a.viewsite:hover {
  color: #4C40ED;
}
.admin-user-menu.nav > li > a.viewsite:hover i {
  color: #4C40ED;
}

.page-wrapper {
  background-color: #fff;
  padding-top: 76px;
  min-height: calc(100vh - 40px);
}

.main-wrapper {
  background-color: #fff;
  height: auto;
}

.card {
  border-radius: 8px;
  border: 2px solid rgba(220, 220, 220, 0.3);
  box-shadow: none;
  margin-bottom: 25px;
  width: 100%;
}

.card-body {
  padding: 25px;
}

.admin-sidebar-logo {
  display: flex;
  justify-content: center;
}
.admin-sidebar-logo img {
  max-height: 46px;
  width: auto;
}

.location-set {
  margin-bottom: 25px;
}

.location-setcontent h5 {
  font-size: 16px;
  color: #28283C;
  font-weight: 500;
  margin: 0 0 5px;
}
.location-setcontent h6 {
  font-size: 14px;
  color: #74788D;
  font-weight: 400;
  margin: 0;
}
.service-detail-blk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.service-detail-blk span {
  width: 46px;
  height: 46px;
  background: #FAFAFA;
  border-radius: 50px;
  display: block;
  flex-shrink: 0;
  margin-right: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}

.pricing-box .pricing-selected .card {
  background-color: #4C40ED;
  border-color: #4C40ED;
  color: #fff;
}
.pricing-box .pricing-selected .pricing-header h2, .pricing-box .pricing-selected .pricing-header p {
  color: #fff;
}
.pricing-box .pricing-selected .pricing-card-price .heading2, .pricing-box .pricing-selected .pricing-card-price p {
  color: #fff;
}
.pricing-box .pricing-selected .btn {
  background-color: #fff;
  color: #4C40ED;
}
.pricing-box .pricing-selected .pricing-options li {
  color: #fff;
}
.pricing-box .pricing-header {
  margin-bottom: 2rem;
}
.pricing-box .pricing-header h2 {
  margin-bottom: 0;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.2;
}
.pricing-box .pricing-header p {
  color: #9b9b9b;
  font-size: 0.875rem;
  line-height: 1.43;
}
.pricing-box .pricing-card-price {
  margin-bottom: 1.563rem;
}
.pricing-box .pricing-card-price .heading2 {
  margin-bottom: 5px;
}
.pricing-box .pricing-card-price p {
  color: #9b9b9b;
}
.pricing-box .pricing-card-price p + p {
  margin: 0;
}

.content-sidelink {
  min-width: 289px;
  background: #FBFDFF;
  padding: 25px;
}

.content-sidelinkheading {
  margin-bottom: 20px;
}
.content-sidelinkheading h6 {
  font-size: 18px;
  color: #28283C;
  font-weight: 500;
}

.content-sidelinkmenu ul {
  list-style: none;
  padding: 0;
  margin: 0 0 20px;
}
.content-sidelinkmenu ul li h5 {
  font-size: 15px;
  color: #526484;
  font-weight: 500;
  margin-bottom: 15px;
}
.content-sidelinkmenu ul li h5 a {
  color: #526484;
  font-weight: 500;
  margin-bottom: 15px;
  padding: 0;
}

.symbol-input {
  position: relative;
}

.group-image .form-control {
  padding-left: 30px;
}

.symbol-input > span {
  position: absolute;
  background: #F2F2F2;
  border-radius: 6px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  top: 5px;
  right: 5px;
}

.content-sidelinkmenu ul li a {
  color: #9597A9;
  font-size: 14px;
  padding-left: 20px;
  font-weight: 400;
}

.page-settings {
  display: flex;
}

.content-sidelinkmenu ul li {
  margin-bottom: 10px;
}
.content-sidelinkmenu ul li h4 {
  color: #9597A9;
  font-size: 13px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 500;
}
.content-sidelinkmenu ul li:last-child {
  margin: 0;
}

.content-page-header {
  margin-bottom: 15px;
}
.content-page-header.content-space h5 {
  margin-bottom: 10px;
}

.content-sidelinkmenu ul li.submenu-sidelink a {
  padding-left: 40px;
}

.pricing-box .pricing-options {
  list-style: none;
  padding: 0;
  margin-bottom: 1.875rem;
}
.pricing-box .pricing-options li {
  margin-bottom: 12px;
  font-size: 0.875rem;
  line-height: 1.43;
  position: relative;
}

.add-button {
  box-shadow: 0px 0px 12px 0px rgba(255, 0, 128, 0.2);
  width: 45px;
  height: 45px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.filter-btn {
  box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.02);
  border: 0;
  width: 45px;
  height: 45px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #4C40ED;
  border-radius: 10px;
}
.filter-btn:hover, .filter-btn:focus {
  background-color: #4C40ED;
  color: #fff;
}

.filter-card .form-group {
  margin-bottom: 1.5rem;
}

.has-error .help-block {
  color: red;
}

small.help-block {
  color: #F44336 !important;
}

.toggle.ios, .toggle-on.ios, .toggle-off.ios, .toggle.ios .toggle-handle {
  border-radius: 20px;
}

.service-header {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}
.service-header h2 {
  font-weight: bold;
}

.service-cate a {
  color: #fff;
  padding: 2px 10px;
  text-transform: uppercase;
  background: #d9c505;
  border-radius: 4px;
  font-size: 13px;
  display: inline-block;
}

.service-images, .widget {
  margin-bottom: 30px;
}

.service-amount {
  color: #4C40ED;
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  margin-left: auto;
}

.about-author {
  min-height: 80px;
}

.about-provider-img {
  background-color: #fff;
  height: 80px;
  overflow: hidden;
  position: absolute;
  width: 80px;
}

.provider-details {
  margin-left: 100px;
}

.ser-provider-name {
  display: inline-block;
  margin-bottom: 5px;
  color: #272b41;
  font-size: 16px;
  font-weight: 600;
}

.last-seen {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.last-seen i {
  color: #ffbc34;
  margin-right: 5px;
  font-size: 12px;
}
.last-seen i.online {
  color: #00e65b;
}

.provider-info {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 4px;
}

.available-widget ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.available-widget ul li {
  color: #858585;
  overflow: hidden;
  text-align: right;
  padding-top: 10px;
  padding-bottom: 10px;
}
.available-widget ul li span {
  float: left;
}
.available-widget ul li:first-child {
  padding-top: 0;
}
.available-widget ul li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.rating {
  list-style: none;
  margin: 0 0 7px;
  padding: 0;
  width: 100%;
}
.rating i {
  color: #dedfe0;
}
.rating i.filled {
  color: #FFCC08;
}

.service-widget .rating {
  color: #757575;
  font-size: 14px;
  margin-bottom: 15px;
}
.service-widget .rating i {
  font-size: 14px;
}

.nav-tabs.menu-tabs {
  margin-bottom: 30px;
}
.nav-tabs.menu-tabs .nav-item {
  padding-right: 2.25rem;
}
.nav-tabs.menu-tabs .nav-item.active .nav-link {
  color: #4C40ED;
}
.nav-tabs.menu-tabs .nav-link {
  padding: 0 0 1rem;
  font-weight: 700;
  position: relative;
  border: none;
  outline: none;
  display: inline-flex;
  align-items: center;
  background: transparent;
}
.nav-tabs.menu-tabs .nav-link::before {
  position: absolute;
  content: "";
}
.nav-tabs .nav-link::after {
  position: absolute;
  content: "";
}
.nav-tabs.menu-tabs .nav-link::after {
  left: 0;
  right: 0;
  bottom: 0;
  height: 3px;
  background: #4C40ED;
  opacity: 0;
}
.nav-tabs.menu-tabs .nav-item.active .nav-link::after {
  opacity: 1;
}
.nav-tabs.menu-tabs .nav-link:focus {
  background: transparent;
}
.nav-tabs .nav-link:hover {
  background: transparent;
}
.nav-tabs.menu-tabs .nav-link:focus {
  color: #4C40ED;
}
.nav-tabs.menu-tabs .nav-link .badge {
  margin-left: 0.25rem;
}

.noti-dropdown > a {
  display: inline-flex;
  position: relative;
  z-index: 1;
  padding: 6px !important;
  min-width: 36px;
}
.noti-dropdown > a::before {
  position: absolute;
  z-index: -1;
  height: 20px;
  width: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 50% 50%;
  content: "";
  background-color: #e5e5e5;
  border-radius: 50%;
  opacity: 0;
  transition: all 0.3s;
}
.noti-dropdown > a:hover::before {
  opacity: 1;
  height: 40px;
  width: 40px;
}

.login-page {
  background-color: #f5f5f5;
  position: relative;
  padding: 0;
  margin: -20px -20px -20px 0;
}
.login-page::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  pointer-events: none;
  height: 50vh;
  background-color: #4C40ED;
  border-radius: 0 0 2.5rem 2.5rem;
  z-index: 0;
}

.login-body {
  z-index: 10;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 15px;
  min-height: 100vh;
  display: flex;
}

.login-header {
  margin-bottom: 20px;
}
.login-header p {
  margin-bottom: 0;
}
.login-header h3 {
  font-size: 20px;
  margin-bottom: 0;
  text-transform: uppercase;
}
.login-header h3 span {
  color: #4C40ED;
}

.login-body .form-control {
  height: 50px;
}

.account-btn {
  font-size: 20px;
  font-weight: 500;
  display: block;
  width: 100%;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
}

.login-body .form-control::placeholder {
  color: #bbb;
  opacity: 1;
}

.filter-card .form-group {
  margin-bottom: 1.5rem;
  position: relative;
}
.filter-card .form-group label {
  position: absolute;
  background: white;
  font-size: 12px;
  left: 10px;
  top: -7px;
  padding: 0 7px;
  color: #858585;
  z-index: 10009;
}
.filter-card .form-control, .filter-card .btn, .filter-card .select2-container .select2-selection--single {
  height: 48px;
}
.filter-card .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 46px;
}
.filter-card .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 46px;
}

#toggle_btn {
  padding: 0 20px;
}

.mini-sidebar .admin-siderbar-toggle .admin-slider {
  background: #4C40ED;
}
.mini-sidebar .admin-siderbar-toggle .slider:before {
  transform: translateX(26px);
  left: -5px;
}

.service-carousel .owl-nav {
  width: 100%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin: 0;
}
.service-carousel:hover .owl-nav {
  opacity: 1;
  visibility: visible;
}
.service-carousel .owl-carousel .owl-nav div {
  height: 48px;
  width: 48px;
  line-height: 48px;
  top: 50%;
  background: #666;
  color: #fff;
  font-size: 25px;
  position: absolute;
  border: none;
  margin: 0;
  padding: 0;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-radius: 100%;
  margin-top: -24px;
}
.service-carousel .owl-carousel .owl-nav div:hover {
  color: #fff;
}
.service-carousel .owl-carousel .owl-nav .owl-prev {
  left: -75px;
}
.service-carousel .owl-carousel .owl-nav .owl-prev:hover {
  left: -65px;
}
.service-carousel .owl-carousel .owl-nav .owl-next {
  right: -75px;
}
.service-carousel .owl-carousel .owl-nav .owl-next:hover {
  right: -65px;
}
.service-carousel .owl-dots {
  margin-top: 50px;
}
.service-carousel .owl-dots .owl-dot {
  margin-right: 5px;
}
.service-carousel .owl-dots .owl-dot:last-child {
  margin-right: 0px;
}
.service-carousel .owl-dots .owl-dot span {
  width: 12px;
  height: 12px;
  line-height: 12px;
  margin: 0;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.service-carousel .owl-dots .owl-dot.active span {
  border-color: transparent;
  width: 30px;
  height: 12px;
}

.check {
  display: block;
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  position: absolute;
}

.checktoggle {
  background-color: #e0001a;
  border-radius: 12px;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 24px;
  margin-bottom: 0;
  position: relative;
  width: 48px;
}
.checktoggle:after {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(5px, -50%);
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  transition: left 300ms ease, transform 300ms ease;
}

.check:checked + .checktoggle {
  background-color: #55ce63;
}
.check:checked + .checktoggle:after {
  left: 100%;
  transform: translate(calc(-100% - 5px), -50%);
}

.onoffswitch {
  margin-left: auto;
  position: relative;
  width: 73px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 20px;
  margin-bottom: 0;
}

.form-group p {
  font-size: 14px;
  margin: 5px 0 0;
}

.active-switch .switch {
  position: relative;
  display: inline-block;
  width: 41px;
  height: 21px;
  margin: 0;
}
.active-switch .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.active-switch .sliders {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.active-switch .sliders:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.active-switch input:checked + .sliders {
  background-color: #00CC45;
}
.active-switch input:checked + .sliders:before {
  left: -5px;
}
.active-switch input:focus + .sliders {
  box-shadow: 0 0 1px #00CC45;
}
.active-switch input:checked + .sliders:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.active-switch .sliders.round {
  border-radius: 34px;
}
.active-switch .sliders.round:before {
  border-radius: 50%;
}
.active-switch .onoffswitch-inner {
  display: block;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
  width: 200%;
}

.connectetapps {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pass-group {
  position: relative;
}

.change-passwordback a {
  margin-bottom: 40px;
  display: block;
  color: #5F648A;
  font-size: 15px;
}

.modal-header h5 {
  font-size: 20px;
  font-weight: 500;
}

.change-password {
  display: flex;
  flex-direction: column;
  max-width: 360px;
  margin: auto;
  padding: 30px 15px;
  height: 100vh;
  justify-content: center;
  overflow: auto;
}

.change-passwordhead {
  margin-bottom: 35px;
}
.change-passwordhead h3 {
  font-size: 20px;
  font-weight: 500;
}
.change-passwordhead p {
  font-size: 14px;
  color: #9597A9;
}

.pass-group .toggle-password, .pass-group .toggle-passworda, .pass-group .toggle-passwords {
  transform: translateY(-50%);
  cursor: pointer;
  width: 30px;
  height: 30px;
  color: #797979;
  font-size: 14px;
  position: absolute;
  top: 50%;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.connectetappsimg {
  display: flex;
  align-items: center;
}

.connectet-img {
  width: 46px;
  height: 46px;
  background: #eee;
  padding: 10px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.connectet-content {
  margin-left: 15px;
}
.connectet-content h3 {
  font-size: 16px;
  font-weight: 500;
}
.connectet-content p {
  font-size: 13px;
  margin: 0;
  color: #9597A9;
}
.connectet-content p span {
  color: #28283C;
}

.connectetappscontent {
  display: flex;
  align-items: center;
}

.transfer-lists .theme-image-content {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
}
.transfer-lists .theme-image-content i {
  color: #00CC45;
}
.transfer-lists.active .theme-image-content {
  display: block;
}

.connectetappscontent .active-switch {
  margin-left: 90px;
}

.success-labels {
  background: #F2FFF6;
  padding: 10px 20px;
  margin: 0;
  font-size: 14px;
  color: #1BA345;
  border-radius: 50px;
  display: inline-block;
}

.delete-labels {
  background: #FFF3F3;
  padding: 7px 23px;
  margin: 0;
  font-size: 14px;
  color: #FF4949;
  border-radius: 50px;
  display: inline-block;
}

.active-switch .onoffswitch-inner:before, .active-switch .onoffswitch-inner:after {
  box-sizing: border-box;
  color: #fff;
  display: block;
  float: left;
  font-size: 16px;
  height: 30px;
  line-height: 32px;
  padding: 0;
  width: 50%;
}
.active-switch .onoffswitch-inner:before {
  background-color: #55ce63;
  color: #fff;
  content: "ON";
  padding-left: 14px;
}
.active-switch .onoffswitch-inner:after {
  content: "OFF";
  padding-right: 14px;
  background-color: #ccc;
  color: #fff;
  text-align: right;
}
.active-switch .onoffswitch-switch {
  background: #fff;
  border-radius: 20px;
  bottom: 0;
  display: block;
  height: 20px;
  margin: 5px;
  position: absolute;
  right: 43px;
  top: 0;
  transition: all 0.3s ease-in 0s;
  width: 20px;
}
.active-switch .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}
.active-switch .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}

.noti-contents {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  color: #a1a1a1;
  justify-content: center;
}

#filter_inputs {
  display: none;
}

textarea.form-control.content-textarea {
  height: 100px;
  min-height: auto;
}

.service-widget {
  background-color: #fff;
  border: 2px solid rgba(102, 102, 102, 0.09);
  border-radius: 10px;
  margin-bottom: 30px;
  position: relative;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.service-img {
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-radius: 10px 10px 0 0;
}
.service-img .serv-img {
  border-radius: 10px 10px 0 0;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
  -moz-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -o-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  width: 100%;
}

.service-widget:hover .service-img .serv-img {
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  transform: scale(1.15);
}

.action-search a {
  color: #838383;
  font-weight: 500;
}

.serv-info i {
  margin-right: 5px;
}

.action-search a:hover {
  color: #4C40ED;
}

a.serv-edit {
  margin-right: 30px;
}

.service-widget-image {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.service-widget-image h6 {
  margin-left: 20px;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 0;
}
.service-widget-image h6 img {
  margin-right: 6px;
}

.service-content {
  padding: 15px;
}
.service-content .title {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 10px;
}
.service-content .title a {
  color: #28283C;
}
.service-content p {
  color: #74788D;
}

.service-widget .serv-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.service-widget .serv-info h6 {
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 0;
}
.service-widget .service-content .title a:hover {
  color: #4C40ED;
}
.service-widget .btn-book {
  background: #F7F7FF;
  border-radius: 8px;
  font-weight: 500;
  padding: 6px 15px;
  box-shadow: inset 0 0 0 0 #ffffff;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.service-widget .btn-book:hover {
  background: #fff;
  border-radius: 8px;
  border: 1px solid #4c40ed;
  box-shadow: inset 0 70px 0 0 #fff;
  color: #4c40ed;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.service-widget .fav-item {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 15px 15px;
  z-index: 1;
}

.item-info {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0 15px 15px;
  z-index: 1;
}
.item-info span {
  background: #F7F7FF;
  border-radius: 6px;
  font-size: 14px;
  padding: 6px 15px;
  color: #28283C;
}

.fav-icon {
  width: 32px;
}

.site-logo {
  max-height: 32px;
  width: auto;
}

.admin-noti-wrapper .noti-list {
  padding: 7px 10px;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
  transition: 0.2s ease;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  margin-bottom: 5px;
  position: relative;
}
.admin-noti-wrapper .noti-list .noti-avatar {
  left: auto;
  height: 40px;
  width: 40px;
  position: absolute;
}
.admin-noti-wrapper .noti-list .noti-avatar img {
  height: 40px;
  width: 40px;
}
.admin-noti-wrapper .noti-list .noti-contents {
  margin-left: 50px;
  font-size: 13px;
}
.admin-noti-wrapper .noti-list .noti-contents h3 {
  color: #333;
  font-size: 16px;
  margin-bottom: 0;
}

.modal {
  z-index: 1050;
}

.modal-backdrop {
  z-index: 1040;
}

/*-----------------
	16. Blog
-----------------------*/
.blog {
  background-color: #fff;
  border: 2px solid rgba(102, 102, 102, 0.09);
  border-radius: 10px;
  margin-bottom: 30px;
  position: relative;
}

.grid-blog .blog-image {
  position: relative;
  z-index: 1;
  border-radius: 10px 10px 0 0;
}

.blog-image {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
}
.blog-image > a {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
}
.blog-image img {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  border-radius: 10px 10px 0 0;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
  -moz-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -o-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}

.blog-widget-image {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.blog-widget-image h6 {
  margin-left: 20px;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}
.blog-widget-image h6 img {
  margin-right: 6px;
}

.grid-blog .fav-item {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 15px 15px;
  z-index: 1;
}

.blog-content {
  padding: 15px;
}
.blog-content .title {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 10px;
}
.blog-content .title a {
  color: #28283C;
}
.blog-content .title a:hover {
  color: #4C40ED;
}

.grid-blog .entry-meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding: 0;
}
.grid-blog .entry-meta li {
  margin-bottom: 15px;
}

.entry-meta li {
  display: inline-block;
  margin-right: 15px;
}

.grid-blog .post-author {
  width: 189px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.back-btn {
  font-weight: 600;
  font-size: 16px;
  color: #4C40ED;
  display: inline-block;
  margin-bottom: 18px;
}

.post-author span:nth-child(1):hover {
  color: #4C40ED;
}
.post-author a {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
}
.post-author img {
  border-radius: 5px;
  width: 42px;
  height: 42px;
  margin-right: 10px;
}

.grid-blog .blog-title {
  font-size: 16px;
  margin: 0 0 6px;
}
.grid-blog .blog-title a {
  color: #333333;
  transition: all 0.5s;
}
.grid-blog .blog-content p {
  color: #74788D;
}
.grid-blog .blog-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.grid-blog .blog-info h6 {
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 0;
}

.post-title {
  display: block;
  color: #131523;
  font-weight: 600;
}

.post-date {
  font-size: 12px;
  color: #7E84A3;
}

.blog-views {
  position: absolute;
  top: 20px;
  left: 15px;
  background: #F5F6FA;
  border-radius: 6px;
  padding: 2px 5px;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  font-size: 12px;
}

.blog-catagories {
  position: absolute;
  top: 15px;
  right: 15px;
  background: #FC8543;
  border-radius: 6px;
  min-width: 92px;
  padding: 6px 10px;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
}
.blog-catagories p {
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0px;
}

.blog-views img {
  width: auto;
  margin-right: 5px;
}

.edit-delete-btn a {
  margin-right: 6px;
  font-size: 12px;
  border-radius: 5px;
  padding: 6px 13px;
}
.edit-delete-btn a:nth-child(1) {
  margin-right: 6px;
  background: rgba(23, 208, 83, 0.21);
}
.edit-delete-btn a:nth-child(2) {
  background: rgba(255, 0, 0, 0.1);
}

.btn-blog {
  padding: 10px 22px;
}

.edit-options {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.settings-view {
  display: flex;
  align-items: center;
}
.settings-view > a {
  color: #9597A9;
  margin-right: 15px;
}

.content-table {
  padding: 30px;
  padding-right: 0;
}

.language-set img {
  width: 18px;
  border-radius: 50px;
  margin-right: 5px;
}

.active-text {
  color: #1B5A90;
}

.modal.contentmodal .modal-content .submit-section .btn.btn-download {
  min-width: 200px;
}

.list-links {
  display: flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  padding: 0;
}
.list-links li {
  margin: 0 15px 10px 0;
  list-style: none;
}
.list-links li a {
  background: #FFFFFF;
  border-radius: 5px;
  padding: 12px 30px;
  font-size: 14px;
  color: #131523;
  border: 1px solid #F5F6FA;
  display: inline-block;
}

.inactive-style {
  font-size: 14px;
}

.list-links li a:hover {
  background: #e5e5e8;
}
.list-links li a span {
  font-size: 14px;
}
.list-links li.active a {
  background: #FFFFFF;
  border: 1px solid #4C40ED;
  color: #4C40ED;
}

.grid-blog .blog-title a:hover {
  color: #4C40ED;
}

.select-by .select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #131523;
  border-style: solid;
  border-width: 0 2px 2px 0;
  padding: 4px;
  height: 0;
  left: 50%;
  margin-left: -5px;
  margin-top: -5px;
  position: absolute;
  top: 45%;
  width: 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.modal-select-box .select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #131523;
  border-style: solid;
  border-width: 0 2px 2px 0;
  padding: 4px;
  height: 0;
  left: 50%;
  margin-left: -5px;
  margin-top: -5px;
  position: absolute;
  top: 45%;
  width: 0;
  transform: rotate(224deg);
  -webkit-transform: rotate(224deg);
}

.select-by .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: #131523;
  border-width: 2px 0 0 2px;
  padding: 3px;
  margin-top: -2px;
}

.pagination-tab {
  padding: 30px;
}

.admin-header.fixed-header .admin-user-menu.nav > li.view-btn > a {
  background-color: #fff;
  color: #000000;
}
.admin-header.fixed-header .admin-user-menu.nav > li.view-btn > a:hover {
  background-color: #d19eb7;
  color: #fff;
}

.modal-select-box .select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #131523;
  border-width: 2px 0 0 2px;
  padding: 3px;
  margin-top: -2px;
}

.transfer-lists::after {
  position: absolute;
  content: "";
  background: rgba(76, 64, 237, 0.03);
  width: 149px;
  height: 149px;
  top: 0;
  border-radius: 50%;
  left: -110px;
}
.transfer-lists::before {
  position: absolute;
  content: "";
  background: rgba(76, 64, 237, 0.03);
  width: 149px;
  height: 149px;
  top: -60px;
  border-radius: 50%;
  right: 0px;
}

.transfer-liststop::before {
  position: absolute;
  content: "";
  background: rgba(76, 64, 237, 0.03);
  width: 149px;
  height: 149px;
  top: -20px;
  border-radius: 50%;
  right: -100px;
}

.transfer-lists {
  background: #F8F8FE;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 15px;
  position: relative;
  overflow: hidden;
  border: 2px solid #F8F8FE;
}
.transfer-lists.active {
  border-color: #00CC45;
}

.transfer-liststop {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  justify-content: space-between;
  position: relative;
}

.transfer-listbank h3 {
  font-size: 18px;
  font-weight: 500;
  color: #28283C;
}
.transfer-listbank h4 {
  font-size: 14px;
  color: #74788D;
  margin: 0;
}

.transfer-listsname h5 {
  font-size: 14px;
  color: #74788D;
  font-weight: 400;
}
.transfer-listsname h6 {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.transfer-listsbottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.transfer-listsbottom a {
  color: #4C40ED;
  font-weight: 500;
}

.status-toggle {
  display: flex;
  align-items: center;
}
.status-toggle span {
  margin-left: 8px;
  font-weight: 400;
  font-size: 14px;
}

::selection {
  background: #4C40ED;
  color: #fff;
}

table.dataTable > thead .sorting:after, table.dataTable > thead .sorting_asc:after, table.dataTable > thead .sorting_desc:after, table.dataTable > thead .sorting_asc_disabled:after, table.dataTable > thead .sorting_desc_disabled:after {
  right: 0.5em;
  content: "\f0d7";
  font-family: "Font Awesome 5 Free";
  top: 24px;
  color: #C1CCDB;
  font-size: 12px;
  opacity: 1;
}
table.dataTable > thead .sorting:hover:after, table.dataTable > thead .sorting_asc:hover:after, table.dataTable > thead .sorting_desc:hover:after, table.dataTable > thead .sorting_asc_disabled:hover:after, table.dataTable > thead .sorting_desc_disabled:hover:after, table.dataTable > thead .sorting:focus:after, table.dataTable > thead .sorting_asc:focus:after, table.dataTable > thead .sorting_desc:focus:after, table.dataTable > thead .sorting_asc_disabled:focus:after, table.dataTable > thead .sorting_desc_disabled:focus:after {
  color: #8291A5;
}
table.dataTable > thead .sorting:before, table.dataTable > thead .sorting_asc:before, table.dataTable > thead .sorting_desc:before, table.dataTable > thead .sorting_asc_disabled:before, table.dataTable > thead .sorting_desc_disabled:before {
  right: 0.5em;
  content: "\f0d8";
  font-family: "Font Awesome 5 Free";
  top: 13px;
  color: #C1CCDB;
  font-size: 12px;
  opacity: 1;
}
table.dataTable > thead .sorting:hover:before, table.dataTable > thead .sorting_asc:hover:before, table.dataTable > thead .sorting_desc:hover:before, table.dataTable > thead .sorting_asc_disabled:hover:before, table.dataTable > thead .sorting_desc_disabled:hover:before, table.dataTable > thead .sorting:focus:before, table.dataTable > thead .sorting_asc:focus:before, table.dataTable > thead .sorting_desc:focus:before, table.dataTable > thead .sorting_asc_disabled:focus:before, table.dataTable > thead .sorting_desc_disabled:focus:before {
  color: #8291A5;
}

.btn-linegrey {
  border: 1px solid #EDF1F1;
  padding: 3px 10px;
  font-size: 22px;
  margin-right: 5px;
}
.btn-linegrey:hover {
  background-color: #2c1fe5;
  color: #fff;
}

.active-switch.toogle-primary input:checked + .sliders {
  background-color: #4C40ED;
}

.chartgraph .apexcharts-toolbar, .chartgraph .apexcharts-legend {
  display: none;
}

.filter-checkbox {
  margin-top: 20px;
}

.datatable-nofooter .dataTables_length, .datatable-nofooter .paging_numbers, .datatable-nofooter .dataTables_info {
  display: none;
}

.home-user {
  border-radius: 8px;
  width: 100%;
}

.widget-path .card-body {
  padding: 15px;
}

.home-graph-header {
  padding-bottom: 15px;
}

.admin-btn-viewall {
  border: 1px solid rgba(133, 134, 136, 0.16);
  color: #4169E1 !important;
  padding: 7px;
  min-width: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
}

.btn-check:checked + .btn.admin-btn-viewall, .btn.admin-btn-viewall.active {
  color: #4169E1 !important;
  background-color: #4169e1;
  box-shadow: none;
}

.admin-btn-viewall img {
  width: 14px;
}
.admin-btn-viewall:hover {
  box-shadow: 0 50px 0 0 #4169E1 inset;
  color: #fff !important;
}
.admin-btn-viewall:hover img {
  filter: brightness(0) invert(1);
}

.home-provider .home-usercount span {
  background: rgba(240, 160, 160, 0.12);
}

.home-subscription .home-usercount span {
  background: rgba(255, 184, 73, 0.12);
}

.home-select .delete-table:hover {
  background: #d5d5d5;
  color: #333;
}

.home-head-user {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bookingstatus li span {
  position: absolute;
  width: 14px;
  height: 14px;
  background: #1BA345;
  border-radius: 50px;
}
.bookingstatus li.process-status span {
  background: #0081FF;
}
.bookingstatus li.process-pending span {
  background: #FEC001;
}

.bookingmap li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bookingmap li + li {
  margin-top: 25px;
}
.bookingmap li img {
  width: 16px;
  border-radius: 50px;
}
.bookingmap li span {
  color: #74788D;
  display: flex;
  align-items: center;
}
.bookingmap li h6 {
  font-size: 14px;
  margin: 0;
  font-weight: 400;
}

.bookingstatus li {
  position: relative;
}
.bookingstatus li h6 {
  padding-left: 20px;
  font-size: 14px;
  font-weight: 500;
  color: #74788D;
}
.bookingstatus li + li {
  margin-top: 20px;
}
.bookingstatus li span::after {
  position: absolute;
  content: "";
  background: #fff;
  width: 6px;
  height: 6px;
  border-radius: 50px;
  top: 4px;
  left: 4px;
}

.home-select {
  display: flex;
  align-items: center;
}

.home-head-user h2 {
  font-size: 18px;
  margin: 0;
}

.home-select .btn-action {
  border: 1px solid rgba(133, 134, 136, 0.16);
  padding: 9px 10px;
  color: #74788D;
}

.home-userhead {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-usercount {
  display: flex;
  align-items: center;
}
.home-usercount span {
  width: 36px;
  height: 36px;
  background: rgba(76, 64, 237, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border-radius: 8px;
}
.home-usercount h6 {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.home-useraction a.delete-table {
  background: #fff;
  color: #28283C;
}

.home-usercontent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-usercontentcount {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}
.home-usercontentcount span {
  font-size: 22px;
  font-weight: 500;
  margin: 0;
}
.home-usercontentcount h5 {
  font-size: 13px;
  font-weight: 400;
  color: #8B8D97;
}

.home-usercontents h5 {
  color: #8B8D97;
  font-size: 13px;
  font-weight: 400;
  margin: 0;
}

.role-checkset h6 {
  color: #28283C;
  font-size: 14px;
  font-weight: 400;
}

.more-option {
  color: #4C40ED;
}

.role-checkset li {
  margin-right: 67px;
}
.role-checkset .checkboxs {
  justify-content: center;
}

.swal2-actions .btn {
  margin: 0 5px;
}

.checkboxs {
  cursor: pointer;
  display: flex;
  min-height: 20px;
  align-items: center;
  line-height: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #607D8B;
  font-weight: normal;
}
.checkboxs span i:before, .checkboxs span i:after {
  content: "";
  position: absolute;
  background-color: white;
  border-radius: 20px;
}
.checkboxs span i:before {
  height: 0%;
  top: 50%;
  left: 0;
  width: 2px;
}
.checkboxs span i:after {
  width: 0%;
  bottom: 0;
  left: 0;
  height: 2px;
}
.checkboxs input {
  display: none;
}
.checkboxs input:checked ~ span {
  border: none;
  background-color: #4c40ed;
}
.checkboxs input:checked ~ span i:before {
  height: 50%;
  transition: height 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
.checkboxs input:checked ~ span i:after {
  width: 100%;
  transition: width 300ms 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
}
.checkboxs input:checked ~ b {
  color: #263238;
}
.checkboxs span {
  position: relative;
  display: flex;
  width: 19px;
  height: 19px;
  border: 1px solid #D4D4D4;
  box-sizing: border-box;
  overflow: hidden;
  margin-right: 8px;
  background: #fff;
  border-radius: 4px;
}
.checkboxs span i {
  position: relative;
  width: 20px;
  height: 20px;
  margin-top: -1px;
  margin-left: 3px;
  transform: rotate(-45deg) translateX(0%) translateY(0%) scale(0.7);
  transform-origin: center left;
}

.filter-checkbox li {
  margin-bottom: 15px;
}
.filter-checkbox li:last-child {
  margin: 0;
}

.checkboxs .check-content {
  color: #494545;
  font-weight: 400;
  font-size: 13px;
  margin: 0;
}

.btn-login {
  background-color: #0E82FD;
  width: 100%;
  color: #fff;
  padding: 8px;
  border: 2px solid #0E82FD;
  transition: all 0.5s;
}
.btn-login:hover {
  box-shadow: 0 50px 0 0 #fff inset;
  color: #0E82FD;
}

.login-button {
  margin-bottom: 20px;
}

.login-or {
  margin-bottom: 20px;
  text-align: center;
  position: relative;
}
.login-or::after {
  position: absolute;
  content: "";
  background: #EFEFEF;
  width: 100%;
  height: 1px;
  left: 0;
  top: 10px;
}
.login-or span {
  background: #fff;
  position: relative;
  z-index: 9;
  padding: 0 10px;
  color: #818181;
}

.btn-linepath {
  border: 1px solid #E4E4E8;
  width: 100%;
  padding: 11px;
  color: #000000;
}
.btn-linepath:hover {
  background: #eee;
}

/*-----------------
	17. Blog Details
-----------------------*/
.close-btn {
  border: 0;
  outline: 0;
  background: transparent;
  font-size: 18px;
}

.blog-single-post .blog-image {
  margin-bottom: 20px;
  border-radius: 25px;
}
.blog-single-post .blog-title {
  font-weight: 700;
  color: #000000;
  margin-bottom: 25px;
  font-size: 20px;
}

.about-author-img {
  background-color: #fff;
  height: 62px;
  width: 62px;
}
.about-author-img img {
  border-radius: 5px;
  height: 62px;
  width: 62px;
}

.author-details {
  margin-left: 20px;
  flex: 1;
}

.about-author {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
}

.author-details .blog-author-name {
  display: inline-block;
  color: #131523;
  font-weight: 600;
  margin-bottom: 5px;
}
.author-details .blog-author-name span {
  font-size: 12px;
  font-weight: 400;
  color: #7E84A3;
  padding-left: 3px;
}
.author-details p {
  font-size: 12px;
}

.blog-comments .comments-list {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}
.blog-comments .comments-list li {
  clear: both;
  padding-left: 57px;
}
.blog-comments .comments-list li .comment {
  margin-bottom: 20px;
}
.blog-comments .comments-list li .comment-author {
  left: 0;
  position: absolute;
}
.blog-comments .comments-list li img.avatar {
  height: 42px;
  width: 42px;
  border-radius: 5px;
}
.blog-comments .comment-btn {
  color: #000000;
  display: inline-block;
  font-size: 12px;
}
.blog-comments .comment-btn img {
  width: auto !important;
}

.blog-author-name {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}

.blog-date {
  color: #7E84A3;
  font-size: 12px;
  padding-left: 15px;
  font-weight: 400;
}

.modal-header {
  border-bottom: 0;
  padding: 24px;
  justify-content: center;
}
.add-serving .custom_check .checkmark::after {
  left: 3px;
  top: -3px;
}
.modal-body {
  padding: 24px;
}
.modal .btn-secondary:hover {
  border: 1px solid  #6c757d;
  background: #fff;
  color: #6c757d;

}
.modal-content {
  border-radius: 10px;
}

.modal-footer {
  border: 0;
  padding: 24px;
  justify-content: center;
}

.modal-header .close-modal {
  background: #F1F1F1;
  border: 0;
  border-radius: 50%;
  color: #4F4F4F;
  font-size: 15px;
  width: 29px;
  height: 29px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin: 0;
  opacity: 1;
  padding: 0;
  position: absolute;
  right: 20px;
  top: 20px;
  padding: 0;
  z-index: 99;
}
.modal-header .close-modal:hover {
  background: #4C40ED;
  color: #fff !important;
}

.content-page-headersplit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.btn-primaryline {
  border: 2px solid #4C40ED;
  color: #4C40ED;
  transition: all 0.3s;
}
.btn-primaryline:hover {
  box-shadow: 0 50px 0 0 #4c40ed inset;
  color: #fff;
}

.review-sort {
  display: flex;
  align-items: center;
}
.review-sort .select2-container {
  width: auto !important;
  min-width: 150px;
  color: #3C3C3C;
  font-weight: 500;
  font-size: 14px;
  text-align: left;
}
.review-sort .select2-container--default .select2-selection--single {
  border: 1px solid #EDF1F1;
  border-radius: 5px;
  height: 36px;
  background: #fff;
}
.review-sort .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 36px;
}
.review-sort .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 36px;
}
.review-sort p {
  margin: 0;
  margin-right: 10px;
}

.review-entries select {
  margin: 0 10px 0 20px;
  border: 0.5px solid #CCCCCC;
  border-radius: 5px;
  padding: 5px 30px 5px 10px;
  color: #637381;
  font-weight: 500;
  font-size: 14px;
}

.close-modal i {
  font-size: 15px;
}

.comments-list .comment-block p {
  font-size: 12px;
  margin-bottom: 10px;
}

.new-comment .form-floating > .form-control, .form-floating > .form-select {
  height: 50px;
}

.new-comment .form-floating > label {
  padding: 0.8rem 0.6rem;
}

.social-share {
  float: left;
  list-style: none;
  margin: 0;
  padding: 0;
}
.social-share > li {
  display: inline-block;
  float: left;
  margin-left: 10px;
  text-align: center;
}
.social-share > li:first-child {
  margin-left: 0;
}
.social-share > li > a {
  border: 1px solid #4C40ED;
  border-radius: 6px;
  color: #4C40ED;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  height: 40px;
  width: 40px;
}
.social-share > li > a:hover {
  background-color: #4C40ED;
  color: #fabbdb;
  border-color: #fabbdb;
}

.post-list ul {
  margin: 0 0 20px;
  padding-left: 0;
  list-style: none;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  flex-wrap: wrap;
}
.post-list ul li {
  font-size: 12px;
  color: #7E84A3;
  margin-bottom: 5px;
  margin-right: 30px;
}
.post-list ul li i {
  padding-right: 5px;
}
.post-list ul li:last-child {
  margin-right: 0;
}

.post-author img {
  border-radius: 5px;
  width: 36px;
  height: 36px;
  margin-right: 10px;
}
.post-author span:nth-child(1) {
  font-size: 14px;
  font-weight: 500;
  color: #131523;
  transition: all 0.5s;
}
.post-author span:nth-child(2) {
  font-size: 12px;
  font-weight: 400;
  color: #7E84A3;
  transition: all 0.5s;
}

.profile-upload {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.profile-upload-img {
  padding-right: 15px;
}
.profile-upload-img img {
  width: 50px;
  border-radius: 50px;
}

.profile-upload-btn {
  display: flex;
}

.profile-upload-file {
  position: relative;
  margin-right: 15px;
}

.profile-upload-para p {
  margin: 10px 0 0;
  font-size: 13px;
}

.profile-upload-file input {
  position: absolute;
  opacity: 0;
  width: 75px;
  left: 8px;
}

.btn-upload {
  background: #EFF1F7;
  color: #2469EF;
  padding: 4px 15px;
}

.btn-remove {
  border: 2px solid #FF4949;
  color: #FF4949;
  padding: 3px 15px;
}
.btn-remove:hover {
  background: #FF4949;
  color: #fff;
}

.blog-content p {
  font-size: 14px;
}

a.blog-author-name:hover, a.comment-btn:hover {
  color: #4C40ED;
}

.save-btn {
  font-weight: 600;
  font-size: 16px;
  padding: 6px 75px;
}

.upload-file {
  margin-bottom: 20px;
}
.upload-file h6 {
  font-weight: 400;
  margin-bottom: 15px;
}
.upload-file img {
  border-radius: 5px;
  margin-bottom: 6px;
}
.upload-file p {
  font-size: 12px;
  color: #7E84A3;
}

.up-img {
  position: relative;
  padding-left: 11px;
}
.up-img .close-icon {
  position: absolute;
  top: -9px;
}

.table-action-btn {
  width: 79px;
  height: 27px;
}

.bank-details .modal-footer.blog-categories-btn {
  justify-content: start;
}

.blog-categories-btn {
  padding: 20px;
}

.modal-footer.blog-categories-btn > .bank-details-btn .btn {
  border-radius: 4px;
}

.checktoggle.checkbox-bg {
  background-color: #fff;
  border: 1px solid #D7D7D7;
}
.checktoggle.checkbox-bg::after {
  background: rgba(0, 0, 0, 0.25);
}

.check:checked + .checktoggle.checkbox-bg::after {
  background-color: #fff;
}

.categories-table .pagination, .categories-table .dataTables_info {
  display: none;
}
.categories-table tr:last-child td {
  padding-bottom: 0px;
}

.blog-view .card-header {
  padding: 15px;
}

.blog-image {
  overflow: hidden;
}
.blog-image:hover img {
  transform: scale(1.1);
}

/*-----------------
	18. Reports
-----------------------*/
.report-list .app-listing {
  justify-content: flex-end;
}
.report-list .multiple-selection #checkboxes {
  right: 0;
  left: auto;
}

.sortby #checkbox, .report-list .multiple-selection #checkboxes::before, .sortby #checkbox::before {
  right: 0;
  left: auto;
}

.report-list #checkboxes .form-custom {
  position: relative;
}
.report-list #checkboxes .form-custom .form-control {
  padding-left: 30px;
}
.report-list #checkboxes .form-custom i {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 10px;
  font-size: 14px;
}
.report-list .selectbox-cont {
  height: auto;
}
.report-list .multiple-selection #checkboxes {
  right: 0;
  left: auto !important;
}

.sortby #checkbox {
  right: 0;
  left: auto !important;
}

.report-list .multiple-selection #checkboxes::before, .report-list .sortby #checkbox::before {
  right: 0;
  left: auto;
}

.order-by p i {
  color: #e60073;
  width: 20px;
  height: 20px;
}

.custom_radio {
  display: block;
  position: relative;
  padding-left: 27px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.custom_radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.custom_radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  margin: 1px 0 0 0;
  border: 2px solid #EEF1FE;
  border-top-color: rgb(221, 221, 221);
  border-right-color: rgb(221, 221, 221);
  border-bottom-color: rgb(221, 221, 221);
  border-left-color: rgb(221, 221, 221);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.custom_radio .checkmark::after {
  position: absolute;
  left: 2px;
  top: 2px;
  content: "";
  width: 10px;
  height: 10px;
  background-color: #4C40ED;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0.1);
  -moz-transform: scale(0.1);
  -ms-transform: scale(0.1);
  -o-transform: scale(0.1);
  transform: scale(0.1);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.custom_radio input:checked ~ .checkmark::after {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.lab-title {
  margin-bottom: 10px;
}

.service-report-btn {
  margin-bottom: 20px;
}

.btn-path {
  text-align: right;
}
.btn-path.path-update {
  text-align: left;
}
.btn-path.path-update .btn-primary:hover {
  border: 1px solid #2469ef;
}

.btn-upload:hover {
  background: #2469ef;
  color: #fff;
}

.fw-500 {
  font-weight: 500 !important;
}

.content-page-header {
  margin-bottom: 30px;
}

.active-switch h6 {
  font-size: 12px;
  font-weight: 500;
  margin: 0 10px 0 0;
}

.content-page-header h5 {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}

.service-report-btn .btn {
  background: #FFFFFF;
  border: 1px solid #F7F8F9;
  padding: 13px 10px;
  border-radius: 7px;
  width: 100%;
}
.service-report-btn .btn:hover {
  background: #4C40ED;
  border: 1px solid #4C40ED;
  color: #fff;
}

.account-status-toggle {
  justify-content: flex-end;
}
.account-status-toggle .checktoggle {
  background-color: #e4e4e4;
  border: 1px solid #e4e4e4;
}
.account-status-toggle .check:checked + .checktoggle {
  background-color: #4C40ED;
  border: 1px solid #4C40ED;
}

.table .invoice-delete a {
  color: #f05050;
}

.sms-group ul li {
  color: #28283C;
  font-weight: 500;
  margin-bottom: 15px;
}
.sms-group ul li span {
  float: right;
  color: #74788D;
  font-weight: 400;
}
.sms-group ul li span .avatar-sm {
  width: 27px;
  height: 27px;
  border-radius: 50%;
}

.del-action {
  width: 28px;
  height: 28px;
  background: #F5F5F5;
  color: #74788D;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  border-radius: 50%;
}

.con-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.con-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 20px 20px 0;
}
.con-list li:last-child {
  margin-right: 0;
}
.con-list li span {
  margin-right: 10px;
}

.con-img img {
  width: 38px;
  height: 38px;
  border-radius: 50%;
}

.con-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  width: 38px;
  height: 38px;
  background: #F7F7FF;
  border-radius: 50%;
}

.con-info h6 {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 8px;
}
.con-info p {
  font-size: 13px;
  color: #74788D;
  margin-bottom: 0;
}

.abuse-list li {
  margin-bottom: 20px;
}
.abuse-list li h6 {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 10px;
}
.abuse-list li p {
  font-size: 13px;
  color: #74788D;
  margin-bottom: 0;
}

.page-headers .search-bar {
  position: relative;
  width: 250px;
}
.page-headers .search-bar .form-control {
  border: 1px solid #C2C9D1;
  border-radius: 8px;
  padding: 9px 9px 9px 36px;
  background-color: #fff;
  color: #74788D;
  font-weight: 400;
  font-size: 15px;
}
.page-headers .search-bar .form-control::placeholder {
  color: #74788D;
}
.page-headers .search-bar span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  color: #74788D;
}

/*-----------------
	19. Invoice
-----------------------*/
.upload-size {
  width: 90px;
  height: 110px;
}

.settings-btns .btn-orange {
  font-size: 16px;
  font-weight: 700;
  padding: 10px 26px;
  margin-right: 15px;
  background: #4C40ED;
  border: 1px solid #4C40ED;
  color: #fff;
  border-radius: 4px;
}
.settings-btns .btn-grey {
  font-size: 16px;
  font-weight: 700;
  padding: 10px 26px;
  background: #fff;
  color: #242424;
  border: 1px solid #242424;
  border-radius: 4px;
}

.settings-form {
  margin-top: 20px;
}
.settings-form .social-icon {
  width: 50px;
  height: 40px;
  margin-right: 6px;
  color: #fff;
  background: #4C40ED;
  border: 1px solid #4C40ED;
  border-radius: 5px;
  font-size: 24px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  padding: 4px;
}

.change-photo-btn {
  color: #7E84A3;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  font-size: 13px;
  font-weight: 600;
  padding: 15px;
  position: relative;
  transition: 0.3s;
  text-align: center;
  height: 120px;
  background: #FAFAFA;
  border: 1px dashed #E1E1E1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  margin: auto;
  margin-bottom: 10px;
}
.change-photo-btn input.upload {
  bottom: 0;
  cursor: pointer;
  filter: alpha(opacity=0);
  left: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  height: 100px;
  width: 100%;
}
.change-photo-btn p {
  margin: 0;
  color: #E0E0E0;
}

.settings-form .trash {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 40px;
  width: 46px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  background-color: #4C40ED;
  color: #fff;
  position: relative;
  right: -6px;
  font-size: 20px;
}
.settings-form .trash:hover {
  color: #fff;
  opacity: 0.8;
}

.add-links {
  background-color: #4C40ED;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  padding: 10px 26px;
  border-radius: 4px;
}

.settings-form .bootstrap-tagsinput .tag {
  margin-right: 5px;
  color: #fff;
  background: #4C40ED !important;
  border-radius: 0px;
  font-size: 15px;
  font-weight: 500;
  padding: 12px 12px;
}

.star-red {
  color: red;
}

.bootstrap-tagsinput .tag [data-role=remove] {
  position: relative;
  left: 7px;
  cursor: pointer;
}
.bootstrap-tagsinput .tag [data-role=remove]:after {
  content: "x";
  padding: 0px 2px;
}

.settings-form .bootstrap-tagsinput input {
  line-height: 36px;
  border: 1px solid transparent;
}

.report-card {
  border-radius: 5px;
}

.card-body {
  position: relative;
  padding: 25px;
}

.app-listing {
  padding: 0;
  margin: 0 -15px;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.app-listing li {
  width: 20%;
  padding-left: 10px;
  padding-right: 10px;
}

.multiple-selection {
  position: relative;
  margin-bottom: 20px;
}
.multiple-selection .selectbox {
  position: relative;
  background: #fff;
  width: 100%;
  padding: 13px 10px;
  font-weight: 400;
  background: #FFFFFF;
  border: 1px solid #F7F8F9;
  border-radius: 7px;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.sortby .selectboxes {
  position: relative;
  background: #fff;
  width: 100%;
  padding: 13px 10px;
  font-weight: 400;
  background: #FFFFFF;
  border: 1px solid #F7F8F9;
  border-radius: 7px;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.selectbox p {
  color: #1B2559;
  font-weight: 500;
}
.selectbox p .select-icon {
  color: #e60073;
  width: 20px;
  height: 20px;
}

.report-btn {
  margin-bottom: 20px;
}
.report-btn .btn {
  font-weight: 600;
  color: #e60073;
  background: #FFFFFF;
  border: 2px solid #e60073;
  border-radius: 8px;
  width: 100%;
  padding: 11px 0;
}

.multiple-selection #checkboxes, .sortby #checkbox {
  display: none;
  position: absolute;
  width: 300px;
  left: 0;
  z-index: 1;
  margin-top: 10px;
  padding: 20px;
  background: #FFFFFF;
  border: 1px solid #F2ECFF;
  box-shadow: 0px 4px 44px rgba(231, 234, 252, 0.75);
  border-radius: 6px;
}

.multiple-selection #checkboxes::before, .sortby #checkbox::before {
  border: 7px solid #F2ECFF;
  border-color: transparent transparent #ffffff #ffffff;
  box-shadow: -2px 2px 2px -1px rgba(0, 0, 0, 0.1);
  content: "";
  left: 30px;
  position: absolute;
  top: 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.checkbox-title {
  font-weight: 600;
  font-size: 16px;
  color: #4C40ED;
  margin-bottom: 14px;
}

#checkboxes .form-custom {
  margin-bottom: 14px;
}
#checkboxes .form-custom .form-control {
  padding-left: 14px;
  border: 1px solid rgba(231, 234, 252, 0.75);
  border-radius: 6px;
}

.custom_check {
  color: #1B2559;
  display: inline-block;
  position: relative;
  font-size: 14px;
  margin-bottom: 15px;
  padding-left: 30px;
  cursor: pointer;
  font-weight: 400;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.custom_check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.custom_check .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border: 1px solid #C4C4C4;
  background-color: #fff;
  border-radius: 6px;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.custom_check input:checked ~ .checkmark {
  background-color: #e60073;
  border-color: #e60073;
}
.custom_check .checkmark::after {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  display: none;
  left: 7px;
  top: 1px;
  color: #ffffff;
  font-size: 9px;
}
.custom_check input:checked ~ .checkmark:after {
  display: block;
}
.meta-keys .bootstrap-tagsinput input {
  padding: 11px 5px;
}
.meta-keys .bootstrap-tagsinput {
  padding: 0;
  padding-left: 10px;
}

.content-sidelinkmenu ul li a.active, .content-sidelinkmenu ul li a:hover {
  color: #4C40ED;
}

#checkboxes .btn-grey {
  background: rgba(231, 234, 252, 0.75);
  color: #1B2559;
  margin-top: 10px;
}
#checkboxes .btn {
  font-size: 14px;
  font-weight: 500;
  padding: 8px 0px;
}

#checkbox .btn {
  font-size: 14px;
  font-weight: 500;
  padding: 8px 0px;
}

.selectbox-cont-one {
  overflow-y: inherit;
}

.selectbox-cont {
  height: 220px;
  overflow-y: auto;
  padding-right: 5px;
}

.date-picker {
  width: 50%;
  float: left;
  padding-right: 10px;
}

#checkboxes .form-custom {
  margin-bottom: 14px;
}
#checkboxes .date-picker .form-custom .form-control {
  padding-left: 10px;
}
#checkboxes .form-custom .form-control {
  padding-left: 14px;
  border: 1px solid rgba(231, 234, 252, 0.75);
  border-radius: 6px;
}

.date-list ul {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.date-list ul li {
  width: 100%;
  padding: 0;
  padding-bottom: 5px;
}
.date-list .date-btn {
  background: #fff;
  color: #1B2559;
  border: 1px solid #E7EAFC;
  border-radius: 4px;
  padding: 10px 0 !important;
  width: 100%;
}
.date-list .date-btn:hover {
  background: #4C40ED;
  color: #fff;
  border: 1px solid #E7EAFC;
}

.table-profileimage {
  display: flex;
  align-items: center;
}
.table-profileimage img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  min-width: 40px;
}

.invoices-tabs-card {
  background: transparent;
  box-shadow: unset;
}

.invoices-main-tabs {
  border-bottom: 2px solid #F3F3F3;
  padding-bottom: 10px;
}

.invoices-tabs ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.invoices-tabs ul li {
  position: relative;
  display: inline-block;
  margin-right: 36px;
}

.split-check-box .radiossets .checkmark-radio:after {
  background: #00CC45;
}

.split-check-title h6 {
  font-size: 13px;
  font-weight: 500;
  color: #28283C;
  margin: 0;
}

.table-imgname.flag-image img {
  width: 24px;
  height: 18px;
}

.split-check-box ul {
  display: flex;
  justify-content: flex-end;
}
.split-check-box ul li {
  min-width: 114px;
}
.split-check-box .radiossets input:checked ~ .checkmark-radio {
  border: 1px solid #00CC45;
}

.invoices-tabs ul li a {
  font-weight: 600;
  font-size: 16px;
  color: #1B2559;
  padding-bottom: 24px;
}
.invoices-tabs ul li a.active {
  color: #4C40ED;
  border-bottom: 2px solid #4C40ED;
}

.delete-table {
  background: #F5F5F5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50px;
  color: #74788D;
}
.delete-table:hover {
  background: #4C40ED;
  color: #fff;
}

.table-action a {
  background: #F5F5F5;
  border-radius: 6px;
  font-weight: 500;
  font-size: 13px;
  padding: 5px 10px;
  color: #28283C;
}

.mod-name {
  color: #3F4254 !important;
  font-weight: 600 !important;
}

.role-wrap {
  margin-bottom: 17px;
}
.role-wrap h6 {
  font-size: 18px;
  margin-bottom: 15px;
}
.role-wrap h6 span {
  font-size: 16px;
  color: #4C40ED;
}
.role-wrap .checkboxs {
  margin-bottom: 15px;
}
.role-wrap .checkboxs .check-content {
  font-size: 16px;
  color: #878A99;
}

.role-table {
  max-height: 615px;
  overflow-y: auto;
}
.role-table thead {
  position: sticky;
  top: 0;
  z-index: 9;
}

.action-language .btn-primary:hover {
  box-shadow: 0 0px 0 0 inset;
}

.invoices-settings-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
}

.invoices-settings-icon {
  color: #e60073;
  margin-right: 20px;
}

.invoices-settings-btn .btn {
  font-weight: 600;
  color: #fff;
  padding: 12px 0;
  min-width: 167px;
  background: #4C40ED;
  border: 1px solid #4C40ED;
  border-radius: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
}

.invoices-settings-icon .feather {
  width: 24px;
  height: 24px;
  font-size: 24px;
}

.invoices-settings-btn .btn .feather {
  margin-right: 6px;
}

.invoices-links .feather {
  width: 25px;
  height: 25px;
  font-size: 24px;
}

.inovices-card {
  border-radius: 5px;
}
.inovices-card .card-body {
  padding: 15px;
}

.inovices-widget-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.inovices-widget-icon img {
  width: 50px;
  height: 50px;
}

.inovices-widget-header .inovices-amount {
  font-weight: 600;
  font-size: 24px;
  color: #e60073;
}

.invoices-links.active {
  color: #e60073;
}

.inovices-all {
  font-weight: 600;
  color: #1B2559;
  margin-top: 16px;
  margin-bottom: 0px;
}
.inovices-all span {
  font-size: 10px;
  margin-left: 15px;
}

.invoices-links {
  color: #1B2559;
  margin-right: 20px;
}

.invoices-grid-card {
  background: #FFFFFF;
  box-shadow: 0px 4px 24px rgba(228, 228, 228, 0.25);
  border-radius: 10px;
  color: #1B2559;
}
.invoices-grid-card .card-header {
  padding: 20px;
  border: 0;
}

.invoice-grid-link {
  color: #1B2559;
}

.invoices-grid-card .card-header .action-icon {
  color: #1B2559;
  display: initial;
}

.card-middle {
  padding: 20px;
  background: #F7F7FF;
}

.card-middle-avatar {
  align-items: center;
  display: inline-flex;
  font-size: 16px;
  margin: 0;
}
.card-middle-avatar a {
  color: #1B2559;
}

.invoices-grid-card .card-body {
  padding: 20px;
}
.invoices-grid-card .card-body span {
  font-size: 14px;
}
.invoices-grid-card .card-body h6 {
  font-size: 15px;
  font-weight: 500;
  color: #1B2559;
  margin-top: 4px;
}
.invoices-grid-card .card-footer {
  padding: 20px;
  border: 0;
}
.invoices-grid-card .card-footer .badge {
  border-radius: 6px;
  font-size: 13px;
  font-weight: 400;
  padding: 7px 18px;
}
.invoices-grid-card .bg-success-dark {
  background: #008F64;
}
.invoices-grid-card .bg-danger-dark {
  background: #FF0000;
}
.invoices-grid-card .bg-secondary-dark {
  background: #7638FF;
}
.invoices-grid-card .bg-primary-dark {
  background: #2196f3;
}
.invoices-grid-card .bg-secondary-dark {
  background: #7638FF;
}
.invoices-grid-card .bg-primary-dark {
  background: #2196f3;
}
.invoices-grid-card .bg-danger-dark {
  background: #FF0000;
}
.invoices-grid-card .bg-success-dark {
  background: #008F64;
}

.invoice-load-btn {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #9597A9;
  border-style: solid;
  border-width: 0 2px 2px 0;
  padding: 3px;
  height: 0;
  left: 50%;
  margin-left: -5px;
  margin-top: -5px;
  position: absolute;
  top: 50%;
  width: 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.group-image {
  position: relative;
}
.group-image img {
  position: absolute;
  z-index: 99;
  top: 15px;
  left: 10px;
}

.filter-selects .select2-container .select2-selection--single {
  border: 1px solid #E6E9E8;
  height: 46px;
  background-color: #fff;
  width: 140px;
}

.filter-select-set {
  width: 180px;
}
.filter-select-set .select2-container .select2-selection--single {
  border: 1px solid #E6E9E8;
  height: 34px;
}
.filter-select-set .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 34px;
}

.filter-select-se .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 34px;
  right: 17px;
}

.filter-select-set .group-image img {
  top: 8px;
}
.filter-select-set .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 34px;
}

.badge-default {
  background: #F8F8F8;
  font-size: 12px;
  font-weight: 400;
  color: #A5A5A5;
  display: inline-block;
  width: 80px;
  padding: 7px;
  border-radius: 50px;
  text-align: center;
}

.service-widget .serv-info .serv-users h6 {
  font-size: 16px;
  color: #28283C;
  margin-bottom: 16px;
}
.service-widget .serv-info .serv-users h6 span {
  color: #74788D;
  font-size: 13px;
  margin-left: 15px;
  font-weight: 400;
}

.delete-plugin {
  color: #4C40ED;
  font-weight: 500;
}

.serv-update {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.serv-update ul {
  display: flex;
  align-items: center;
}

.widget-service .card {
  position: relative;
  transition: all 0.5s;
}
.widget-service .card:hover {
  position: relative;
  transition: all 0.5s;
}

.serv-update ul li + li {
  margin-left: 25px;
}
.serv-update h6 {
  margin: 0;
  font-weight: 700;
  font-size: 19px;
}

.preview-plugin {
  color: #74788D;
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
}

.delete-plugin:hover {
  color: #333;
}

.invoice-load-btn .btn {
  font-weight: 700;
  font-size: 16px;
  color: #4C40ED;
  min-width: 170px;
  padding: 12px 15px;
  background: #FFFFFF;
  border: 2px solid #4C40ED;
  border-radius: 6px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.invoice-load-btn .btn span {
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 8px;
  vertical-align: 0;
  border: 3px solid #4C40ED;
  border-right-color: #4C40ED;
  border-radius: 50%;
}

.invoices-create-btn .delete-invoice-btn {
  background: #4C40ED;
  margin-right: 10px;
}
.invoices-create-btn .invoices-preview-link {
  font-weight: 600;
  color: #4C40ED;
  margin-right: 30px;
}
.invoices-create-btn .btn {
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  border-radius: 8px;
  padding: 10px 0px;
  min-width: 160px;
}
.invoices-create-btn .save-invoice-btn {
  background: #fff;
  border: 1px solid #e60073;
  color: #e60073;
}

.invoices-add-card {
  background: #FFFFFF;
  border-radius: 10px;
}

.invoices-main-form {
  padding-bottom: 15px;
  margin-bottom: 30px;
  border-bottom: 1px solid #E5E5E5;
}

.invoices-form label {
  font-weight: 600;
  color: #1B2559;
}
.invoices-form .multiple-selection .selectbox {
  border: 2px solid #4C40ED;
  border-radius: 6px;
}

.multiple-selection #checkboxes-one {
  display: none;
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 1;
  margin-top: 10px;
  padding: 20px;
  background: #FFFFFF;
  border: 1px solid #F2ECFF;
  box-shadow: 0px 4px 44px rgba(231, 234, 252, 0.75);
  border-radius: 6px;
}

.invoices-form .form-control {
  height: 50px;
  border: 1px solid #E5E5E5;
  border-radius: 6px;
}

.invoice-details-title {
  font-weight: 500;
  font-size: 20px;
  color: #1B2559;
  margin-bottom: 0.5rem;
}

.invoice-details-box {
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-radius: 10px;
}

.invoice-inner-head {
  padding: 26px;
  border-bottom: 1px solid #E5E5E5;
}
.invoice-inner-head span {
  color: #1B2559;
}
.invoice-inner-head span a {
  font-weight: 500;
  color: #4C40ED;
}

.invoice-inner-footer {
  padding: 0;
}

.invoice-inner-date {
  padding: 26px;
  border-right: 1px solid #E5E5E5;
}

.table-profileimage span {
  color: #74788D;
}

.table-imgname span {
  color: #74788D;
}
.table-imgname:hover span {
  color: #4C40ED;
}

.table-profileimage:hover span {
  color: #4C40ED;
}

.invoice-inner-footer span {
  font-weight: 500;
  color: #1B2559;
  position: relative;
}
.invoice-inner-footer .form-control {
  position: absolute;
  color: #4C40ED;
  background: transparent;
  border: 0;
  display: inline-block;
  width: auto;
  height: auto;
  padding: 0 10px;
}

#show-invoices {
  display: none;
}

.invoice-info {
  margin-bottom: 20px;
}

.invoice-item .customer-text {
  font-size: 20px;
  color: #000;
  font-weight: 600;
  margin-bottom: 16px;
  display: block;
}
.invoice-item .customer-text a {
  font-size: 14px;
  color: #4C40ED;
  margin-left: 12px;
}
.invoice-item .invoice-details-two {
  text-align: left;
  color: #000;
}

.invoice-add-table {
  padding-top: 20px;
  padding-bottom: 30px;
}
.invoice-add-table h4 {
  font-weight: 600;
  font-size: 20px;
  color: #4C40ED;
  margin-bottom: 30px;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #dee2e6;
}

.invoice-add-table td {
  border-color: #E5E5E5;
  padding: 20px;
}
.invoice-add-table th {
  border-color: #E5E5E5;
  padding: 20px;
  background: #F8F9FA;
}

.invoice-fields .field-title {
  font-weight: 600;
  font-size: 16px;
  color: #1B2559;
  margin-bottom: 15px;
}

.field-box {
  padding: 15px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-radius: 4px;
}
.field-box p {
  font-weight: 500;
  font-size: 14px;
  color: #1B2559;
  margin-bottom: 10px;
}
.field-box .btn {
  min-width: 266px;
  border-radius: 4px;
  padding: 30px 0;
}

.invoice-faq .faq-tab {
  padding-top: 10px;
}

.faq-tab .panel-title {
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-radius: 4px;
  padding: 10px 20px;
  margin-bottom: 0px;
  position: relative;
}
.faq-tab .panel-title a {
  color: #C4C4C4;
}
.faq-tab .panel-title a.collapsed::after {
  content: "\f067";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  right: 20px;
  top: 10px;
  color: #4C40ED;
  font-weight: 600;
  font-size: 14px;
}
.faq-tab .panel-title a:not(.collapsed)::after {
  color: #FF0000;
}
.faq-tab .panel-title a::after {
  content: "\f068";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  right: 20px;
  top: 10px;
  color: #f09e9e;
  font-weight: 600;
  font-size: 14px;
}

.invoice-total-card .invoice-total-title {
  font-weight: 600;
  font-size: 16px;
  color: #1B2559;
  margin-bottom: 15px;
}

.invoice-total-box {
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-radius: 4px;
}

.invoice-total-inner {
  padding: 15px;
}

.invoice-total-box p {
  color: #1B2559;
  margin-bottom: 20px;
  position: relative;
}

.invoice-total-inner .checktoggle {
  background-color: #C4C4C4;
  border: 1px solid #C4C4C4;
  border-radius: 12px;
  cursor: pointer;
  font-size: 0;
  height: 15px;
  margin-left: 10px;
  top: 3px;
  margin-bottom: 0;
  position: absolute;
  width: 30px;
  display: inline-block;
}

.change-activity {
  padding: 50px 20px;
  max-width: 980px;
  margin: auto;
}

.change-activity-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}

.sort-div {
  display: flex;
  align-items: center;
}

.change-activity-head h6 {
  font-size: 20px;
  color: #28283C;
  margin: 0;
  font-weight: 500;
}

.sort-divset {
  display: flex;
  align-items: center;
}
.sort-divset > label {
  margin-right: 10px;
  margin-bottom: 0;
}

a.option-sort {
  border: 1px solid #EDF1F1;
  padding: 8px 10px;
  border-radius: 8px;
  color: #3C3C3C;
  font-weight: 500;
  font-size: 14px;
}

.drop-collapse {
  margin-left: 10px;
}

.btn-filters {
  border: 2px solid #EDF1F1;
  padding: 10px;
  border-radius: 8px;
}

.sort-divset .dropdown-menu a {
  width: 100%;
  color: #333;
  padding: 10px;
  display: block;
}

.invoice-total-inner .checktoggle:after {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(5px, -50%);
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 50%;
  transition: left 300ms ease, transform 300ms ease;
}

.invoice-total-box p span {
  float: right;
}
.invoice-total-box .add-links-bg {
  background: transparent;
  padding: 0;
  color: #18aefa;
}
.invoice-total-box .add-links-one {
  font-weight: 500;
  color: #4C40ED;
}
.invoice-total-box .service-trash-one {
  margin-top: 10px;
  color: #1B2559;
}
.invoice-total-box .service-trash-one i {
  color: #EC1361;
}

.invoice-total-footer {
  border-top: 1px solid #E5E5E5;
  padding: 15px;
}
.invoice-total-footer h4 {
  font-size: 20px;
  margin-bottom: 0;
  color: #4C40ED;
}
.invoice-total-footer h4 span {
  float: right;
}

.invoice-total-box .add-links {
  font-weight: 500;
  color: #4C40ED;
  margin-bottom: 20px;
  display: block;
}

.upload-sign {
  float: right;
  margin-top: 20px;
}

.service-upload {
  border: 2px dashed #E5E5E5;
  text-align: center;
  padding: 30px 0;
  background-color: #fff;
  position: relative;
  width: 330px;
}
.service-upload span {
  font-size: 15px;
  color: #858585;
  display: block;
}
.service-upload input[type=file] {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.upload-sign .btn {
  border-radius: 8px;
  min-width: 198px;
  padding: 10px 0;
}

.invoices-page-header .invoices-breadcrumb-item i {
  color: #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  background: #4C40ED;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.invoices-page-header .invoices-breadcrumb-item a {
  font-weight: 600;
  color: #4C40ED;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.invoice-item .invoice-logo {
  margin-bottom: 30px;
}
.invoice-item .invoice-logo img {
  width: auto;
  max-height: 40px;
}

.invoice-item-one {
  border-bottom: 1px solid rgba(231, 234, 252, 0.75);
}
.invoice-item-one .invoice-info {
  margin-bottom: 30px;
  text-align: right;
}

.invoice-head {
  margin-bottom: 30px;
}
.invoice-head h2 {
  font-weight: 600;
  font-size: 28px;
  color: #1B2559;
  text-transform: uppercase;
  margin-bottom: 0;
}
.invoice-head p {
  font-weight: 400;
  font-size: 20px;
  color: #1B2559;
  margin-bottom: 0;
}

.invoice-item-bg {
  background: #4C40ED;
  border-radius: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.invoice-circle-img {
  position: relative;
}
.invoice-circle-img .invoice-circle1 {
  position: absolute;
  left: 0;
  opacity: 0.1;
}
.invoice-circle-img .invoice-circle2 {
  position: absolute;
  right: 0;
  opacity: 0.1;
}

.invoice-item-bg .invoice-info {
  border-right: 1px solid #fff;
  padding: 30px;
  margin-top: 20px;
}
.invoice-item-bg .customer-text-one {
  color: #fff;
}
.invoice-item-bg .invoice-name {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.invoice-item .invoice-details {
  text-align: right;
  font-weight: 400;
}

.invoice-item-bg .invoice-details-two {
  color: #fff;
}

.invoice-item .invoice-details-two {
  text-align: left;
}

.invoice-info p {
  margin-bottom: 0;
}

.invoice-item-bg .invoice-info-one p {
  color: #fff;
  margin-bottom: 10px;
}

.form-groupheads {
  margin-bottom: 25px;
}
.form-groupheads h2 {
  font-size: 18px;
  font-weight: 500;
  color: #28283C;
}
.form-groupheads h3 {
  font-size: 14px;
  font-weight: 500;
  color: #28283C;
}

.upload-div {
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.upload-sets-btn {
  position: relative;
}
.upload-sets-btn input[type=file] {
  position: absolute;
  opacity: 0;
  width: 75px;
}

.theme-image-set img {
  width: 100%;
  border-radius: 5px;
  border: 3px solid transparent;
}

.theme-image {
  position: relative;
  margin-bottom: 30px;
}

.active .theme-image-content {
  display: block;
}

.theme-image-content {
  display: none;
  position: absolute;
  top: -15px;
  right: -15px;
}

.themecolor ul {
  display: flex;
}
.themecolor ul li {
  margin-right: 15px;
  width: 40px;
}

.themecolorset::after {
  background: #4C40ED;
  width: 34px;
  height: 34px;
  display: block;
  border-radius: 50px;
  position: absolute;
  content: "";
  top: -3px;
  left: -3px;
}

.active.themecolorset::after {
  width: 22px;
  height: 22px;
  top: 3px;
  left: 3px;
}

.themecolorset {
  position: relative;
  border: 3px solid #4C40ED;
  width: 34px;
  height: 34px;
  display: block;
  border-radius: 50px;
  position: relative;
  cursor: pointer;
}

.theme-greens {
  border: 3px solid #16AF54;
}
.theme-greens::after {
  background: #16AF54;
}

.theme-viloets {
  border: 3px solid #8431D9;
}
.theme-viloets::after {
  background: #8431D9;
}

.theme-orange {
  border: 3px solid #D26400;
}
.theme-orange::after {
  background: #D26400;
}

.nav-menus {
  background: #F9F9FB;
  border: 2px solid #F9F9FB;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  border-radius: 8px;
}
.nav-menus:hover {
  background: #eee;
}
.nav-menus h4 {
  font-size: 16px;
  margin: 0;
  font-weight: 500;
}

.form-group-set h4 {
  font-size: 14px;
  font-weight: 500;
}

.nav-bar-image img {
  border-radius: 8px;
}

.form-group-set h5 {
  font-size: 13px;
  font-weight: 400;
  color: #74788D;
  margin: 0;
}

.delete-links {
  background: #F5F5F5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50px;
  color: #74788D;
  margin-left: auto;
}

.link-sets {
  color: #4C40ED;
  font-weight: 500;
}

.delete-links:hover {
  background: #4C40ED;
  color: #fff;
}

.form-group-set {
  margin-bottom: 25px;
}
.form-group-set h4 span {
  color: #A1A1A1;
  font-size: 11px;
  font-weight: 400;
  margin-left: 5px;
}

.active .theme-image-set img {
  border: 3px solid #4C40ED;
}

.theme-image-content i {
  font-size: 18px;
  z-index: 99;
  position: relative;
  color: #4C40ED;
  border-radius: 50px;
  border: 3px solid #fff;
}

.theme-image-set h2 {
  margin-top: 15px;
  margin-bottom: 0;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #28283C;
}

.upload-sets p {
  margin: 10px 0 0;
  color: #8D8D8D;
  font-size: 13px;
}

.form-uploads-path {
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  padding: 30px;
  text-align: center;
}

.view-img ul li {
  position: relative;
  display: inline-block;
  margin: 0 10px 10px 0;
}
.view-img ul li img {
  width: 120px;
  height: 92px;
  object-fit: cover;
  border-radius: 5px;
}
.view-img ul li a {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #fff;
}

.form-uploads-path img {
  margin-bottom: 15px;
}

.file-browse {
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-browse-path {
  position: relative;
  cursor: pointer;
}
.file-browse-path input[type=file] {
  opacity: 0;
  max-width: 60px;
  position: relative;
  z-index: 9;
  cursor: pointer;
}
.file-browse-path a {
  position: absolute;
  left: 8px;
  top: 6px;
  font-size: 14px;
  font-weight: 700;
  color: #4C40ED;
  text-decoration: underline;
  z-index: 1;
  cursor: pointer;
}

.form-uploads-path h5 {
  font-size: 13px;
  font-weight: 400;
  margin: 0;
}

.file-browse h6 {
  font-size: 15px;
  font-weight: 700;
  margin: 0;
}

.upload-imgset {
  width: 100px;
  height: 100px;
  background: #F6F8FF;
  display: flex;
  align-items: center;
  justify-content: center;
}
.upload-imgset img {
  max-width: 100%;
}

.invoice-table > thead > tr > th {
  background: #F5F7FF;
  border-top: 0;
  font-weight: 500;
  font-size: 16px;
  color: #1B2559;
}

.invoice-payment-box h4 {
  font-weight: 600;
  font-size: 16px;
  color: #1B2559;
  margin-bottom: 16px;
}
.invoice-payment-box .payment-details {
  background: transparent;
  border: 2px dashed #4C40ED;
  width: 266px;
  border-radius: 4px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.invoice-payment-box .payment-details p {
  font-weight: 600;
  font-size: 16px;
  color: #1B2559;
  max-width: 140px;
  margin-bottom: 0;
}

.invoice-sign-box {
  margin-top: 30px;
}

.invoice-terms {
  margin-bottom: 30px;
}
.invoice-terms h6 {
  font-weight: 500;
  font-size: 20px;
  color: #1B2559;
  margin-bottom: 6px;
}
.invoice-terms p {
  font-weight: 400;
  font-size: 16px;
  color: #8F9BBA;
}

.invoice-sign {
  padding-top: 30px;
}
.invoice-sign span {
  color: #1B2559;
  margin-top: 18px;
}

.custom-modal .modal-content {
  border: 0;
  border-radius: 10px;
}

.form-header {
  text-align: center;
  margin-bottom: 30px;
}
.form-header h3 {
  color: #1B2559;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 10px;
}
.form-header p {
  color: #929292;
  font-size: 15px;
  margin-bottom: 0;
}

.paid-continue-btn {
  background-color: #e60073;
  border: 1px solid #e60073;
  border-radius: 5px;
  color: #fff;
  display: block;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 20px;
  text-align: center;
}

.online-set {
  position: relative;
  display: flex;
  align-items: center;
}

span.online-path {
  width: 10px;
  height: 10px;
  background: #1BA345;
  border-radius: 50px;
  display: block;
  margin-right: 6px;
}

.online-set h6 {
  margin: 0;
  font-size: 13px;
  color: #1BA345;
}

span.online-path::after {
  position: absolute;
  content: "";
  width: 4px;
  height: 4px;
  background: #fff;
  top: 6px;
  left: 3px;
  border-radius: 50px;
}

.paid-cancel-btn {
  background-color: #fff;
  border: 1px solid #e60073;
  border-radius: 5px;
  color: #e60073;
  display: block;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 20px;
  text-align: center;
}

.bank-details .close {
  background: transparent;
  border: 0;
  color: #1B2559;
  font-size: 28px;
  line-height: normal;
  top: 20px;
  width: auto;
  height: auto;
  right: 20px;
}

.custom-modal .modal-footer {
  border: 0;
  justify-content: center;
  padding: 0 30px 30px;
}

.bank-details-btn .btn {
  min-width: 160px;
  border-radius: 8px;
  padding: 10px 0;
  color: #fff;
}
.bank-details-btn .bank-save-btn {
  color: #e60073 !important;
  border-color: #e60073;
}
.bank-details-btn .bank-save-btn:hover {
  background: #e60073;
  border: 1px solid #e60073;
  color: #fff !important;
}
.bank-details-btn .bank-cancel-btn {
  background: #4C40ED;
}

.bank-details .modal-footer {
  justify-content: flex-end;
  padding: 20px;
}

.invoice-setting-btn .cancel-btn {
  background: #4C40ED;
}

.btn-primary-save-bg {
  background-color: #fff;
  color: #4C40ED !important;
  border-color: #4C40ED;
}
.btn-primary-save-bg:hover {
  background: #4C40ED;
  border: 1px solid #4C40ED;
  color: #fff !important;
}

.invoice-setting-btn .btn {
  min-width: 160px;
  padding: 10px 0;
  color: #fff;
  border-radius: 8px;
}

.settings-menu ul {
  display: block;
  padding: 0;
}
.settings-menu ul li {
  list-style: none;
}
.settings-menu ul li a {
  color: #455560;
  padding: 0;
  border: 0 !important;
  display: inline-block;
}
.settings-menu ul li i {
  margin-right: 10px;
  font-size: 20px;
  min-width: 25px;
}
.settings-menu ul li + li {
  margin-top: 15px;
}
.settings-menu ul li a.active {
  color: #4C40ED !important;
  border: 0;
}

.invoices-upload-btn {
  border: 1px solid #dee2e6;
  border-radius: 6px;
  color: #000000;
  cursor: pointer;
  font-weight: 400;
  padding: 15px 15px;
  position: relative;
  width: 100%;
  height: 50px;
}
.invoices-upload-btn .hide-input {
  position: relative;
  z-index: 1;
  cursor: pointer;
  min-height: auto;
  padding-left: 4px;
  padding-top: 0;
  line-height: 10px;
  width: 100%;
  opacity: 0;
}
.invoices-upload-btn .upload {
  position: absolute;
  background: #4C40ED;
  border-radius: 4px;
  right: 6px;
  top: 8px;
  width: 136px;
  height: 32px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.table .bg-success-dark {
  background: #008F64;
}
.table .bg-danger-dark {
  background: #FF0000;
}

.invoice-add-table .add-btn {
  color: #4C40ED;
}
.invoice-add-table .remove-btn {
  color: #FF0000;
}

.payment_trash .fe-trash-2 {
  color: #fff;
}

.connectetappscontent ul {
  display: flex;
  align-items: center;
  list-style: none;
}

.btn-change {
  background: #F1F8FF;
  color: #007BFF;
}

.connectetappscontent ul li {
  margin: 0 5px;
}
.connectetappscontent ul li:first-child {
  margin-right: 25px;
}

.btn-removes {
  background: #FFF3F3;
  color: #FF4949;
}
.btn-removes:hover {
  background: #ff4949;
  color: #fff;
}

.btn-change:hover {
  background: #007bff;
  color: #fff;
}

.connectetappscontent .btn-linedark {
  min-width: 150px;
  border: 1px solid #AAACB8;
  font-size: 14px;
  color: #28283C;
}
.connectetappscontent .btn-linedark:hover {
  background: #28283C;
  color: #fff;
}

.invoice-total-box .service-amount {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.invoice-total-box .service-trash {
  margin-bottom: 10px;
  color: #1B2559;
}
.invoice-total-box .service-trash i {
  color: #EC1361;
}
.invoice-total-box .service-amount1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.invoice-item-two {
  padding-top: 30px;
  padding-bottom: 30px;
}
.invoice-item-two .invoice-details {
  color: #1B2559;
  font-weight: 400;
}

.invoice-item-box {
  background: #F7F7FF;
  border-radius: 5px;
  padding: 15px;
  width: 200px;
  float: right;
  margin-top: 20px;
}
.invoice-item-box p {
  color: #1B2559;
  font-weight: 400;
  margin-bottom: 10px;
}

.invoice-info {
  margin-bottom: 20px;
}
.invoice-info.invoice-info2 {
  text-align: right;
}

.customer-text-one {
  font-size: 20px;
  color: #1B2559;
  font-weight: 600;
  display: block;
  margin-bottom: 10px;
}

.invoice-issues-box {
  background: #4C40ED;
  border-radius: 10px;
  text-align: center;
  padding: 10px;
  margin-bottom: 30px;
}

.invoice-issues-date {
  padding-top: 20px;
  padding-bottom: 20px;
}
.invoice-issues-date p {
  font-weight: 600;
  font-size: 16px;
  color: #FFFFFF;
  margin-bottom: 0;
}

.invoice-table-wrap {
  margin-bottom: 30px;
}

.inovices-month-info {
  padding-top: 50px;
}
.inovices-month-info .form-control {
  height: 42px;
}

.settings-form .links-cont .social-icon::after {
  content: "";
  border-top: 0.2em solid;
  border-right: 0.2em solid transparent;
  border-bottom: 0;
  border-left: 0.2em solid transparent;
  margin-left: 4px;
}

.add-links:hover {
  color: #fff;
  opacity: 0.8;
}

.poor-active span.active#poor, .poor-active span.active#poors {
  background: #FF0000;
}

.avg-active span.active#poor, .avg-active span.active#weak, .avg-active span.active#poors, .avg-active span.active#weaks {
  background: #FFB54A;
}

.strong-active span.active#poor, .strong-active span.active#weak, .strong-active span.active#strong, .strong-active span.active#poors, .strong-active span.active#weaks, .strong-active span.active#strongs {
  background: #1D9CFD;
}

.heavy-active span.active#poor, .heavy-active span.active#weak, .heavy-active span.active#strong, .heavy-active span.active#heavy, .heavy-active span.active#poors, .heavy-active span.active#weaks, .heavy-active span.active#strongs, .heavy-active span.active#heavys {
  background: #159F46;
}

.settings-btns .btn-grey:hover {
  background: #242424;
  border: 1px solid #242424;
  color: #fff;
}

.invoices-create-btn .save-invoice-btn:hover {
  background: #e60073;
  border: 1px solid #e60073;
  color: #fff;
}

#checkboxes .date-picker .bootstrap-datetimepicker-widget table td {
  padding-left: 10px;
  padding-right: 10px;
}

.invoice-inner-date.invoice-inner-datepic {
  padding-left: 0;
}

.form-group .group-image .select2-container--default .select2-selection--single .select2-selection__rendered {
  padding-left: 35px;
}
.form-group .select2-container--default .select2-selection--single .select2-selection__rendered {
  padding-left: 15px;
}

.list-btn .btn-filters {
  border: 1px solid #E6E9E8;
  border-radius: 8px;
  color: #74788D;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.list-btn .btn-downloads {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px;
  border-radius: 8px;
  padding: 10px 15px;
  color: #fff;
}
.list-btn .btn-downloads img {
  margin-right: 6px;
}
.down-pdf {
  background: #333333;
}
.down-pdf:hover {
  background: #6c6b6b;
}
.down-excel {
  background: #4C40ED;
}
.down-excel:hover {
  background: #4035cb;
}
.menu-set {
  border: 1px solid rgba(41, 136, 230, 0.22);
  margin-bottom: 25px;
  border-radius: 8px;
}
.card.card-chart-blk {
  border: 1px solid #F5F5F5;
}
.card-head-blk {
  padding: 15px;
  border-bottom: 1px solid #F5F5F5;
}
.card-table-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.card-table-head h4 {
  font-size: 18px;
  margin-bottom: 0;
}
.card-table-head ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
  margin-left: 30px;
}
.card-table-head ul li {
  font-size: 14px;
  margin-bottom: 0;
  color: #858585;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
  margin-right: 15px;
}
.card-table-head ul li:last-child {
  margin-right: 0;
}
.card-table-head ul li span {
  width: 12px;
  height: 12px;
  display: block;
  margin-right: 5px;
}
.earning-black {
  background: #333333;
}
.earning-pink {
  background: #4C40ED;
}
.earning-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: flex-end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
}
.earning-group .sortby-section {
  margin-right: 6px;
}
.earning-group .sortby-section:last-child {
  margin-right: 0px;
}
.earning-group .select2-container .select2-selection--single {
  background: transparent;
  min-width: 140px;
}
.earning-group .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 42px;
}
.earning-group .sortby-section .sorting-info .sortby-filter-group .sortbyset.week-bg .sorting-select .select2-container .select2-selection--single .select2-selection__rendered {
  background-image: url(/src/style/admin/img/icons/calendar.svg);
  background-repeat: no-repeat;
  background-position: 10% 50%;
}
.earning-group .select2-container .select2-selection--single .select2-selection__rendered {
  padding-right: 30px;
  padding-left: 30px;
}
.card.card-status {
  border: 1px solid #F5F5F5;
  margin-bottom: 0;
}
.earning-group .status-provider .select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 10px;
}
.earning-group .sortby-section.status-provider .sorting-info .sortby-filter-group .sortbyset.week-bg .sorting-select .select2-container .select2-selection--single .select2-selection__rendered {
  background: transparent;
}
.chart-date-group .form-control {
  background: transparent;
  border: 1px solid #d8d8d8;
  margin-right: 6px;
  max-width: 140px;
  /* background-image: url(../img/icons/calendar-alt.svg); */
  background-repeat: no-repeat;
  background-position: 10% 50%;
  padding-left: 30px;
  color: #74788D;
}
.chart-date-group .cal-icon:after {
  display: none;
}
.chart-date-group .form-control::placeholder {
  color: #74788D;
}

.menu-setheader a, .menu-setheader h5 {
  background: #F8FAFC;
  padding: 17px;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid rgba(41, 136, 230, 0.22);
  margin: 0;
  display: block;
  color: #28283C;
  border-radius: 8px 8px 0 0;
}
.menu-setheader .collapsed i {
  transform: rotate(0);
}
.menu-setheader i {
  position: relative;
  top: 1px;
  font-size: 18px;
  transform: rotate(180deg);
  transition: all 0.5s;
}

.menu-setcontent .select2-container .select2-selection--single {
  background-color: #fff;
  height: 34px;
}
.menu-setcontent .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 34px;
  right: 7px;
}

.menu-setcontent-inner {
  border: 1px solid #E4E8EC;
  background: #E4E8EC;
  padding: 11px;
  border-radius: 5px;
  margin-bottom: 15px;
}

.menu-setcontent .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 34px;
}

.menu-innerhead a {
  color: #28283C;
  width: 100%;
  display: block;
}

.form-group .contentpage {
  background: #EDF3F9;
  padding: 11px;
  font-size: 13px;
  font-weight: 400;
  border-radius: 8px;
  color: #74788D;
}

.menu-setcontent-inner .menu-setcontent {
  padding: 17px 0 0;
}

.menu-setcontent {
  padding: 17px;
}
.menu-setcontent .form-control {
  height: 34px;
  background: #FFFFFF;
  padding: 10px;
}

.list-btn ul li:last-child {
  margin-right: 0;
}
.list-btn .btn-filters:hover, .list-btn .btn-filters.active {
  box-shadow: 0 50px 0 0 #4C40ED inset;
  color: #2c1fe5;
  border-color: #4C40ED;
}
.list-btn .btn-filters:hover i, .list-btn .btn-filters.active {
  color: #fff;
}
.list-btn ul {
  display: flex;
  align-items: center;
}
.list-btn ul li {
  margin: 0 5px;
}

.filter-sorting .review-sort .select2-container--default .select2-selection--single {
  border: 0;
  border-radius: 5px;
  height: auto;
  background: #fff;
}
.filter-sorting .review-sort .select2-container {
  width: auto !important;
  min-width: 100%;
  color: #74788D;
  font-weight: 500;
  z-index: 9;
}
.filter-sorting ul {
  border: 1px solid #E6E9E8;
  display: flex;
  align-items: center;
  border-radius: 8px;
}
.filter-sorting ul li {
  margin: 0;
  padding: 0 5px;
  display: flex;
  display: flex;
  align-items: center;
}
.filter-sorting ul li:last-child {
  border-left: 1px solid #E6E9E8;
  width: 60%;
}
.filter-sorting ul li:first-child {
  width: 40%;
  display: block;
  padding: 0;
}
.filter-sorting ul li span img {
  min-width: 15px;
}

.filter-sets {
  color: #74788D;
  font-weight: 500;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.filter-sets:hover {
  background: #eee;
}

.form-group .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border-color: #F3F3F3;
  background: #F9F9FB;
}
.form-group .ck.ck-toolbar {
  border: 1px solid #f3f3f3;
}
.form-group .ck.ck-toolbar .ck.ck-toolbar__separator {
  background: transparent;
}

.table-select {
  width: 115px;
}
.table-select span.select2-selection.select2-selection--single {
  height: 30px;
}
.table-select .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 30px;
  padding-left: 10px;
  font-size: 14px;
}
.table-select .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 30px;
}
.table-select.table-selectpayouts {
  width: 225px;
}
.table-select .select2-container {
  font-size: 11px;
}

.filter-sorting .review-sort .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 36px;
  padding-left: 0;
  margin-left: 15px;
}

.table-namesplit {
  display: flex;
  align-items: center;
}

.table-name span {
  color: #74788D;
  font-weight: 500;
}

a.table-name:hover span {
  color: #4C40ED;
}

.table-name p {
  color: #878787;
  margin: 0;
  font-size: 13px;
}

.btn-history {
  color: #74788D;
  background: #F7F7FF;
  font-size: 13px;
  padding: 4px 18px;
}
.btn-history:hover {
  box-shadow: 0 50px 0 0 #74788D inset;
  border: 1px solid;
  color: #fff;
}

.notification-message img.avatar-img.rounded-circle {
  border-radius: 5px !important;
}

.info-set {
  font-size: 13px;
  font-weight: 400;
  color: #74788D;
  margin: 8px 0 0;
}

.form-icon {
  position: relative;
}
.form-icon span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

/*-----------------
	20. Pricing
-----------------------*/
.price-card {
  background: #FFFFFF;
  border: 2px solid #F1F1F1;
  border-radius: 8px;
  padding: 15px;
}

.price-head {
  border-bottom: 1px solid #EFEFEF;
  padding-bottom: 15px;
}
.price-head h6 {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;
}
.price-head h1 {
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 0;
}
.price-head h1 span {
  font-size: 12px;
  color: #9A9A9A;
}

.price-level {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.price-body {
  padding-top: 15px;
}
.price-body ul {
  margin-bottom: 35px;
}
.price-body ul li {
  position: relative;
  font-size: 14px;
  color: #848484;
  margin-bottom: 15px;
  padding-left: 25px;
}
.price-body ul li:last-child {
  margin-bottom: 0;
}
.price-body ul li:before {
  position: absolute;
  content: "";
  top: 2px;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}
.price-body ul li.active:before {
  background-image: url("../img/icons/check-icon.svg");
}
.price-body ul li.inactive:before {
  background-image: url("../img/icons/close-icon.svg");
}
.price-body .btn-choose {
  background: #EDF3F9;
  border-radius: 8px;
  font-size: 14px;
  padding: 6px 30px;
  margin: 0 auto;
}

.price-card:hover {
  background: #EDF3F9;
  border: 2px solid #F1F1F1;
}
.price-card:hover .btn-choose {
  background-color: #4c40ed;
  color: #fff;
}

.price-toggle {
  font-weight: 500;
  font-size: 18px;
  color: #28283C;
  text-align: center;
  margin-bottom: 35px;
}
.price-toggle .checktoggle {
  margin: 0 23px;
  background: #4C40ED;
}
.price-toggle .check:checked + .checktoggle {
  background-color: #4C40ED;
  border: 1px solid #4C40ED;
}

/*-----------------
	21. Preloading
-----------------------*/
#overlayer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 9999;
  background: #fff;
}

.loader {
  display: inline-block;
  width: 40px;
  height: 40px;
  position: fixed;
  z-index: 10000;
  border: 4px solid #4C40ED;
  top: 50%;
  animation: loader 2s infinite ease;
  left: 50%;
  border-radius: 5px;
}

.loader-inner {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  background-color: #4C40ED;
  animation: loader-inner 2s infinite ease-in;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loader-inner {
  0% {
    height: 0%;
  }
  25% {
    height: 0%;
  }
  50% {
    height: 100%;
  }
  75% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
}
.sidbarnews {
  width: 100%;
  height: 100%;
  overflow: auto;
}

/*-----------------
    22. Chat
-----------------------*/
.chat-scroll {
  max-height: calc(100vh - 264px);
  overflow-y: auto;
}

.chat-cont-left .chat-scroll {
  max-height: calc(100vh - 286px);
  overflow-y: auto;
}

.contacts_body {
  padding: 0.75rem 0;
  overflow-y: auto;
  white-space: nowrap;
}

.msg_card_body {
  overflow-y: auto;
  padding: 20px;
}

.chat-cont-left .chat-header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  padding: 15px;
}
.chat-cont-left .chat-header h6 {
  font-size: 20px;
  margin-bottom: 3px;
}
.chat-cont-left .chat-header p {
  font-size: 14px;
  margin-bottom: 0;
}
.chat-cont-left .chat-header.inner-chat-header {
  padding: 15px 0;
}
.chat-cont-left .chat-header.inner-chat-header h6 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}

.chat-list {
  margin: 0 auto;
  padding: 0 14px;
  text-align: center;
}
.chat-list li {
  display: inline-block;
  margin-bottom: 5px;
}
.chat-list li a {
  padding: 5px 11px;
  font-weight: 500;
  font-size: 12px;
  color: #34444C;
  border-radius: 50px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.chat-list li a:hover {
  background-color: #F3F3F5;
}
.chat-list li a i {
  margin-right: 5px;
}
.chat-list li a.active {
  background-color: #F3F3F5;
}

.chat-compose {
  width: 37px;
  height: 37px;
  color: #34444C;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  background: #F9F9F9;
  border-radius: 5px;
  font-size: 18px;
}

.chat-cont-left .card {
  border: 2px solid rgba(220, 220, 220, 0.3);
  border-radius: 5px;
}

.chat-file-attachments {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.chat-file-attach {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-right: 20px;
}

.chat-file-download {
  width: 28px;
  height: 28px;
  background: #fff;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  color: #2A313D;
}

.msg_card_body .media .media-body .msg-box .chat-file-icon {
  margin-right: 9px;
}
.msg_card_body .media .media-body .msg-box .chat-file-icon i {
  font-size: 25px;
}
.msg_card_body .media .media-body .msg-box .chat-file-info h6 {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
}
.msg_card_body .media .media-body .msg-box .chat-file-info p {
  font-size: 12px;
  color: #828282;
  margin-bottom: 0;
}

.card-footer {
  background-color: #fff;
  border-radius: 0 0 15px 15px;
  border-top: 0;
}

.search {
  border-radius: 15px 0 0 15px;
  background-color: rgba(0, 0, 0, 0.1);
  border: 0;
  color: #fff;
}
.search:focus {
  box-shadow: none;
  outline: 0px;
}

.chat-cont-right .card-footer {
  padding: 20px;
}

.btn-file {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 20px;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  overflow: hidden;
  padding: 0;
  vertical-align: middle;
  background: #EDEEF7;
  border: 1px solid #EDEEF7;
  border-radius: 50% !important;
  width: 30px;
  height: 30px;
  position: absolute !important;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.btn-file input {
  cursor: pointer;
  filter: alpha(opacity=0);
  font-size: 0;
  height: 100%;
  margin: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.type_msg {
  background: #FFFFFF;
  border: 1px solid #4C40ED;
  box-shadow: 0px 4px 8px rgba(52, 68, 76, 0.04);
  border-radius: 50px;
  overflow-y: auto;
  min-height: 50px;
  padding: 10px 107px 10px 45px;
  border-radius: 50px !important;
  font-size: 14px;
  font-weight: 400;
}
.type_msg:focus {
  box-shadow: none;
  outline: 0px;
  z-index: unset !important;
}
.type_msg::placeholder {
  color: #B1B1B1;
}

.send-action {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-flex;
  display: -webkit-inline-flex;
  align-items: center;
  -webkit-align-items: center;
}
.send-action a {
  width: 30px;
  height: 30px;
  display: inline-flex;
  display: -webkit-inline-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  background: #EDEEF7;
  border: 1px solid #F3F3F3;
  color: #575757;
  border-radius: 50%;
  margin-right: 7px;
  font-size: 12px;
}
.send-action a:hover {
  background: #4C40ED;
  color: #fff;
}

.btn_send {
  width: 30px;
  height: 30px;
  border-radius: 50% !important;
  display: inline-flex;
  display: -webkit-inline-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  padding: 0;
  font-size: 12px;
}

.search_btn {
  background-color: transparent;
  border: 0;
  line-height: 0;
}

.user_img {
  height: 39px;
  width: 39px;
  min-width: 39px;
}

.img_cont {
  position: relative;
  height: 45px;
  width: 45px;
}

.offline {
  background-color: #c23616;
}

.user_info {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 8px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user_info span {
  font-size: 18px;
  font-weight: 600;
  color: #28283C;
}
.user_info .user-name {
  color: #2c3038;
}
.user_info p {
  font-size: 14px;
  line-height: normal;
}
.user_info p.active {
  color: #48E100;
}
.user_info p.inactive {
  color: #c23616;
}

.chat-window .avatar-online::before {
  background-color: #47BC00;
}

.msg_head {
  position: relative;
  background-color: transparent;
  border-bottom: 0 !important;
}

.msg_card_body ul.list-unstyled {
  margin: 0 auto;
  padding: 1rem 0 0;
  width: 100%;
  border-top: 1px solid #E8EBED;
}
.msg_card_body ul.list-unstyled li:last-child {
  margin-bottom: 0;
}
.msg_card_body .media .avatar {
  height: 25px;
  width: 25px;
}
.msg_card_body .media .media-body {
  margin-left: 6px;
}
.msg_card_body .media .media-body .msg-box > div {
  padding: 8px 10px;
  border-radius: 10px;
  display: inline-block;
  position: relative;
}
.msg_card_body .media .media-body .msg-box > div p {
  font-size: 14px;
  margin-bottom: 0;
}
.msg_card_body .media .media-body .msg-box + .msg-box {
  margin-top: 5px;
}
.msg_card_body .media.received {
  margin-bottom: 20px;
}
.msg_card_body .media.received .chat-msg-info li a {
  color: #ABABAB;
}
.msg_card_body .media:last-child {
  margin-bottom: 0;
}
.msg_card_body .media.received .media-body .msg-box > div {
  background-color: #EDEEF7;
}
.msg_card_body .media.sent {
  margin-bottom: 20px;
}
.msg_card_body .media.sent .media-body {
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
  margin-left: 0;
}
.msg_card_body .media.sent .media-body .msg-box > div {
  background: #F7F7F8;
}
.msg_card_body .chat-date {
  font-size: 13px;
  margin: 22px 0;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-transform: capitalize;
}
.msg_card_body .chat-date span {
  color: #A8A8A8;
  background: #FFFFFF;
  border: 1px solid #F1F1F1;
  border-radius: 5px;
  padding: 2px 10px;
  display: inline-block;
}

.signinform h4 {
  font-size: 14px;
  font-weight: 400;
  color: #212B36;
}
.signinform h4 a {
  color: #212B36;
  font-weight: 600;
}

.chat-cont-right .chat-footer {
  background-color: #fff;
  border-top: 1px solid #f0f0f0;
  padding: 10px 15px;
  position: relative;
}
.chat-cont-right .chat-footer .input-group {
  width: 100%;
}
.chat-cont-right .chat-footer .input-group .form-control {
  background-color: #f5f5f6;
  border: none;
  border-radius: 50px;
}
.chat-cont-right .chat-footer .input-group .form-control:focus {
  background-color: #f5f5f6;
  border: none;
  box-shadow: none;
}
.chat-cont-right .chat-footer .input-group .input-group-prepend .btn {
  background-color: transparent;
  border: none;
  color: #9f9f9f;
}
.chat-cont-right .chat-footer .input-group .input-group-append .btn {
  background-color: transparent;
  border: none;
  color: #9f9f9f;
}
.chat-cont-right .chat-footer .input-group .input-group-append .btn.msg-send-btn {
  background-color: #0de0fe;
  border-color: #0de0fe;
  border-radius: 50%;
  color: #fff;
  margin-left: 10px;
  min-width: 46px;
  font-size: 20px;
}

.msg-typing {
  width: auto;
  height: 24px;
  padding-top: 8px;
}
.msg-typing span {
  height: 8px;
  width: 8px;
  float: left;
  margin: 0 1px;
  background-color: #fff;
  display: block;
  border-radius: 50%;
  opacity: 0.4;
}
.msg-typing span:nth-of-type(1) {
  animation: 1s blink infinite 0.33333s;
}
.msg-typing span:nth-of-type(2) {
  animation: 1s blink infinite 0.66666s;
}
.msg-typing span:nth-of-type(3) {
  animation: 1s blink infinite 0.99999s;
}

.msg_card_body .media.received .media-body .msg-box {
  position: relative;
}
.msg_card_body .media.received .media-body .msg-box:first-child > div {
  border-radius: 0 15px 15px 15px;
  margin-top: 15px;
}
.msg_card_body .media.sent .media-body .msg-box {
  padding-left: 50px;
  position: relative;
}
.msg_card_body .media.sent .media-body .msg-box:first-child > div {
  border-radius: 15px 0 15px 15px;
  margin-top: 15px;
}
.msg_card_body .media .chat-msg-info li .dropdown-item {
  padding: 4px 6px;
  font-size: 12px;
}

.chat-msg-info {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  clear: both;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  font-size: 10px;
  padding: 0;
  margin: 0 0 5px;
  position: absolute;
  top: -20px;
  left: 0;
  width: 100%;
}
.chat-msg-info li {
  font-size: 13px;
  padding-right: 5px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.chat-msg-info li:last-child {
  padding-right: 0;
}
.chat-msg-info li .chat-time {
  margin-right: 10px;
}
.chat-msg-info li .drop-item {
  color: #ABABAB;
}
.chat-msg-info li:not(:last-child):after {
  position: absolute;
  right: 8px;
  top: 50%;
  content: "";
  height: 4px;
  width: 4px;
  background: #d2dde9;
  border-radius: 50%;
  transform: translate(50%, -50%);
}

.media.sent .chat-msg-info li .chat-time {
  margin: 0 0 0 10px;
}

.msg_card_body .media.received .media-body .msg-box > div .chat-time {
  color: #ABABAB;
}
.msg_card_body .media.sent .media-body .msg-box > div .chat-time {
  color: rgba(50, 65, 72, 0.4);
}

.chat-msg-info li a {
  color: #777;
}
.chat-msg-info li a:hover {
  color: #2c80ff;
}

.chat-seen i {
  color: #00d285;
  font-size: 16px;
}

.chat-msg-attachments {
  padding: 4px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin: 0 -1px;
}
.chat-msg-attachments > div {
  margin: 0 1px;
}

.msg_card_body .media.sent .media-body .msg-box > div .chat-msg-info, .msg_card_body .media.sent .media-body .msg-box > div .chat-msg-attachments {
  flex-direction: row-reverse;
}
.msg_card_body .media.sent .media-body .msg-box > div .chat-msg-info li {
  padding-left: 16px;
  padding-right: 0;
  position: relative;
}
.msg_card_body .media.sent .media-body .msg-box > div .chat-msg-info li:last-child {
  padding-left: 0;
}

.chat-attachment {
  position: relative;
  max-width: 130px;
  overflow: hidden;
  border-radius: 0.25rem;
}
.chat-attachment img {
  max-width: 100%;
}
.chat-attachment:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  content: "";
  opacity: 0.4;
  transition: all 0.4s;
}
.chat-attachment:hover:before {
  opacity: 0.6;
}

.chat-attach-caption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  padding: 7px 15px;
  font-size: 13px;
  opacity: 1;
  transition: all 0.4s;
}

.chat-attach-download {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.4s;
  color: #fff;
  width: 32px;
  line-height: 32px;
  background: rgba(255, 255, 255, 0.2);
  text-align: center;
}
.chat-attach-download:hover {
  color: #495463;
  background: #fff;
}

.chat-attachment:hover .chat-attach-caption {
  opacity: 0;
}
.chat-attachment:hover .chat-attach-download {
  opacity: 1;
}

.chat-attachment-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: -5px;
}
.chat-attachment-list li {
  width: 33.33%;
  padding: 5px;
}

.chat-attachment-item {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 5px solid rgba(230, 239, 251, 0.5);
  height: 100%;
  min-height: 60px;
  text-align: center;
  font-size: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.msg_card_body .media.sent .media-body .msg-box > div:hover .chat-msg-actions {
  opacity: 1;
}

.chat-msg-actions {
  position: absolute;
  left: -30px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: all 0.4s;
  z-index: 2;
}
.chat-msg-actions > a {
  padding: 0 10px;
  color: #495463;
  font-size: 24px;
}
.chat-msg-actions > a:hover {
  color: #2c80ff;
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}
.msg_card_body .media.sent .avatar {
  -ms-flex-order: 3;
  order: 3;
}
.msg_card_body .media.sent .media-body {
  margin-right: 6px;
}

.chat-users-list {
  padding: 15px;
}

.chat-cont-left .chat-users-list a.media {
  border: 1px solid #fff;
  padding: 8px;
  transition: all 0.2s ease 0s;
}
.chat-cont-left .chat-users-list a.media .media-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.chat-cont-left .chat-users-list a.media .media-img-wrap {
  margin-right: 10px;
  position: relative;
}
.chat-cont-left .chat-users-list a.media .media-img-wrap .avatar {
  width: 35px;
  height: 35px;
}
.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name, .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name {
  color: #34444C;
  font-weight: 500;
  font-size: 15px;
  text-transform: capitalize;
  margin-bottom: 3px;
}
.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
  color: #7A7F9A;
  font-size: 13px;
}
.chat-cont-left .chat-users-list a.media .media-body > div:last-child {
  text-align: right;
}
.chat-cont-left .chat-users-list a.media .media-body > div:last-child .badge {
  width: 15px;
  height: 15px;
  background: #4C40ED;
  border-radius: 50%;
  color: #fff;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  font-weight: 500;
  font-size: 9px;
  padding: 0;
}
.chat-cont-left .chat-users-list a.media .media-body > div:last-child .last-chat-time {
  color: #7A7F9A;
  font-size: 13px;
}
.chat-cont-left .chat-users-list a.media.active {
  background-color: #F3F3F5;
  border-radius: 5px;
}

.chat-page .footer {
  display: none;
}

.chat-cont-right .card {
  border-color: rgba(220, 220, 220, 0.3);
  border-radius: 5px;
}
.chat-cont-right .card-header {
  padding: 15px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
}
.chat-cont-right .img_cont {
  width: auto;
  height: auto;
}
.chat-cont-right .msg_head .back-user-list {
  display: none;
  padding-right: 10px;
  margin-left: -20px;
  padding-left: 10px;
}

.chat-search {
  padding: 0px 15px 24px;
  width: 100%;
}
.chat-search .input-group {
  width: 100%;
}
.chat-search .input-group .form-control {
  border: 1px solid #F7F7FF;
  border-radius: 7px !important;
  background-color: #fff;
  padding-left: 36px;
  min-height: 35px;
  font-size: 13px;
  font-weight: 400;
}
.chat-search .form-control::placeholder {
  color: #888888;
}
.chat-search .input-group .form-control:focus {
  border-color: #ccc;
  box-shadow: none;
}
.chat-search .input-group .input-group-prepend {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  bottom: 0;
  color: #888888;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  left: 10px;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: 4;
}

.chat-options ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  display: -webkit-flex;
}
.chat-options ul li a {
  width: 30px;
  height: 30px;
  color: #74788D;
  background-color: #fff;
  border-radius: 30px;
  display: inline-block;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
}
.chat-options ul li:not(:last-child) {
  margin-right: 5px;
}
.chat-options ul li a:hover, .chat-options ul li a.with-bg {
  background-color: #F3F3F5;
}

.msg-highlight a {
  font-size: 13px;
  color: #4C40ED;
  text-decoration: underline;
}

.chat-cont-profile .card {
  border: 2px solid rgba(220, 220, 220, 0.3);
  border-radius: 5px;
}
.chat-cont-profile .card-header {
  padding: 14px 14px 0;
  background: #fff;
  border: 0;
}
.chat-cont-profile .card-body {
  padding: 14px;
  max-height: calc(100vh - 265px);
  overflow-y: auto;
}

.chat-profile {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
}

.chat-profile-img {
  margin-right: 16px;
}
.chat-profile-img img {
  width: 61px;
  height: 61px;
  border-radius: 50%;
}

.chat-profile-info h6 {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 3px;
}
.chat-profile-info p {
  font-size: 11px;
  color: #959494;
  margin-bottom: 0;
}

.profile-wrap {
  border-bottom: 1px solid #F2F2F2;
  padding-bottom: 14px;
}
.profile-wrap ul li {
  display: inline-block;
  margin-right: 15px;
}
.profile-wrap ul li:last-child {
  margin-right: 0;
}
.profile-wrap ul li a {
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 30px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  background-color: #F3F3F5;
  color: #585858;
}
.profile-wrap ul li a:hover {
  background: #4C40ED;
  color: #fff;
}

.chat-pro-list ul {
  padding: 18px 0;
  border-bottom: 1px solid #F2F2F2;
}
.chat-pro-list ul li {
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 13px;
}
.chat-pro-list ul li .role-info {
  font-weight: 400;
  color: #8B8B8B;
}
.chat-pro-list ul li:last-child {
  margin-bottom: 0;
}

.role-title {
  width: 100px;
  display: inline-block;
}
.role-title i {
  margin-right: 8px;
}

.media-list {
  text-align: center;
}
.media-list ul {
  margin-bottom: 10px;
}
.media-list li {
  display: inline-block;
  margin-bottom: 10px;
}
.media-list li a {
  padding: 5px 12px;
  font-weight: 500;
  font-size: 12px;
  color: #34444C;
  border-radius: 50px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.media-list li a.active {
  background-color: #F3F3F5;
}

.photo-list ul li {
  display: inline-block;
  margin: 0 12px 15px 0;
}
.photo-list ul li img {
  width: 80px;
  height: 70px;
  object-fit: cover;
  border-radius: 10px;
}

.chat-cont-profile .card-body::-webkit-scrollbar, .chat-cont-left .chat-scroll::-webkit-scrollbar, .chat-scroll::-webkit-scrollbar {
  width: 3px;
  background: #fff;
  height: 3px;
}

.chat-cont-profile .card-body::-webkit-scrollbar-track, .chat-cont-left .chat-scroll::-webkit-scrollbar-track, .chat-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
}

.chat-cont-profile .card-body::-webkit-scrollbar-thumb, .chat-cont-left .chat-scroll::-webkit-scrollbar-thumb, .chat-scroll::-webkit-scrollbar-thumb {
  background: #D4D4D4;
  border-radius: 10px;
}

.chat-cont-profile .card-body::-webkit-scrollbar-thumb:hover, .chat-cont-left .chat-scroll::-webkit-scrollbar-thumb:hover, .chat-scroll::-webkit-scrollbar-thumb:hover {
  background: #D4D4D4;
}

.chat-notification ul li {
  border-bottom: 1px solid #F2F2F2;
  padding: 14px 0;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.chat-notification ul li p {
  font-weight: 500;
  font-size: 14px;
  color: #28283C;
  margin-bottom: 0;
}
.chat-notification ul li p i {
  color: #34444C;
  margin-right: 5px;
  width: 15px;
}

.chat-media-title {
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin: 18px 0 10px 0;
}
.chat-media-title h6 {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 10px;
}
.chat-media-title span {
  font-size: 12px;
  color: #4C40ED;
  display: inline-block;
  margin-bottom: 10px;
}

.photo-link li a, .photo-file {
  font-size: 12px;
}

.photo-link li a:hover {
  color: #4C40ED;
}

.photo-file li {
  margin-bottom: 10px;
}

.clear-list ul {
  padding-top: 18px;
}
.clear-list ul li {
  font-weight: 500;
  font-size: 14px;
  color: #8C8C8C;
  margin-bottom: 14px;
}
.clear-list ul li:last-child {
  margin-bottom: 0;
}
.clear-list ul li a {
  color: #8C8C8C;
}
.clear-list ul li a i {
  margin-right: 10px;
}
.clear-list ul li a:hover {
  color: #4C40ED;
}

/*-----------------
	23. View Service
-----------------------*/
.service-wrap {
  margin-bottom: 30px;
}
.service-wrap p {
  color: #74788D;
}
.service-wrap p:last-child {
  margin-bottom: 0;
}

.provide-box {
  margin-bottom: 22px;
  display: flex;
}
.provide-box > span {
  width: 45px;
  height: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F4F5F6;
  color: #28283C;
  border-radius: 50%;
  margin-right: 12px;
  flex-shrink: 0;
}
.provide-box > img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 12px;
}

.provide-service.service-wrap {
  margin-bottom: 15px;
}

.provide-social.social-icon li {
  display: inline-block;
  margin-right: 7px;
}
.provide-social.social-icon li a {
  width: 36px;
  height: 36px;
  background: #F7F7FF;
  border-radius: 40px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: #28283C;
}
.provide-social.social-icon li a:hover {
  background-color: #4C40ED;
  color: #fff;
}

.provide-info h6 {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 4px;
}
.provide-info p {
  font-size: 14px;
  color: #74788D;
  word-break: break-word;
  margin-bottom: 0;
}

.service-wrap h5 {
  font-weight: 600;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}

.card-provide {
  border: 2px solid #F5F5F5;
  border-radius: 10px;
}
.card-provide .card-body {
  padding: 15px;
}

.provide-widget {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 30px;
}
.provide-widget .service-amount {
  margin-left: 0;
  line-height: 0;
  color: #28283C;
}
.provide-widget .service-amount h5 {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 7px;
}

.service-amount p i {
  color: #FFBC35;
}
.service-amount h5 span {
  color: #74788D;
  font-size: 20px;
  margin-left: 5px;
  text-decoration: line-through;
}
.service-amount .serv-review {
  font-size: 12px;
  font-weight: 500;
  color: #74788D;
  margin-bottom: 0;
}
.service-amount .serv-review span {
  font-size: 16px;
  font-weight: 600;
  color: #28283C;
}

.serv-proimg {
  position: relative;
}
.serv-proimg img {
  width: 61px;
  height: 61px;
  border-radius: 50%;
}
.serv-proimg span {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 15px;
  color: #58C27D;
  background: #fff;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  display: inline-block;
  text-align: center;
}

.package-widget {
  background: #F7F7FF;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
}
.package-widget h5 {
  font-size: 18px;
  margin-bottom: 20px;
}
.package-widget ul li {
  position: relative;
  margin-bottom: 12px;
  padding-left: 24px;
  color: #74788D;
  display: flex;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.package-widget ul li:last-child {
  margin-bottom: 0;
}
.package-widget ul li:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "\f058";
  font-weight: 900;
  font-family: "Font Awesome 6 Free";
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  color: #4C40ED;
}

.pack-service.package-widget ul li {
  padding-left: 0;
}
.pack-service .custom_check .checkmark {
  border: 1px solid #C2C9D1;
  background: transparent;
  height: 15px;
  width: 15px;
}
.pack-service.package-widget ul li:before {
  content: none;
}

.add-serv-info, .add-serv-item {
  overflow: hidden;
}

.add-serving {
  width: calc(100% - 65px);
  display: flex;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.add-serv-item {
  display: flex;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.add-serv-img {
  margin-right: 8px;
  flex-shrink: 0;
}
.add-serv-img img {
  width: 48px;
  height: 48px;
  border-radius: 5px;
  flex-shrink: 0;
}

.add-serv-info h6 {
  font-size: 16px;
  font-weight: 500;
  color: #28283C;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.add-serv-info p {
  font-size: 12px;
  color: #74788D;
  margin-bottom: 0;
}

.add-serv-amt h6 {
  font-weight: 500;
  font-size: 16px;
  color: #28283C;
  margin-bottom: 0;
}

.serv-profile h2 {
  font-size: 38px;
  margin-bottom: 8px;
}
.serv-profile ul li {
  font-size: 14px;
  color: #74788D;
  display: inline-block;
  margin-right: 10px;
}

.service-map i {
  color: #C2C9D1;
}

.serv-profile span.badge {
  font-weight: 500;
  font-size: 13px;
  background: #F7F7FF;
  color: #4C40ED;
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 10px 17px;
  display: inline-block;
}

.card-available {
  background: #F7F7FF;
  border-radius: 8px;
  border: 0;
}
.card-available .card-body {
  padding: 15px;
}

.available-info ul li {
  color: #28283C;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 14px;
  padding: 0;
  text-align: left;
}
.available-info ul li:last-child {
  margin-bottom: 0;
}
.available-info ul li span {
  float: right;
  color: #74788D;
}

.card-provide .contact-map {
  display: block;
  width: 100%;
  height: 250px;
  border-radius: 10px;
  margin: 0;
}

.service-wrap .owl-nav button.owl-next, .service-wrap .owl-nav button.owl-prev {
  margin-bottom: 20px;
}

.service-images {
  position: relative;
  margin-bottom: 45px;
}
.service-images img {
  width: 100%;
  border-radius: 10px;
}

.big-gallery a.btn-show {
  background: #fff;
  border-radius: 8px;
  color: #4C40ED;
  font-weight: 500;
  font-size: 13px;
  padding: 5px 12px;
  position: absolute;
  left: 20px;
  bottom: 20px;
}

.gallery-widget img {
  border-radius: 5px;
}

.owl-nav button.owl-next, .owl-nav button.owl-prev {
  width: 32px;
  height: 32px;
  background: #fff;
  box-shadow: 0px 4px 14px rgba(141, 141, 141, 0.25);
  text-shadow: none;
  top: 4px;
  cursor: pointer;
  border: 0;
  border-radius: 50%;
  color: #28283C;
  margin-bottom: 30px;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
}
.owl-nav button.owl-next:hover, .owl-nav button.owl-prev:hover {
  background-color: #4c40ed;
  color: #fff;
}
.owl-nav button.owl-next {
  margin-left: 10px;
}

/*-----------------
	24. Add Service
-----------------------*/
#progressbar {
  margin-bottom: 30px;
}
#progressbar li {
  display: inline-flex;
  display: -webkit-inline-flex;
  align-items: center;
  -webkit-align-items: center;
  margin: 0 30px 20px 0;
  background: #FBFBFF;
  box-shadow: 0px 1px 2px rgba(55, 65, 81, 0.08);
  padding: 10px;
  position: relative;
  text-align: left;
  opacity: 0.8;
}
#progressbar li:last-child {
  margin-right: 0;
}
#progressbar li::after {
  position: absolute;
  display: block;
  content: "";
  width: 0;
  height: 0;
  left: 100%;
  top: 0;
  border-left: 15px solid #FBFBFF;
  border-right: 15px solid transparent;
  border-top: 31px solid transparent;
  border-bottom: 30px solid transparent;
}
#progressbar li.active, #progressbar li.activated {
  background: #F7F7FF;
  opacity: 1;
}
#progressbar li.active::after #progressbar li.activated::after {
  border-left: 15px solid #F7F7FF;
}
#progressbar .multi-step-info {
  margin-right: 10px;
}
#progressbar .multi-step-info h6 {
  font-weight: 500;
  font-size: 14px;
  color: #28283C;
  margin-bottom: 2px;
}
#progressbar .multi-step-info p {
  font-size: 12px;
  color: #74788D;
  margin-bottom: 0;
}
#progressbar .multi-step-icon span {
  width: 39px;
  height: 39px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
}
#progressbar li.active span {
  background: #4C40ED;
  color: #fff;
}

.schedule-nav {
  margin-bottom: 10px;
}
.schedule-nav ul li {
  margin: 5px 15px 10px 0;
  display: inline-block;
}
.schedule-nav ul li:last-child {
  margin-right: 0;
}
.schedule-nav ul li a.active, .schedule-nav ul li a:hover {
  background: #4C40ED;
  border: 1px solid #4C40ED;
  color: #fff;
}
.schedule-nav ul > li > a {
  background: #F7F7FF;
  border: 1px solid #F7F7FF;
  border-radius: 4px;
  padding: 4px 15px;
  color: #4C40ED;
  font-weight: 500;
  font-size: 13px;
}

.form-icon {
  position: relative;
}
.form-icon .cus-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  height: 20px;
}

.timepicker-sec {
  display: none;
}

.contact-map {
  border: 0;
  display: block;
  width: 100%;
  height: 450px;
  margin: 0;
}

.file-preview ul {
  margin-bottom: 5px;
}
.file-preview ul li {
  display: inline-block;
  margin: 0 15px 15px 0;
  position: relative;
}
.file-preview ul li h6 {
  font-weight: 400;
  font-size: 14px;
}
.file-preview ul li .custom_check {
  position: absolute;
  right: 20px;
  padding-left: 28px;
  bottom: -20px;
}
.file-preview ul li a {
  position: absolute;
  top: 3px;
  right: 3px;
  color: #F82424;
}
.file-preview ul li img {
  width: 83px;
  height: 72px;
  object-fit: cover;
  border-radius: 5px;
}
.file-preview .custom_check input:checked ~ .checkmark {
  background-color: #4C40ED;
  border-color: #4C40ED;
}
.check-delete a {
  width: 24px;
  height: 24px;
  background: #FF0101;
  border-radius: 5px;
  position: absolute;
  top: 5px;
  right: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.check-delete i {
  color: #fff;
}

.field-btns {
  margin-top: 20px;
  text-align: right;
}
.field-btns .btn {
  font-weight: 500;
  font-size: 14px;
  padding: 9px 24px;
}

fieldset {
  display: none;
}
fieldset#first-field {
  display: block;
}
#progress-head li {
  display: none;
}
#progress-head li.active {
  display: block;
}

.form-duration {
  position: relative;
}
.form-duration .mins {
  position: absolute;
  background: #fff;
  border-radius: 6px;
  color: #4C40ED;
  font-weight: 500;
  font-size: 14px;
  padding: 9px 18px;
  top: 50%;
  transform: translateY(-50%);
  right: 4px;
}
.form-duration .form-control {
  padding: 10px 70px 10px 15px;
}

.sml-info {
  font-size: 12px;
  color: #929292;
}

.authentication-buttons .radiossets .checkmark-radio::after {
  background: #00CC45;
}
.authentication-buttons .radiossets input:checked ~ .checkmark-radio {
  border: 1px solid #00CC45;
}
.authentication-buttons .checkboxs input:checked ~ span {
  background: #00CC45;
}

/*-----------------
	25. admin1
-----------------------*/



.verify-request .table-download {
  display:inline-flex;
  align-items: flex-start;
  border-radius: 5px;
  background:#4C40ED;
  padding: 10px;
  color: white;
  border: 1px solid #4C40ED;
  cursor: pointer;
}
.verify-request .table-download:hover {
  background: white;
  transition: all 0.5s;
  color: #4C40ED;
}
.verify-request .table.thead.th {
  padding: 16px;
  font-size: 16px;
  font-weight: 500;
  color: #28283C;

}
.btn-rejected {
  border-radius: 4px;
  background: #E63C3C;
  padding: 1px 5px;
  text-align: center;
  width: 75px;
  color: white;
  box-shadow: inset 0 0 0 0 #FFFFFF;
}
.btn-verified {
  border-radius: 4px;
  background: #27C24C;
  padding: 1px 5px;
  text-align: center;
  width: 75px;
  color: white;
  box-shadow: inset 0 0 0 0 #FFFFFF;
}
.btn-verified:hover {
  color: #27C24C;
  background-color: white;
  border: 1px solid #27C24C;
  transition: all 0.5s;
  box-shadow: inset 0 0 0 0 #FFFFFF;
}
.btn-rejected:hover {
  color: #E63C3C;
  background-color: white;
  border: 1px solid #E63C3C;
  transition: all 0.5s;
  box-shadow: inset 0 0 0 0 #FFFFFF;
}
.verify-request .btn-primary {
  display:flex;
  align-items:center;
  border-radius: 5px;
  justify-content:center;
  background:#4C40ED;
  padding: 10px 15px;
 
}
.service-models .modal-header {
  border-bottom: 1px solid #E1E1E1;
  margin-bottom: 20px;
  padding: 20px;
  padding-bottom: 20px;
}
.service-models textarea.form-control {
  border: 1px solid #E1E1E1;
  box-shadow: none;
  color: #2A313D;
  height: 133px;
  padding: 13px 10px;
  border-radius: 5px;
  background: white;
}
.table-actions {
  align-items: center;
  justify-content: flex-end;
}
.action-language .active-switch {
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.table-edit {
  background: #E2F6ED;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 74px;
  padding: 4px 8px;
  height: 26px;
  margin-right: 5px;
  border-radius: 4px;
  color: #26AF48;
}
.table-edit i {
  margin-right: 4px;
}
.table-delete {
  background: #FDE2E7;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 74px;
  padding: 4px 8px;
  height: 26px;
  border-radius: 4px;
  color: #E63C3C;
}
.table-delete i {
  margin-right: 4px;
}
.table > :not(caption) > * > * {
  padding: 16px;
  box-shadow: unset;
  background-color: unset;
}
.delete-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: 0;
  justify-content: center;
  padding: 0 40px 40px;
}
.delete-footer .btn-secondary {
  margin-right: 10px;
}
.modal-content {
  padding: 0px;
}
.modal-dialog:not(.modal-dialog-scrollable) .modal-footer {
  padding-top: 0;
}
.modal-delete {
   margin-top: 0px;
    color: white;
   display: flex;
   align-items: center;
   border-radius: 5px;
   justify-content: center;
   background: #4C40ED;
}
.modal-delete:hover {
  background: white;
  color:#4C40ED ;
  border: 1px solid #4C40ED;
}
.delete-popup {
  display: flex;
  color: #FF0101;
  background: white;
  border: none;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 40px 0 5px 0;
}
.delete-popup i {
  font-size: 40px;
  margin-bottom: 5px;
}
.del-modal h5 {
  margin-bottom: 15px;
}
.del-modal p {
  margin-bottom: 15px;
}
.del-modal {
  text-align: center;
}
.tax-table-rate .list-btn a{
  display: flex;
  align-items: center;
}
.tax-table-rate .list-btn ul li {
  margin: 0 10px;
}
.tax-module {
  display: flex;
  margin-right: 10px;
}
.action-language {
  display: flex;
  justify-content: flex-end;
}
.active-check li {
  display: flex;
}

/* -------------------
  Typography Setting
 -------------------*/
 
 .typo-setting .form-groupheads h2 {
  border-bottom: 1px solid #E1E1E1;
  padding-bottom: 24px;
 }
.btn-typograp .btn-primary {
 display: inline-block;
}

/* -------------------
  Calendar Setting
 -------------------*/
.calendar-set-grp {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.calendar-events {
  border: 1px solid #ccc;
  cursor: move;
}
.calendar-events:hover {
  border-color: #ccc;
  background-color: #fff;
}
.calendar-events i {
  margin-right: 8px;
}
.calendar {
  float: left;
  margin-bottom: 0;
}
.fc-toolbar.fc-header-toolbar {
  margin-bottom: 1.5rem;
}
.none-border .modal-footer {
  border-top: none;
}
.fc-toolbar h2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  text-transform: uppercase;
}
.fc-day {
  background: #fff;
}
.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
  z-index: 0;
}
.fc th.fc-widget-header {
  background: #fff;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 0;
  text-transform: uppercase;
  color: #333;
}
.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
  border-color: #f3f3f3;
}
.fc-basic-view .fc-day-number, 
.fc-basic-view .fc-week-number {
  padding: 2px 5px;
}
.fc-button {
  background: #f1f1f1;
  border: none;
  color: #797979;
  text-transform: capitalize;
  box-shadow: none !important;
  border-radius: 3px !important;
  margin: 0 3px !important;
  padding: 6px 12px !important;
  height: auto !important;
}
.fc-text-arrow {
  font-family: inherit;
  font-size: 16px;
}
.fc-state-hover {
  background: #f3f3f3;
}
.fc-state-highlight {
  background: #f0f0f0;
}
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  background-color: #4C40ED !important;
  color: #fff !important;
  text-shadow: none !important;
}
.fc-cell-overlay {
  background: #f0f0f0;
}
.fc-unthemed .fc-today {
  background: #fff;
}
.fc-event {
  border-radius: 2px;
  border: none;
  color: #fff !important;
  cursor: move;
  font-size: 13px;
  margin: 1px 7px;
  padding: 5px 5px;
  text-align: center;
}
.fc-basic-view td.fc-week-number span {
  padding-right: 8px;
  font-weight: 700;
}
.fc-basic-view td.fc-day-number {
  padding-right: 8px;
  font-weight: 700;
}
.event-form .input-group .form-control {
  height: 40px;
}
.submit-section {
  text-align: center;
  margin-top: 40px;
}
.submit-btn {
  border-radius: 50px;
  font-size: 18px;
  font-weight: 600;
  min-width: 200px;
  padding: 10px 20px;
}


/*-----------------
	25. Bank  Transfer
-----------------------*/
.bank-box {
  background: #F8F8FE;
  border: 1px solid #EBEBEB;
  border-radius: 10px;
  padding: 20px;
  position: relative;
}

.bank-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.bank-name h6 {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 10px;
}
.bank-name p {
  color: #74788D;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;
}

.bank-info p {
  font-size: 12px;
  color: #74788D;
  margin-bottom: 6px;
}
.bank-info h6 {
  font-size: 14px;
  font-weight: 500;
  color: #28283C;
  margin-bottom: 10px;
}

.bank-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
}
.bank-action a {
  font-weight: 500;
  font-size: 14px;
  color: #4C40ED;
}
.bank-action a i {
  margin-right: 5px;
}

.bank-box.active {
  border: 1px solid #00CC45;
}
.bank-box.active:before {
  position: absolute;
  top: -5px;
  right: -5px;
  content: "\f058";
  font-family: "Fontawesome";
  color: #00CC45;
}

.seo-list {
  margin-bottom: 20px;
}
.seo-list li {
  display: inline-block;
  margin-right: 10px;
}
.seo-list li a {
  display: inline-block;
  background: #FFFFFF;
  border: 2px solid #E1E2E5;
  border-radius: 6px;
  padding: 4px 26px;
  font-weight: 500;
  font-size: 15px;
  color: #28283C;
}
.seo-list li a.active {
  background: #F1F1F1;
}

.location-set.soc-active {
  border: 2px solid #4C40ED;
}

/* -------------------
  update design 
--------------------*/

.progressbar-card #progress-head li {
  margin-bottom: 28px;
  font-size: 28px;
  font-weight: 700;
  padding-top: 24px;
  color: #333333;
}
.progressbar-card #progressbar  {
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
}
.progressbar-card #progressbar li {
  flex-direction: column;
  margin: 0;
  background: #fff;
  box-shadow: 0px 1px 2px rgba(55, 65, 81, 0.08);
  padding: 10px;
  position: relative;
  text-align: left;
  width: 227px;
  margin-bottom: 10px;
  border: 1px solid #eee;
  margin-right: 1px;
}
.progressbar-card #progressbar {
  margin-bottom: 20px;
}
.progressbar-card #progressbar .multi-step-icon span {
  width: 39px;
  height: 39px;
  background: var(--background-container, #FAFAFA);
  border-radius: 50%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
}

.progressbar-card #progressbar li.activated .multi-step-icon span {
  background: #333333;
}
.progressbar-card #progressbar li.activated .multi-step-icon span i {
  color: #fff
}
.progressbar-card #progressbar li::after {
  border: 0;
}
.progressbar-card #progressbar li.active::after {
  position: absolute;
  display: block;
  content: '';
  width: 0;
  height: 0;
  left: 50%;
  right: 0;
  top: 100%;
  bottom: 100%;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #4C40ED;
  border-bottom: 5px solid transparent;
  transform: translateX(-50%);
}
.progressbar-card #progressbar li:last-child::before {
  display: none;
}
.progressbar-card #progressbar li.active::before {
  display: none;
}
.progressbar-card #progressbar li.active span {
  background: #fff;
  color: #4C40ED;
}
.progressbar-card #progressbar li:first-child.active {
  border-radius: 10px 0px 0px 10px;
}
.progressbar-card #progressbar li:last-child.active {
  border-radius: 0px 10px 10px 0px;
}
.progressbar-card #progressbar li.active {
  background: #4C40ED;
  border-radius: 0;
  opacity: 1;
}
.progressbar-card #progressbar li.active .multi-step-icon i {
  color: #4C40ED;
}
.progressbar-card #progressbar li.active h6 {
  color: #fff;
}
.progressbar-card #progressbar .multi-step-info h6 {
  font-weight: 500;
  font-size: 14px;
  color: #000;
  margin-bottom: 2px;
}
.progressbar-card #progressbar li .multi-step-icon i {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 18px;
  color: #000;
}
.progressbar-card #progressbar li .multi-step-icon {
  margin-bottom: 10px;
}
.progressbar-card #progressbar li .multi-step-icon i.fa-check-circle,.progressbar-card #progressbar li .multi-step-icon i.fa-clock {
  position: relative;
  top: -1px;
  left: 1px;
}
/* -------------------
  Service Information 
--------------------*/
#first-field .container-service {
  padding: 24px;
  border-radius: 10px;
  background: var(--white, #FFF);
  box-shadow: 0px 4px 114px 0px rgba(210, 210, 210, 0.25);
  margin-bottom: 24px;
}
#first-field .container-service .sub-title {
  border-bottom: 1px solid #E1E1E1;
  margin-bottom: 24px;
}
#first-field .container-service .sub-title h6 {
   margin-bottom: 24px;
   font-size: 20px;
   font-weight: 500;
}
#first-field .container-service .form-group.price label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#first-field .container-service .form-group input {
  background: #fff;
  border-radius: 5px;
  border: 1px solid var(--stroke, #E1E1E1);
}
.form-group {
  margin-bottom: 24px;
}
.additional {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #E1E1E1;
  margin-bottom: 24px;
}
.status-toggle {
  display: block;
}
#first-field .container-service .additional .sub-title {
  border-bottom: none;
  margin-bottom: 0;
}
#first-field .container-service .select2-container .select2-selection--single {
  background: #fff;
  border-radius: 5px;
  border: 1px solid var(--stroke, #E1E1E1);
}
.addservice-info .service-cont .form-group.tax label {
  display: flex;
  justify-content: space-between;
}
.video-title {
  border-bottom: 1px solid #E1E1E1;
  margin-bottom: 24px;
}
.video-title h6 {
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 500;
}
.bottom-btn {
  display: flex;
  justify-content: flex-end;
}
.bottom-btn .field-btns {
  margin-top: 0;
}
.bottom-btn .field-btns .btn-prev {
  background: #000;
  border-color: #000;
  border-radius: 8px;
  color: #fff;
  transition: 0.5s;
  margin-right: 10px;
 
}
.bottom-btn .field-btns .btn-prev:hover {
  border: 1px solid #000;
  box-shadow: 0 50px 0 0 #fff inset;
  color: #000;
  transition: 0.5s;
}
.bottom-btn .btn-primary{
  background-color: #4C40ED;
  border-color: #4C40ED;
  transition: 0.5s;
  border-radius: 8px;
} 
.bottom-btn .btn-primary:hover {
  background-color: #fff;
  border: 1px solid #4C40ED;
  box-shadow: 0 50px 0 0 #fff inset;
  color: #2c1fe5;
  transition: 0.5s;
}
/* -----------------
   Availablity 
-------------------*/
.available-heading {
  border-bottom: 1px solid #E1E1E1;
  margin-bottom: 24px;
}
.available-heading h4 {
  margin-bottom: 24px;
  font-weight: 500;
}
.card-section {
  padding: 24px;
  border-radius: 10px;
  background: var(--white, #FFF);
  box-shadow: 0px 4px 114px 0px rgba(210, 210, 210, 0.25);
  margin-bottom: 24px;
}
.card-section.space-service {
  padding-bottom: 0;
}
#first-field .container-service.space-service {
  padding-bottom: 0;
}
.available-section .schedule-nav {
  margin-bottom: 14px;
}
.available-section .hours-info .hours-cont .days {
  margin-bottom: 10px;
}
.available-section .hours-info:first-child {
  border-bottom: 1px solid #E1E1E1;
  margin-bottom: 24px;
}
.available-section .select2-container .select2-selection--single {
  background: #fff;
  border-radius: 5px;
  border: 1px solid var(--stroke, #E1E1E1);
}
.available-section .schedule-nav ul > li > a {
  background: #F7F7FF;
  border: 1px solid #F7F7FF;
  border-radius: 4px;
  padding: 4px 15px;
  color: #000;
  font-weight: 500;
  font-size: 13px;
  border-radius: 4px;
  background: var(--input, #DDD);
}
.available-section .schedule-nav ul li a.active, .schedule-nav ul li a:hover {
  background: #4C40ED;
  border: 1px solid #4C40ED;
  color: #fff;
}
.available-section .multi-step-icon span {
  background: red;
}
.card-section .form-control,
.card-section .bootstrap-tagsinput {
  background: #fff;
  border-radius: 5px;
  border: 1px solid var(--stroke, #E1E1E1);
}

.form-group.service-editor .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  background: #fff;
}
.badge-active.completed-active-badge {
  border-radius: 4px;
  background: #27C24C;
  color: #fff;
}
.badge-active.inprogress.loading-inprogress {
  background: #FFC107;
  border-radius: 4px;
  color: #fff;
}
.badge-active.badge-reject.reject-cancel {
  background: #E63C3C;
  color: #fff;
  border-radius: 4px;
}
.badge-active.badge-pending.pending-process {
  background: #00C6FF;
  color: #fff;
  border-radius: 4px;
}
.badge-pending,.badge-active,.badge-inactive {
  border-radius: 4px;
}
/* -----------------
  Location
-------------------*/

.heading-service {
  border-bottom: 1px solid #E1E1E1;
  margin-bottom: 24px;
}
.heading-service h4 {
  margin-bottom: 24px;
  font-weight: 500;
}
.addition-service .file-preview ul.gallery-selected-img li .custom_check .checkmark {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background-color: #fff;
}
.addition-service .file-preview ul li .custom_check .checkmark::after {
	content: "";
	width: 12px;
	height: 12px;
	border-radius: 50%;
	position: absolute;
	background: #4c40ed;
	opacity: 0; 
	top: 3px;
	left: 3px;
	display: none;
}
.addition-service .file-preview ul li .custom_check input:checked ~ .checkmark::after {
	opacity: 1;
	display: block;
}
.addition-service .file-preview {
  margin-top: 24px;
}
.addition-service .file-preview label {
  color: #2A313D;
}
.file-preview ul li {
  margin-bottom: 30px;
}
.pass-group input {
  padding-right: 40px;
}
.img-preview a.remove-gallery {
  width: 24px;
  height: 24px;
  background-color: red;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.form-availability-field {
  position: relative;
}
.form-availability-field span.cus-icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}
.chat-search .input-group>.form-control:focus {
  z-index: 3;
}
.add-service-modal .modal-content {
	padding: 40px;
	text-align: center;
}
.add-service-modal .modal-content .modal-body {
	padding: 0;
}
.add-service-modal .modal-content .modal-body span {
	margin-bottom: 10px;
	display: block;
}
.add-service-modal .modal-content .modal-body span i {
	font-size: 40px;
	color: #6DCC76;
}
.add-service-modal .modal-content .modal-body h3 {
	font-size: 28px;
	margin-bottom: 15px;
}
.add-service-modal .modal-content .modal-body p {
	margin-bottom: 15px;
}
/*-----------------
	26. Responsive
-----------------------*/
@media only screen and (min-width: 992px) {
  .admin-header.fixed-header #toggle_btn {
    align-items: center;
    color: #fff;
    display: inline-flex;
    float: left;
    font-size: 30px;
    height: 60px;
    margin-left: 15px;
    justify-content: center;
    padding: 0 20px;
  }
  .mini-sidebar .header-left .logo img {
    height: auto;
    max-height: 24px;
    width: auto;
  }
  .mini-sidebar .admin-header .header-left .logo {
    display: none;
  }
  .mini-sidebar .header-left .logo.logo-small {
    display: block;
  }
  .mini-sidebar .admin-header .header-left {
    padding: 0 5px;
    width: 90px;
  }
  .mini-sidebar .admin-sidebar {
    width: 90px;
  }
  .mini-sidebar.expand-menu .admin-sidebar {
    width: 270px;
  }
  .mini-sidebar .admin-sidebar-menu {
    padding: 10px;
  }
  .mini-sidebar .menu-title {
    visibility: hidden;
    white-space: nowrap;
  }
  .mini-sidebar.expand-menu .menu-title {
    visibility: visible;
  }
  .mini-sidebar .menu-title a {
    visibility: hidden;
  }
  .mini-sidebar.expand-menu .menu-title a {
    visibility: visible;
  }
  .modal-open.mini-sidebar .admin-sidebar {
    z-index: 1051;
  }
  .mini-sidebar .admin-sidebar .admin-sidebar-menu ul > li > a span {
    display: none;
    transition: all 0.2s ease-in-out;
    opacity: 0;
  }
  .mini-sidebar.expand-menu .admin-sidebar .admin-sidebar-menu ul > li > a span {
    display: inline;
    opacity: 1;
  }
  .mini-sidebar .page-wrapper {
    margin-left: 90px;
  }
  .mini-sidebar.expand-menu .page-wrapper {
    margin-left: 270px;
  }
  .mini-sidebar .main-wrapper > .header, .mini-sidebar .admin-header.fixed-header {
    left: 90px;
  }
  .mini-sidebar .admin-sidebar-menu > ul > li > a {
    border-radius: 10px;
    padding: 10px 20px;
  }
  .mini-sidebar.expand-menu .main-wrapper > .admin-header {
    left: 270px;
  }
  .mini-sidebar.expand-menu .main-wrapper > .admin-header.fixed-header {
    left: 270px;
  }
}
@media only screen and (max-width: 1399.98px) {
  .login-logo {
    margin-bottom: 40px;
  }
  .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name, .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
    max-width: 65px;
  }
  .chat-cont-left .chat-scroll {
    max-height: calc(100vh - 318px);
  }
  .progressbar-card #progressbar li {
    width: 200px;
  }
}
@media only screen and (max-width: 1199.98px) {
  .app-listing {
    flex-wrap: wrap;
  }
  .app-listing li {
    width: 50%;
  }
  .invoices-tabs ul li a.active {
    border-bottom: 0;
  }
  .dropdown-heads > a {
    width: 35px;
    height: 35px;
  }
  .tax-table-rate .list-btn ul {
    display: block;
  }
  .tax-table-rate .list-btn ul li {
    margin-bottom: 10px;
  }
  .tax-table-rate .list-btn ul li:last-child {
    margin-bottom: 0px;
  }
  .tax-table-rate .list-btn .filter-sorting ul {
    display: flex;
  }
  .admin-user-menu.nav > li > a {
    padding: 0 10px;
  }
  .card-table-head {
    flex-direction: column;
    align-items: flex-start;
  }
  .card-table-head ul {
    margin-left: 0;
    margin-top: 10px;
  }
}
@media only screen and (max-width: 991.98px) {
  .admin-header .header-left {
    position: absolute;
    width: 100%;
    display: block;
    height: auto;
    top: 20px;
    left: 0;
  }
  .admin-header.fixed-header .mobile_btn {
    color: #fff;
  }
  .hidden-web {
    display: none;
  }
  .connectetapps {
    flex-direction: column;
    padding: 10px;
    border-bottom: 1px solid #eee;
    margin: 0 0 10px;
    align-items: start;
  }
  .connectetappscontent {
    margin-top: 15px;
  }
  .mobile_btn {
    color: #333;
    cursor: pointer;
    display: flex;
    font-size: 26px;
    height: 60px;
    left: 0;
    padding: 0 15px;
    position: absolute;
    text-align: center;
    top: 5px;
    z-index: 10;
    justify-content: center;
    align-items: center;
  }
  .home-head-user h2 {
    font-size: 18px;
  }
  #toggle_btn, .admin-header .header-left .logo {
    display: none;
  }
  .header-left .logo.logo-small {
    display: block;
  }
  .admin-header .header-left .logo img {
    max-height: 36px;
    height: 36px;
  }
  .main-wrapper > .admin-header {
    left: 0;
  }
  .main-wrapper > .admin-header.fixed-header {
    left: 0;
  }
  .animate-circle {
    border: 0px solid #4C40ED;
  }
  .user-img {
    width: 42px;
    height: 42px;
  }
  .logo-small {
    display: block;
  }
  .flag-nav, .admin-user-menu.nav > li > a.viewsite {
    display: none;
  }
  .dropdown-heads {
    margin: 0 5px;
    display: none;
  }
  .page-headers {
    display: none;
  }
  .header-split {
    justify-content: flex-end;
  }
  .admin-sidebar {
    margin-left: -270px;
    width: 270px;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    z-index: 1041;
  }
  .page-wrapper {
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  .nav-tabs.menu-tabs .nav-item {
    padding-right: 1.25rem;
  }
  .view-btn, .select-by {
    display: none;
  }
  .app-listing {
    flex-wrap: wrap;
  }
  .app-listing li {
    width: 50%;
  }
  .report-list .app-listing {
    justify-content: flex-start;
  }
  .report-list .app-listing li {
    width: 33%;
  }
  .report-list .multiple-selection #checkboxes {
    right: 0;
    left: 0 !important;
    width: 100%;
  }
  .sortby #checkbox {
    right: 0;
    left: 0 !important;
    width: 100%;
  }
  .invoice-item .invoice-logo img {
    width: 200px;
  }
  .invoice-head p {
    font-size: 16px;
  }
  .invoice-head h2 {
    font-weight: 600;
    font-size: 20px;
  }
  .invoice-item-bg .invoice-info {
    padding: 20px;
    margin-top: 0px;
    margin-bottom: 0;
  }
  .invoice-payment-box .payment-details {
    width: 100%;
    margin-bottom: 15px;
  }
  .links-cont .form-control {
    margin-bottom: 15px;
  }
  .countset .form-control, .countset .socail-links-set {
    margin-bottom: 15px;
  }
  .links-conts .form-control {
    margin-bottom: 15px;
  }
  .invoices-tabs ul li a.active {
    border-bottom: 0;
  }
  .admin-sidebar-logo .logo-small {
    display: none;
  }
  .content-table {
    padding: 15px 30px;
    padding-right: 15px;
  }
  .content-page-headersplit {
    flex-direction: column;
  }
  .content-page-header h5 {
    font-size: 18px;
  }
  .page-head-btn {
    padding-top: 15px;
  }
  .change-password {
    display: block;
  }
  .login-pages {
    padding: 30px 15px;
  }
  .login-images {
    display: none;
  }
  .login-logo {
    margin-bottom: 30px;
    text-align: center;
  }
  .login-content {
    padding-left: 0px;
  }
  .login-contenthead h5 {
    font-size: 24px;
  }
  .login-input .form-control {
    height: 40px;
  }
  .content-page-header.content-page-headersplit h5 {
    margin-bottom: 15px;
  }
  .tab-contents-count {
    display: none;
  }
  .tab-contents-sets ul {
    flex-wrap: wrap;
  }
  .list-btn > ul {
    flex-wrap: wrap;
    justify-content: center;
  }
  .list-btn > ul > li {
    margin: 5px;
  }
  .tab-contents-sets ul li a.active::after, .tab-contents-sets ul li a:hover::after {
    height: 0;
  }
  .chat-cont-left, .chat-cont-right {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    transition: all 0.4s;
    width: 100%;
    max-height: 100%;
  }
  .chat-cont-left {
    z-index: 9;
  }
  .chat-cont-right {
    position: absolute;
    right: -100%;
    opacity: 0;
    visibility: hidden;
    z-index: 99;
  }
  .chat-cont-right .chat-header {
    justify-content: start;
    -webkit-justify-content: start;
    -ms-flex-pack: start;
  }
  .chat-cont-right .msg_head .back-user-list {
    display: flex;
    align-items: center;
  }
  .chat-cont-right .msg_head .chat-options {
    margin-left: auto;
  }
  .chat-window.chat-slide .chat-cont-right {
    right: 0;
    opacity: 1;
    visibility: visible;
  }
  .chat-cont-right .chat-scroll {
    max-height: calc(100vh - 257px);
  }
  .chat-cont-left .chat-scroll {
    max-height: calc(100vh - 292px);
  }
  .chat-cont-profile {
    z-index: 9999;
  }
  .msg_card_body .media.sent .media-body .msg-box {
    padding-left: 0px;
  }
  .chat-options ul li a {
    width: 20px;
    height: 20px;
  }
  .serv-profile h2 {
    font-size: 32px;
  }
  .tax-table-rate .list-btn ul li {
    margin-bottom: 10px;
  }
  .tax-table-rate .list-btn ul li:last-child {
    margin-bottom: 0px;
  }
}
@media only screen and (max-width: 767.98px) {
  body {
    font-size: 14px;
  }
  h1, .h1 {
    font-size: 2rem;
  }
  h2, .h2 {
    font-size: 1.75rem;
  }
  h3, .h3 {
    font-size: 1.5rem;
  }
  h4, .h4 {
    font-size: 1.125rem;
  }
  h5, .h5 {
    font-size: 1rem;
  }
  h6, .h6 {
    font-size: 0.875rem;
  }
  .admin-header .has-arrow .dropdown-toggle:after, .admin-user-menu.nav > li > a > span:not(.user-img) {
    display: none;
  }
  .navbar-nav .open .dropdown-menu {
    float: left;
    position: absolute;
  }
  .navbar-nav.admin-user-menu .open .dropdown-menu {
    left: auto;
    right: 0;
  }
  .admin-header .header-left {
    padding: 0 15px;
  }
  .admin-header .header-left .logo {
    display: none;
  }
  .header-left .logo.logo-small {
    display: inline-block;
  }
  .login-wrapper .loginbox {
    max-width: 450px;
    min-height: unset;
  }
  .page-settings {
    flex-direction: column;
  }
  .content-sidelink {
    width: 100%;
    padding: 15px;
  }
  .error-box h1 {
    font-size: 6em;
  }
  .error-box .btn {
    font-size: 15px;
    min-width: 150px;
    padding: 8px 20px;
  }
  .dash-count {
    font-size: 16px;
  }
  .nav-tabs.menu-tabs {
    margin-top: -15px;
  }
  .nav-tabs.menu-tabs .nav-item {
    margin-top: 20px;
  }
  .settings-btns .btn-grey {
    font-size: 13px;
  }
  .label {
    display: inline-block;
    padding: 0.35em 0.65em;
    font-size: 0.75em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
  }
  .app-listing {
    flex-wrap: wrap;
  }
  .app-listing li {
    width: 100%;
  }
  .invoices-tabs {
    margin-bottom: 15px;
  }
  .invoices-tabs ul li {
    margin-right: 25px;
  }
  .invoices-tabs ul li a.active {
    border-bottom: 0;
  }
  .report-list .app-listing {
    display: block;
    margin: 0;
  }
  .report-list .app-listing li {
    width: 100%;
    padding: 0;
  }
  .field-box .btn {
    min-width: 100%;
    padding: 10px 0;
  }
  .service-upload {
    width: 100%;
  }
  .invoice-total-footer h4 {
    font-size: 16px;
    color: #4C40ED;
  }
  .invoice-inner-footer .form-control {
    position: relative;
  }
  .invoices-create-btn {
    margin-top: 10px;
  }
  .invoices-create-btn .btn {
    font-size: 14px;
    padding: 8px 0px;
    min-width: 115px;
    margin-bottom: 10px;
  }
  .invoice-setting-btn .btn {
    min-width: 145px;
    padding: 7px 0;
    font-size: 15px;
    margin-bottom: 10px;
  }
  .invoice-setting-btn .cancel-btn {
    margin-right: 0 !important;
  }
  .invoice-item .invoice-logo img {
    width: 100%;
  }
  .invoice-head p {
    font-size: 15px;
  }
  .invoice-head h2 {
    font-weight: 600;
    font-size: 20px;
  }
  .invoice-item-bg .invoice-info {
    padding: 20px;
    margin-top: 0px;
    margin-bottom: 0;
  }
  .invoice-payment-box .payment-details {
    width: 100%;
    margin-bottom: 15px;
  }
  .invoice-info-card .card-body {
    padding: 10px;
  }
  .invoice-total-box p {
    font-size: 14px;
  }
  .pagination-tab {
    padding: 10px;
  }
  .pagination {
    flex-wrap: wrap;
  }
  .pagination .page-link {
    font-size: 14px;
  }
  .settings-btns .btn-orange {
    font-size: 13px;
    margin-right: 10px;
  }
  .links-cont .form-control {
    margin-bottom: 15px;
  }
  .countset .form-control, .countset .socail-links-set {
    margin-bottom: 15px;
  }
  .links-conts .form-control {
    margin-bottom: 15px;
  }
  .invoices-settings-btn .btn {
    padding: 8px 0;
    min-width: 155px;
  }
  .inovices-widget-header .inovices-amount {
    font-size: 20px;
  }
  .invoice-inner-head {
    padding: 15px;
  }
  .invoice-inner-date {
    padding: 15px;
  }
  .invoice-inner-date.invoice-inner-datepic {
    padding-left: 15px;
  }
  .serv-profile h2 {
    font-size: 20px;
  }
  .service-images {
    margin-bottom: 20px;
  }
  .progressbar-card #progressbar li {
    width: 100%;
  }
  .progressbar-card #progressbar li::before {
    display: none;
  }
  .progressbar-card #progress-head li {
    font-size: 24px;
    line-height: 1.5;
  }
  .earning-group {
    display: block;
  }
  .earning-group .sortby-section .sorting-info .sortby-filter-group .sortbyset.week-bg .sorting-select .select2-container .select2-selection--single .select2-selection__rendered {
    background-position: 3% 50%;
  }
  .earning-group .select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 45px;
  }
  .earning-group .sortby-section {
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .earning-group .sortby-section:last-child {
    margin-bottom: 0px;
  }
  .card-table-head {
    margin-bottom: 10px;
  }
  .chart-date-group .form-control {
    max-width: 100%;
    margin-bottom: 10px;
    background-position: 5% 50%;
  }
  .tax-table-rate .list-btn ul li {
    margin-bottom: 10px;
  }
  .tax-table-rate .list-btn ul li:last-child {
    margin-bottom: 0px;
  }
  .fc-left {
    position: relative;
    margin-top: 5px;
    margin-bottom: 5px;
    left: 35px;
  }
  .fc-toolbar .fc-right {
    right: 24px;
    position: relative;
  }
  .card-table-head {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .card-table-head ul {
    margin-left: 0;
    margin-top: 0;
  }
}
@media only screen and (max-width: 575.98px) {
  .main-wrapper > .admin-header {
    right: 0;
    top: 0;
    left: 0;
    padding: 10px 0;
    height: 60px;
  }
  .tab-contents-sets ul li {
    width: 50%;
    padding: 0 !important;
    text-align: center;
  }
  .list-btn .btn-filters {
    font-size: 15px;
    width: 35px;
    height: 35px;
  }
  .tab-contents-sets ul li a {
    padding: 10px;
    display: block;
  }
  .location-setcontent h6 {
    margin-bottom: 10px;
  }
  .page-wrapper {
    padding-top: 60px;
  }
  .bg-danger-light {
    width: 100%;
  }
  .form-groupbtn .btn {
    min-width: auto;
    padding: 7px 15px;
    font-size: 16px;
    margin-bottom: 15px;
  }
  li.nav-item.dropdown.noti-dropdown {
    display: none;
  }
  .content-page-header h5 {
    display: block;
    font-size: 18px;
  }
  .main-wrapper > .admin-header.fixed-header {
    right: 35px;
    top: 0;
    left: 0;
    width: 100%;
  }
  .content-page-header {
    margin-bottom: 20px;
  }
  .card {
    margin-bottom: 0.9375rem;
  }
  .page-wrapper > .content {
    padding: 0.9375rem 0.9375rem 0;
  }
  .card-body {
    padding: 1.25rem;
  }
  .card-header, .card-footer {
    padding: 0.75rem 1.25rem;
  }
  .page-header {
    margin-bottom: 0.9375rem;
  }
  .loginbox {
    padding: 1.25rem;
  }
  .notifications .noti-content {
    width: 300px;
  }
  .filter-btn, .add-button {
    width: 40px;
    height: 40px;
    font-size: 14px;
  }
  .inactive-style {
    margin-top: 15px;
  }
  .profile-upload {
    flex-direction: column;
  }
  .profile-upload-img {
    padding-right: 0;
    padding-bottom: 15px;
  }
  #progressbar li {
    margin-right: 0;
    width: calc(100% - 18px);
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
  }
  .schedule-nav ul li {
    display: block;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    margin-right: 0;
  }
  .earning-group .select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 32px;
  }
  .file-browse {
    flex-direction: column;
  }
  .change-activity-head {
    flex-direction: column;
  }
  .change-activity-head .sort-div {
    margin-top: 20px;
  }
 
}


